var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shouldDisplay
    ? _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            {
              class: {
                xs12: _vm.part.size === _vm.sizeStyles.XL,
                "xs10 sm8": _vm.part.size === _vm.sizeStyles.LG,
                "xs8 sm5":
                  !_vm.part.size || _vm.part.size === _vm.sizeStyles.MD,
                "xs6 sm4": _vm.part.size === _vm.sizeStyles.SM,
                "xs4 sm3": _vm.part.size === _vm.sizeStyles.XS,
                "text-center":
                  _vm.part.horizontal &&
                  _vm.part.horizontal === _vm.horizontalStyles.CENTER,
                "text-left":
                  _vm.part.horizontal &&
                  _vm.part.horizontal === _vm.horizontalStyles.LEFT,
                "text-right":
                  _vm.part.horizontal &&
                  _vm.part.horizontal === _vm.horizontalStyles.RIGHT
              }
            },
            [
              !_vm.loaded
                ? _c("v-progress-circular", {
                    attrs: { size: 50, color: "primary", indeterminate: "" }
                  })
                : _vm.imageSrc
                ? [
                    _vm.part.render === _vm.renderStyles.INLINE
                      ? _c("element-part-graphic-inline", {
                          class: _vm.part.size,
                          attrs: {
                            src: _vm.imageSrc,
                            altText: _vm.imageAlt,
                            attach: _vm.isTopBanner
                              ? ".banner-container"
                              : undefined
                          }
                        })
                      : _c("element-part-graphic-content", {
                          class: _vm.part.size,
                          attrs: {
                            src: _vm.imageSrc,
                            altText: _vm.imageAlt,
                            attach: _vm.isTopBanner
                              ? ".banner-container"
                              : undefined
                          }
                        })
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }