var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center" }, [
    this.showEmpty
      ? _c(
          "svg",
          {
            attrs: {
              viewBox: "0 0 220 100",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("g", { attrs: { "clip-path": "url(#anxiety-empty-clip0)" } }, [
              _c("rect", {
                attrs: { width: "220", height: "100", fill: "none" }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M207.925 93.4209L198.943 93.4209V91.5313L202.168 88.2705C203.123 87.292 203.747 86.6152 204.04 86.2402C204.333 85.8594 204.544 85.5078 204.673 85.1855C204.802 84.8633 204.866 84.5293 204.866 84.1836C204.866 83.668 204.723 83.2842 204.436 83.0322C204.155 82.7803 203.777 82.6543 203.302 82.6543C202.804 82.6543 202.321 82.7686 201.852 82.9971C201.383 83.2256 200.894 83.5508 200.384 83.9727L198.907 82.2236C199.54 81.6846 200.065 81.3037 200.481 81.0811C200.897 80.8584 201.351 80.6885 201.843 80.5713C202.335 80.4482 202.886 80.3867 203.495 80.3867C204.298 80.3867 205.007 80.5332 205.622 80.8262C206.238 81.1191 206.715 81.5293 207.055 82.0566C207.395 82.584 207.565 83.1875 207.565 83.8672C207.565 84.459 207.459 85.0156 207.248 85.5371C207.043 86.0527 206.721 86.583 206.281 87.1279C205.848 87.6729 205.08 88.4492 203.979 89.457L202.326 91.0127V91.1357L207.925 91.1357V93.4209ZM215.941 93.4209L213.225 93.4209V85.9854L213.251 84.7637L213.295 83.4277C212.844 83.8789 212.531 84.1748 212.355 84.3154L210.878 85.502L209.569 83.8672L213.708 80.5713L215.941 80.5713V93.4209Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M9.63281 87.5752C9.63281 89.8193 9.26367 91.4805 8.52539 92.5586C7.79297 93.6367 6.66211 94.1758 5.13281 94.1758C3.65039 94.1758 2.53125 93.6191 1.77539 92.5059C1.02539 91.3926 0.650391 89.749 0.650391 87.5752C0.650391 85.3076 1.0166 83.6377 1.74902 82.5654C2.48145 81.4873 3.60937 80.9482 5.13281 80.9482C6.61523 80.9482 7.73437 81.5107 8.49023 82.6357C9.25195 83.7607 9.63281 85.4072 9.63281 87.5752ZM3.34863 87.5752C3.34863 89.1514 3.4834 90.2822 3.75293 90.9678C4.02832 91.6475 4.48828 91.9873 5.13281 91.9873C5.76562 91.9873 6.22266 91.6416 6.50391 90.9502C6.78516 90.2588 6.92578 89.1338 6.92578 87.5752C6.92578 85.999 6.78223 84.8682 6.49512 84.1826C6.21387 83.4912 5.75977 83.1455 5.13281 83.1455C4.49414 83.1455 4.03711 83.4912 3.76172 84.1826C3.48633 84.8682 3.34863 85.999 3.34863 87.5752Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M46.5 94.0001H18C18 58.4002 40.3333 33.1669 51.5 25.0002C63.6667 13.8335 98.4 -4.09983 140 13.5002C181.6 31.1002 192 74.5001 192 94.0001H162.5C162.5 73.0004 153 61.0002 144 51.0001C135 41.0001 105 28.5001 76.5 42.5001C53.7 53.7001 47 81.5001 46.5 94.0001Z",
                  fill: "#EDEDED"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M110.397 85.5893C110.397 82.6401 107.98 80.236 104.998 80.2196C102.017 80.2032 99.5994 82.5808 99.5994 85.53C99.5994 88.4793 102.017 90.8834 104.998 90.8998C107.98 90.9162 110.397 88.5386 110.397 85.5893Z",
                  fill: "#EDEDED"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M106.902 85.7252C106.902 86.9412 105.897 87.9293 104.668 87.9225L67.2339 87.7169C66.0045 87.7101 65 86.711 65 85.4951C65 84.2791 66.0045 83.291 67.2339 83.2978L104.668 83.5034C105.897 83.5102 106.902 84.5093 106.902 85.7252Z",
                  fill: "#EDEDED"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M107.108 85.5714C107.108 84.4188 106.163 83.4792 104.998 83.4728C103.833 83.4664 102.888 84.3956 102.888 85.5482C102.888 86.7009 103.833 87.6404 104.998 87.6468C106.163 87.6532 107.108 86.724 107.108 85.5714Z",
                  fill: "white"
                }
              })
            ]),
            _c("defs", [
              _c("clipPath", { attrs: { id: "anxiety-empty-clip0" } }, [
                _c("rect", {
                  attrs: { width: "220", height: "100", fill: "white" }
                })
              ])
            ])
          ]
        )
      : _vm._e(),
    this.showZero
      ? _c(
          "svg",
          {
            attrs: {
              viewBox: "0 0 220 100",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("g", { attrs: { "clip-path": "url(#anxiety-0-2-clip0)" } }, [
              _c("rect", {
                attrs: { width: "220", height: "100", fill: "none" }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M207.925 93.4209L198.943 93.4209V91.5313L202.168 88.2705C203.123 87.292 203.747 86.6152 204.04 86.2402C204.333 85.8594 204.544 85.5078 204.673 85.1855C204.802 84.8633 204.866 84.5293 204.866 84.1836C204.866 83.668 204.723 83.2842 204.436 83.0322C204.155 82.7803 203.777 82.6543 203.302 82.6543C202.804 82.6543 202.321 82.7686 201.852 82.9971C201.383 83.2256 200.894 83.5508 200.384 83.9727L198.907 82.2236C199.54 81.6846 200.065 81.3037 200.481 81.0811C200.897 80.8584 201.351 80.6885 201.843 80.5713C202.335 80.4482 202.886 80.3867 203.495 80.3867C204.298 80.3867 205.007 80.5332 205.622 80.8262C206.238 81.1191 206.715 81.5293 207.055 82.0566C207.395 82.584 207.565 83.1875 207.565 83.8672C207.565 84.459 207.459 85.0156 207.248 85.5371C207.043 86.0527 206.721 86.583 206.281 87.1279C205.848 87.6729 205.08 88.4492 203.979 89.457L202.326 91.0127V91.1357L207.925 91.1357V93.4209ZM215.941 93.4209L213.225 93.4209V85.9854L213.251 84.7637L213.295 83.4277C212.844 83.8789 212.531 84.1748 212.355 84.3154L210.878 85.502L209.569 83.8672L213.708 80.5713L215.941 80.5713V93.4209Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M105.054 6C81.0145 6 59.251 15.8639 43.4951 31.8094L55.0388 43.4955L64.0163 52.5837C67.0269 49.5359 70.373 46.8233 73.9867 44.5007L74.0275 44.542C81.885 39.4931 90.9939 36.2663 100.801 35.5365C100.819 35.5365 100.837 35.5365 100.856 35.5319C101.463 35.486 102.071 35.4539 102.683 35.4309C102.832 35.4263 102.982 35.4172 103.132 35.4126C103.771 35.3896 104.41 35.3759 105.059 35.3759C105.707 35.3759 106.346 35.3896 106.986 35.4126C107.135 35.4172 107.285 35.4218 107.434 35.4309C108.047 35.4585 108.654 35.486 109.262 35.5319C109.28 35.5319 109.298 35.5319 109.316 35.5365C119.123 36.2663 128.232 39.4931 136.09 44.542L136.13 44.5007C139.744 46.8233 143.086 49.5359 146.101 52.5837L155.078 43.4955L166.622 31.8094C150.857 15.8639 129.094 6 105.054 6Z",
                  fill: "url(#anxiety-0-2-paint0_linear)"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M47.018 94.1277H18C18 69.7916 27.7437 47.7597 43.495 31.8096L64.0162 52.5838C53.5153 63.2188 47.018 77.9067 47.018 94.1277Z",
                  fill: "#29E9CF"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M166.613 31.8135L146.092 52.5831C146.092 52.5831 151.211 58.6189 154.04 62.7132C159.744 71.8059 163.09 82.5603 163.09 94.127H192.108C192.108 69.7909 182.364 47.7591 166.613 31.8135Z",
                  fill: "url(#anxiety-0-2-paint1_linear)"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M34.7625 42.0908L59.0197 58.3485C59.0197 58.3485 66.8817 47.8925 77.5912 42.2422L62.325 17.3921C62.325 17.3921 46.9545 25.0711 34.7625 42.0908Z",
                  fill: "url(#anxiety-0-2-paint2_linear)"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M9.63281 87.5752C9.63281 89.8193 9.26367 91.4805 8.52539 92.5586C7.79297 93.6367 6.66211 94.1758 5.13281 94.1758C3.65039 94.1758 2.53125 93.6191 1.77539 92.5059C1.02539 91.3926 0.650391 89.749 0.650391 87.5752C0.650391 85.3076 1.0166 83.6377 1.74902 82.5654C2.48145 81.4873 3.60937 80.9482 5.13281 80.9482C6.61523 80.9482 7.73437 81.5107 8.49023 82.6357C9.25195 83.7607 9.63281 85.4072 9.63281 87.5752ZM3.34863 87.5752C3.34863 89.1514 3.4834 90.2822 3.75293 90.9678C4.02832 91.6475 4.48828 91.9873 5.13281 91.9873C5.76562 91.9873 6.22266 91.6416 6.50391 90.9502C6.78516 90.2588 6.92578 89.1338 6.92578 87.5752C6.92578 85.999 6.78223 84.8682 6.49512 84.1826C6.21387 83.4912 5.75977 83.1455 5.13281 83.1455C4.49414 83.1455 4.03711 83.4912 3.76172 84.1826C3.48633 84.8682 3.34863 85.999 3.34863 87.5752Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M110.397 86.5893C110.397 83.6401 107.98 81.236 104.998 81.2196C102.017 81.2032 99.5994 83.5808 99.5994 86.53C99.5994 89.4793 102.017 91.8834 104.998 91.8998C107.98 91.9162 110.397 89.5386 110.397 86.5893Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M106.902 86.7252C106.902 87.9412 105.897 88.9293 104.668 88.9225L67.2339 88.7169C66.0045 88.7101 65 87.711 65 86.4951C65 85.2791 66.0045 84.291 67.2339 84.2978L104.668 84.5034C105.897 84.5102 106.902 85.5093 106.902 86.7252Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M107.108 86.5714C107.108 85.4188 106.163 84.4792 104.998 84.4728C103.833 84.4664 102.888 85.3956 102.888 86.5482C102.888 87.7009 103.833 88.6404 104.998 88.6468C106.163 88.6532 107.108 87.724 107.108 86.5714Z",
                  fill: "white"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M52 80.4992C52 91.2697 43.2697 99.9983 32.4992 99.9983C21.7303 100 13 91.2697 13 80.4992C13 69.7303 21.7303 61 32.4992 61C43.2697 61 52 69.7303 52 80.4992Z",
                  fill: "#29E9CF"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M18.1147 80.4992C18.1147 70.5974 25.4964 62.4197 35.0574 61.1671C34.2201 61.0579 33.3662 61 32.4992 61C21.7303 61 13 69.7303 13 80.4992C13 91.2697 21.7303 99.9983 32.4992 99.9983C33.3662 99.9983 34.2201 99.9404 35.0557 99.8312C25.4964 98.5802 18.1147 90.4026 18.1147 80.4992Z",
                  fill: "#0FD5BD"
                }
              })
            ]),
            _c(
              "defs",
              [
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-0-2-paint0_linear",
                      x1: "60.0058",
                      y1: "29.3179",
                      x2: "163.016",
                      y2: "29.2774",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#FFD740" } }),
                    _c("stop", {
                      attrs: { offset: "0.2163", "stop-color": "#FFD441" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.3964", "stop-color": "#FEC944" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5631", "stop-color": "#FDB848" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5871", "stop-color": "#FDB549" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.9015", "stop-color": "#FF777E" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-0-2-paint1_linear",
                      x1: "169.1",
                      y1: "31.8135",
                      x2: "169.1",
                      y2: "94.127",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { offset: "0.34375", "stop-color": "#FF777E" }
                    }),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#BF5151" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-0-2-paint2_linear",
                      x1: "45.071",
                      y1: "52.6485",
                      x2: "75.7489",
                      y2: "22.3866",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#29E9CF" } }),
                    _c("stop", {
                      attrs: { offset: "0.1961", "stop-color": "#3DE7C2" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.2769", "stop-color": "#62E4A9" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.4328", "stop-color": "#A5DE7C" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5638", "stop-color": "#D6DA5B" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.6631", "stop-color": "#F4D747" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.7192", "stop-color": "#FFD63F" }
                    })
                  ],
                  1
                ),
                _c("clipPath", { attrs: { id: "anxiety-0-2-clip0" } }, [
                  _c("rect", {
                    attrs: { width: "220", height: "100", fill: "white" }
                  })
                ])
              ],
              1
            )
          ]
        )
      : _vm._e(),
    this.showLowRange
      ? _c(
          "svg",
          {
            attrs: {
              viewBox: "0 0 220 100",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("g", { attrs: { "clip-path": "url(#anxiety-3-4-clip0)" } }, [
              _c("rect", {
                attrs: { width: "220", height: "100", fill: "none" }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M207.925 93.4209L198.943 93.4209V91.5313L202.168 88.2705C203.123 87.292 203.747 86.6152 204.04 86.2402C204.333 85.8594 204.544 85.5078 204.673 85.1855C204.802 84.8633 204.866 84.5293 204.866 84.1836C204.866 83.668 204.723 83.2842 204.436 83.0322C204.155 82.7803 203.777 82.6543 203.302 82.6543C202.804 82.6543 202.321 82.7686 201.852 82.9971C201.383 83.2256 200.894 83.5508 200.384 83.9727L198.907 82.2236C199.54 81.6846 200.065 81.3037 200.481 81.0811C200.897 80.8584 201.351 80.6885 201.843 80.5713C202.335 80.4482 202.886 80.3867 203.495 80.3867C204.298 80.3867 205.007 80.5332 205.622 80.8262C206.238 81.1191 206.715 81.5293 207.055 82.0566C207.395 82.584 207.565 83.1875 207.565 83.8672C207.565 84.459 207.459 85.0156 207.248 85.5371C207.043 86.0527 206.721 86.583 206.281 87.1279C205.848 87.6729 205.08 88.4492 203.979 89.457L202.326 91.0127V91.1357L207.925 91.1357V93.4209ZM215.941 93.4209L213.225 93.4209V85.9854L213.251 84.7637L213.295 83.4277C212.844 83.8789 212.531 84.1748 212.355 84.3154L210.878 85.502L209.569 83.8672L213.708 80.5713L215.941 80.5713V93.4209Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M105.054 6C81.0145 6 59.251 15.8639 43.4951 31.8094L55.0388 43.4955L64.0163 52.5837C67.0269 49.5359 70.373 46.8233 73.9867 44.5007L74.0275 44.542C81.885 39.4931 90.9939 36.2663 100.801 35.5365C100.819 35.5365 100.837 35.5365 100.856 35.5319C101.463 35.486 102.071 35.4539 102.683 35.4309C102.832 35.4263 102.982 35.4172 103.132 35.4126C103.771 35.3896 104.41 35.3759 105.059 35.3759C105.707 35.3759 106.346 35.3896 106.986 35.4126C107.135 35.4172 107.285 35.4218 107.434 35.4309C108.047 35.4585 108.654 35.486 109.262 35.5319C109.28 35.5319 109.298 35.5319 109.316 35.5365C119.123 36.2663 128.232 39.4931 136.09 44.542L136.13 44.5007C139.744 46.8233 143.086 49.5359 146.101 52.5837L155.078 43.4955L166.622 31.8094C150.857 15.8639 129.094 6 105.054 6Z",
                  fill: "url(#anxiety-3-4-paint0_linear)"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M47.018 94.1277H18C18 69.7916 27.7437 47.7597 43.495 31.8096L64.0162 52.5838C53.5153 63.2188 47.018 77.9067 47.018 94.1277Z",
                  fill: "#29E9CF"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M166.613 31.8135L146.092 52.5831C146.092 52.5831 151.211 58.6189 154.04 62.7132C159.744 71.8059 163.09 82.5603 163.09 94.127H192.108C192.108 69.7909 182.364 47.7591 166.613 31.8135Z",
                  fill: "url(#anxiety-3-4-paint1_linear)"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M34.7625 42.0908L59.0197 58.3485C59.0197 58.3485 66.8817 47.8925 77.5912 42.2422L62.325 17.3921C62.325 17.3921 46.9545 25.0711 34.7625 42.0908Z",
                  fill: "url(#anxiety-3-4-paint2_linear)"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M9.63281 87.5752C9.63281 89.8193 9.26367 91.4805 8.52539 92.5586C7.79297 93.6367 6.66211 94.1758 5.13281 94.1758C3.65039 94.1758 2.53125 93.6191 1.77539 92.5059C1.02539 91.3926 0.650391 89.749 0.650391 87.5752C0.650391 85.3076 1.0166 83.6377 1.74902 82.5654C2.48145 81.4873 3.60937 80.9482 5.13281 80.9482C6.61523 80.9482 7.73437 81.5107 8.49023 82.6357C9.25195 83.7607 9.63281 85.4072 9.63281 87.5752ZM3.34863 87.5752C3.34863 89.1514 3.4834 90.2822 3.75293 90.9678C4.02832 91.6475 4.48828 91.9873 5.13281 91.9873C5.76562 91.9873 6.22266 91.6416 6.50391 90.9502C6.78516 90.2588 6.92578 89.1338 6.92578 87.5752C6.92578 85.999 6.78223 84.8682 6.49512 84.1826C6.21387 83.4912 5.75977 83.1455 5.13281 83.1455C4.49414 83.1455 4.03711 83.4912 3.76172 84.1826C3.48633 84.8682 3.34863 85.999 3.34863 87.5752Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M110.397 86.5893C110.397 83.6401 107.98 81.236 104.998 81.2196C102.017 81.2032 99.5994 83.5808 99.5994 86.53C99.5994 89.4793 102.017 91.8834 104.998 91.8998C107.98 91.9162 110.397 89.5386 110.397 86.5893Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M107.108 86.5714C107.108 85.4188 106.163 84.4792 104.998 84.4728C103.833 84.4664 102.888 85.3956 102.888 86.5482C102.888 87.7009 103.833 88.6404 104.998 88.6468C106.163 88.6532 107.108 87.724 107.108 86.5714Z",
                  fill: "white"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M49.7932 21.8402C60.1737 18.0621 71.6487 23.4143 75.4269 33.7948C79.2061 44.1731 73.8545 55.6498 63.474 59.428C53.095 63.2056 41.6184 57.8539 37.8408 47.475C34.0626 37.0945 39.4142 25.6179 49.7932 21.8402Z",
                  fill: "#29E9CF"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M49.7932 21.8402C60.1737 18.0621 71.6487 23.4143 75.4269 33.7948C79.2061 44.1731 73.8545 55.6498 63.474 59.428C53.095 63.2056 41.6184 57.8539 37.8408 47.475C34.0626 37.0945 39.4142 25.6179 49.7932 21.8402Z",
                  fill: "url(#anxiety-3-4-paint3_linear)"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M41.8794 40.6339C41.8794 30.4782 49.4504 22.0909 59.2565 20.8062C58.3977 20.6942 57.522 20.6348 56.6327 20.6348C45.5877 20.6348 36.6335 29.5889 36.6335 40.6339C36.6335 51.6806 45.5877 60.6331 56.6327 60.6331C57.522 60.6331 58.3977 60.5737 59.2548 60.4617C49.4504 59.1786 41.8794 50.7913 41.8794 40.6339Z",
                  fill: "#0FD5BD"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M41.8794 40.6339C41.8794 30.4782 49.4504 22.0909 59.2565 20.8062C58.3977 20.6942 57.522 20.6348 56.6327 20.6348C45.5877 20.6348 36.6335 29.5889 36.6335 40.6339C36.6335 51.6806 45.5877 60.6331 56.6327 60.6331C57.522 60.6331 58.3977 60.5737 59.2548 60.4617C49.4504 59.1786 41.8794 50.7913 41.8794 40.6339Z",
                  fill: "url(#anxiety-3-4-paint4_linear)"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M109.223 89.4076C111.058 87.2883 110.827 84.0828 108.708 82.248C106.588 80.4132 103.383 80.6439 101.548 82.7632C99.7132 84.8825 99.9439 88.088 102.063 89.9228C104.183 91.7576 107.388 91.527 109.223 89.4076Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M106.642 87.3677C105.886 88.2415 104.554 88.3373 103.68 87.5808L77.0726 64.5455C76.1989 63.7891 76.103 62.4569 76.8595 61.5831C77.6159 60.7094 78.9481 60.6135 79.8219 61.37L106.429 84.4053C107.303 85.1618 107.399 86.4939 106.642 87.3677Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M106.885 87.3837C107.602 86.5554 107.512 85.3026 106.684 84.5855C105.856 83.8684 104.603 83.9586 103.886 84.7869C103.169 85.6152 103.259 86.8679 104.087 87.585C104.915 88.3021 106.168 88.212 106.885 87.3837Z",
                  fill: "white"
                }
              })
            ]),
            _c(
              "defs",
              [
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-3-4-paint0_linear",
                      x1: "60.0058",
                      y1: "29.3179",
                      x2: "163.016",
                      y2: "29.2774",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#FFD740" } }),
                    _c("stop", {
                      attrs: { offset: "0.2163", "stop-color": "#FFD441" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.3964", "stop-color": "#FEC944" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5631", "stop-color": "#FDB848" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5871", "stop-color": "#FDB549" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.9015", "stop-color": "#FF777E" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-3-4-paint1_linear",
                      x1: "169.1",
                      y1: "31.8135",
                      x2: "169.1",
                      y2: "94.127",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { offset: "0.34375", "stop-color": "#FF777E" }
                    }),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#BF5151" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-3-4-paint2_linear",
                      x1: "45.071",
                      y1: "52.6485",
                      x2: "75.7489",
                      y2: "22.3866",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#29E9CF" } }),
                    _c("stop", {
                      attrs: { offset: "0.1961", "stop-color": "#3DE7C2" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.2769", "stop-color": "#62E4A9" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.4328", "stop-color": "#A5DE7C" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5638", "stop-color": "#D6DA5B" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.6631", "stop-color": "#F4D747" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.7192", "stop-color": "#FFD63F" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-3-4-paint3_linear",
                      x1: "37.8405",
                      y1: "47.4742",
                      x2: "75.4266",
                      y2: "33.794",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#08EACF" } }),
                    _c("stop", {
                      attrs: { offset: "0.708333", "stop-color": "#FEB63D" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-3-4-paint4_linear",
                      x1: "47.945",
                      y1: "20.6348",
                      x2: "47.945",
                      y2: "60.6331",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#FEB63D" } }),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#08EACF" }
                    })
                  ],
                  1
                ),
                _c("clipPath", { attrs: { id: "anxiety-3-4-clip0" } }, [
                  _c("rect", {
                    attrs: { width: "220", height: "100", fill: "white" }
                  })
                ])
              ],
              1
            )
          ]
        )
      : _vm._e(),
    this.showMidRange
      ? _c(
          "svg",
          {
            attrs: {
              viewBox: "0 0 220 100",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("g", { attrs: { "clip-path": "url(#anxiety-5-9-clip0)" } }, [
              _c("rect", {
                attrs: { width: "220", height: "100", fill: "none" }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M207.925 93.4209L198.943 93.4209V91.5313L202.168 88.2705C203.123 87.292 203.747 86.6152 204.04 86.2402C204.333 85.8594 204.544 85.5078 204.673 85.1855C204.802 84.8633 204.866 84.5293 204.866 84.1836C204.866 83.668 204.723 83.2842 204.436 83.0322C204.155 82.7803 203.777 82.6543 203.302 82.6543C202.804 82.6543 202.321 82.7686 201.852 82.9971C201.383 83.2256 200.894 83.5508 200.384 83.9727L198.907 82.2236C199.54 81.6846 200.065 81.3037 200.481 81.0811C200.897 80.8584 201.351 80.6885 201.843 80.5713C202.335 80.4482 202.886 80.3867 203.495 80.3867C204.298 80.3867 205.007 80.5332 205.622 80.8262C206.238 81.1191 206.715 81.5293 207.055 82.0566C207.395 82.584 207.565 83.1875 207.565 83.8672C207.565 84.459 207.459 85.0156 207.248 85.5371C207.043 86.0527 206.721 86.583 206.281 87.1279C205.848 87.6729 205.08 88.4492 203.979 89.457L202.326 91.0127V91.1357L207.925 91.1357V93.4209ZM215.941 93.4209L213.225 93.4209V85.9854L213.251 84.7637L213.295 83.4277C212.844 83.8789 212.531 84.1748 212.355 84.3154L210.878 85.502L209.569 83.8672L213.708 80.5713L215.941 80.5713V93.4209Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M105.054 6C81.0145 6 59.251 15.8639 43.4951 31.8094L55.0388 43.4955L64.0163 52.5837C67.0269 49.5359 70.373 46.8233 73.9867 44.5007L74.0275 44.542C81.885 39.4931 90.9939 36.2663 100.801 35.5365C100.819 35.5365 100.837 35.5365 100.856 35.5319C101.463 35.486 102.071 35.4539 102.683 35.4309C102.832 35.4263 102.982 35.4172 103.132 35.4126C103.771 35.3896 104.41 35.3759 105.059 35.3759C105.707 35.3759 106.346 35.3896 106.986 35.4126C107.135 35.4172 107.285 35.4218 107.434 35.4309C108.047 35.4585 108.654 35.486 109.262 35.5319C109.28 35.5319 109.298 35.5319 109.316 35.5365C119.123 36.2663 128.232 39.4931 136.09 44.542L136.13 44.5007C139.744 46.8233 143.086 49.5359 146.101 52.5837L155.078 43.4955L166.622 31.8094C150.857 15.8639 129.094 6 105.054 6Z",
                  fill: "url(#anxiety-5-9-paint0_linear)"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M47.018 94.1277H18C18 69.7916 27.7437 47.7597 43.495 31.8096L64.0162 52.5838C53.5153 63.2188 47.018 77.9067 47.018 94.1277Z",
                  fill: "#29E9CF"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M166.613 31.8135L146.092 52.5831C146.092 52.5831 151.211 58.6189 154.04 62.7132C159.744 71.8059 163.09 82.5603 163.09 94.127H192.108C192.108 69.7909 182.364 47.7591 166.613 31.8135Z",
                  fill: "url(#anxiety-5-9-paint1_linear)"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M34.7625 42.0908L59.0197 58.3485C59.0197 58.3485 66.8817 47.8925 77.5912 42.2422L62.325 17.3921C62.325 17.3921 46.9545 25.0711 34.7625 42.0908Z",
                  fill: "url(#anxiety-5-9-paint2_linear)"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M9.63281 87.5752C9.63281 89.8193 9.26367 91.4805 8.52539 92.5586C7.79297 93.6367 6.66211 94.1758 5.13281 94.1758C3.65039 94.1758 2.53125 93.6191 1.77539 92.5059C1.02539 91.3926 0.650391 89.749 0.650391 87.5752C0.650391 85.3076 1.0166 83.6377 1.74902 82.5654C2.48145 81.4873 3.60937 80.9482 5.13281 80.9482C6.61523 80.9482 7.73437 81.5107 8.49023 82.6357C9.25195 83.7607 9.63281 85.4072 9.63281 87.5752ZM3.34863 87.5752C3.34863 89.1514 3.4834 90.2822 3.75293 90.9678C4.02832 91.6475 4.48828 91.9873 5.13281 91.9873C5.76562 91.9873 6.22266 91.6416 6.50391 90.9502C6.78516 90.2588 6.92578 89.1338 6.92578 87.5752C6.92578 85.999 6.78223 84.8682 6.49512 84.1826C6.21387 83.4912 5.75977 83.1455 5.13281 83.1455C4.49414 83.1455 4.03711 83.4912 3.76172 84.1826C3.48633 84.8682 3.34863 85.999 3.34863 87.5752Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M110.397 86.5893C110.397 83.6401 107.98 81.236 104.998 81.2196C102.017 81.2032 99.5994 83.5808 99.5994 86.53C99.5994 89.4793 102.017 91.8834 104.998 91.8998C107.98 91.9162 110.397 89.5386 110.397 86.5893Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M107.108 86.5714C107.108 85.4188 106.163 84.4792 104.998 84.4728C103.833 84.4664 102.888 85.3956 102.888 86.5482C102.888 87.7009 103.833 88.6404 104.998 88.6468C106.163 88.6532 107.108 87.724 107.108 86.5714Z",
                  fill: "white"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M109.223 89.4076C111.058 87.2883 110.827 84.0828 108.708 82.248C106.588 80.4132 103.383 80.6439 101.548 82.7632C99.7132 84.8825 99.9439 88.088 102.063 89.9228C104.183 91.7576 107.388 91.527 109.223 89.4076Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M106.885 87.3842C107.602 86.5559 107.512 85.3031 106.684 84.586C105.855 83.8689 104.603 83.9591 103.886 84.7874C103.168 85.6157 103.259 86.8684 104.087 87.5855C104.915 88.3026 106.168 88.2125 106.885 87.3842Z",
                  fill: "white"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M106.35 91.4998C109.078 90.8543 110.766 88.1196 110.12 85.3917C109.475 82.6638 106.74 80.9757 104.012 81.6212C101.284 82.2667 99.5963 85.0014 100.242 87.7293C100.887 90.4572 103.622 92.1453 106.35 91.4998Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M105.45 88.3358C104.326 88.6019 103.189 87.9004 102.923 86.7757L94.8188 52.5281C94.5527 51.4034 95.2542 50.2669 96.3789 50.0008C97.5036 49.7346 98.6401 50.4362 98.9062 51.5609L107.01 85.8085C107.276 86.9332 106.575 88.0697 105.45 88.3358Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M105.638 88.4909C106.704 88.2386 107.364 87.1698 107.111 86.1037C106.859 85.0376 105.79 84.3778 104.724 84.6301C103.658 84.8824 102.998 85.9512 103.25 87.0173C103.503 88.0834 104.572 88.7432 105.638 88.4909Z",
                  fill: "white"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M109 24.9991C109 36.0459 100.046 44.9983 89.0008 44.9983C77.9541 45 69 36.0459 69 24.9991C69 13.9541 77.9541 5 88.9992 5C100.046 5 109 13.9541 109 24.9991Z",
                  fill: "#FFD741"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M74.2459 24.9991C74.2459 14.8434 81.8168 6.45615 91.6229 5.17141C90.7642 5.0594 89.8885 5 88.9992 5C77.9541 5 69 13.9541 69 24.9991C69 36.0459 77.9541 44.9983 88.9992 44.9983C89.8885 44.9983 90.7625 44.9389 91.6212 44.8269C81.8168 43.5438 74.2459 35.1566 74.2459 24.9991Z",
                  fill: "#F1C62C"
                }
              })
            ]),
            _c(
              "defs",
              [
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-5-9-paint0_linear",
                      x1: "60.0058",
                      y1: "29.3179",
                      x2: "163.016",
                      y2: "29.2774",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#FFD740" } }),
                    _c("stop", {
                      attrs: { offset: "0.2163", "stop-color": "#FFD441" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.3964", "stop-color": "#FEC944" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5631", "stop-color": "#FDB848" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5871", "stop-color": "#FDB549" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.9015", "stop-color": "#FF777E" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-5-9-paint1_linear",
                      x1: "169.1",
                      y1: "31.8135",
                      x2: "169.1",
                      y2: "94.127",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { offset: "0.34375", "stop-color": "#FF777E" }
                    }),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#BF5151" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-5-9-paint2_linear",
                      x1: "45.071",
                      y1: "52.6485",
                      x2: "75.7489",
                      y2: "22.3866",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#29E9CF" } }),
                    _c("stop", {
                      attrs: { offset: "0.1961", "stop-color": "#3DE7C2" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.2769", "stop-color": "#62E4A9" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.4328", "stop-color": "#A5DE7C" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5638", "stop-color": "#D6DA5B" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.6631", "stop-color": "#F4D747" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.7192", "stop-color": "#FFD63F" }
                    })
                  ],
                  1
                ),
                _c("clipPath", { attrs: { id: "anxiety-5-9-clip0" } }, [
                  _c("rect", {
                    attrs: { width: "220", height: "100", fill: "white" }
                  })
                ])
              ],
              1
            )
          ]
        )
      : _vm._e(),
    this.showHighRange
      ? _c(
          "svg",
          {
            attrs: {
              viewBox: "0 0 220 100",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("g", { attrs: { "clip-path": "url(#anxiety-10-14-clip0)" } }, [
              _c("rect", {
                attrs: { width: "220", height: "100", fill: "none" }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M207.925 93.4209L198.943 93.4209V91.5313L202.168 88.2705C203.123 87.292 203.747 86.6152 204.04 86.2402C204.333 85.8594 204.544 85.5078 204.673 85.1855C204.802 84.8633 204.866 84.5293 204.866 84.1836C204.866 83.668 204.723 83.2842 204.436 83.0322C204.155 82.7803 203.777 82.6543 203.302 82.6543C202.804 82.6543 202.321 82.7686 201.852 82.9971C201.383 83.2256 200.894 83.5508 200.384 83.9727L198.907 82.2236C199.54 81.6846 200.065 81.3037 200.481 81.0811C200.897 80.8584 201.351 80.6885 201.843 80.5713C202.335 80.4482 202.886 80.3867 203.495 80.3867C204.298 80.3867 205.007 80.5332 205.622 80.8262C206.238 81.1191 206.715 81.5293 207.055 82.0566C207.395 82.584 207.565 83.1875 207.565 83.8672C207.565 84.459 207.459 85.0156 207.248 85.5371C207.043 86.0527 206.721 86.583 206.281 87.1279C205.848 87.6729 205.08 88.4492 203.979 89.457L202.326 91.0127V91.1357L207.925 91.1357V93.4209ZM215.941 93.4209L213.225 93.4209V85.9854L213.251 84.7637L213.295 83.4277C212.844 83.8789 212.531 84.1748 212.355 84.3154L210.878 85.502L209.569 83.8672L213.708 80.5713L215.941 80.5713V93.4209Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M105.054 6C81.0145 6 59.251 15.8639 43.4951 31.8094L55.0388 43.4955L64.0163 52.5837C67.0269 49.5359 70.373 46.8233 73.9867 44.5007L74.0275 44.542C81.885 39.4931 90.9939 36.2663 100.801 35.5365C100.819 35.5365 100.837 35.5365 100.856 35.5319C101.463 35.486 102.071 35.4539 102.683 35.4309C102.832 35.4263 102.982 35.4172 103.132 35.4126C103.771 35.3896 104.41 35.3759 105.059 35.3759C105.707 35.3759 106.346 35.3896 106.986 35.4126C107.135 35.4172 107.285 35.4218 107.434 35.4309C108.047 35.4585 108.654 35.486 109.262 35.5319C109.28 35.5319 109.298 35.5319 109.316 35.5365C119.123 36.2663 128.232 39.4931 136.09 44.542L136.13 44.5007C139.744 46.8233 143.086 49.5359 146.101 52.5837L155.078 43.4955L166.622 31.8094C150.857 15.8639 129.094 6 105.054 6Z",
                  fill: "url(#anxiety-10-14-paint0_linear)"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M47.018 94.1277H18C18 69.7916 27.7437 47.7597 43.495 31.8096L64.0162 52.5838C53.5153 63.2188 47.018 77.9067 47.018 94.1277Z",
                  fill: "#29E9CF"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M166.613 31.8135L146.092 52.5831C146.092 52.5831 151.211 58.6189 154.04 62.7132C159.744 71.8059 163.09 82.5603 163.09 94.127H192.108C192.108 69.7909 182.364 47.7591 166.613 31.8135Z",
                  fill: "url(#anxiety-10-14-paint1_linear)"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M34.7625 42.0908L59.0197 58.3485C59.0197 58.3485 66.8817 47.8925 77.5912 42.2422L62.325 17.3921C62.325 17.3921 46.9545 25.0711 34.7625 42.0908Z",
                  fill: "url(#anxiety-10-14-paint2_linear)"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M9.63281 87.5752C9.63281 89.8193 9.26367 91.4805 8.52539 92.5586C7.79297 93.6367 6.66211 94.1758 5.13281 94.1758C3.65039 94.1758 2.53125 93.6191 1.77539 92.5059C1.02539 91.3926 0.650391 89.749 0.650391 87.5752C0.650391 85.3076 1.0166 83.6377 1.74902 82.5654C2.48145 81.4873 3.60937 80.9482 5.13281 80.9482C6.61523 80.9482 7.73437 81.5107 8.49023 82.6357C9.25195 83.7607 9.63281 85.4072 9.63281 87.5752ZM3.34863 87.5752C3.34863 89.1514 3.4834 90.2822 3.75293 90.9678C4.02832 91.6475 4.48828 91.9873 5.13281 91.9873C5.76562 91.9873 6.22266 91.6416 6.50391 90.9502C6.78516 90.2588 6.92578 89.1338 6.92578 87.5752C6.92578 85.999 6.78223 84.8682 6.49512 84.1826C6.21387 83.4912 5.75977 83.1455 5.13281 83.1455C4.49414 83.1455 4.03711 83.4912 3.76172 84.1826C3.48633 84.8682 3.34863 85.999 3.34863 87.5752Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M102.418 90.6493C104.858 92.0291 107.955 91.1696 109.335 88.7295C110.715 86.2894 109.855 83.1927 107.415 81.8129C104.975 80.433 101.878 81.2925 100.498 83.7327C99.1186 86.1728 99.9781 89.2694 102.418 90.6493Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M103.908 87.7171C102.902 87.1482 102.544 85.8613 103.113 84.8553L120.437 54.2207C121.006 53.2147 122.293 52.8574 123.299 53.4263C124.305 53.9952 124.662 55.2822 124.093 56.2882L106.77 86.9228C106.201 87.9288 104.914 88.286 103.908 87.7171Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M103.94 87.9582C104.894 88.4975 106.104 88.1616 106.643 87.2079C107.182 86.2543 106.846 85.044 105.893 84.5048C104.939 83.9655 103.729 84.3014 103.19 85.2551C102.65 86.2087 102.986 87.419 103.94 87.9582Z",
                  fill: "white"
                }
              }),
              _c("g", { attrs: { "clip-path": "url(#anxiety-10-14-clip1)" } }, [
                _c("path", {
                  attrs: {
                    d:
                      "M142.84 10.2055C153.221 13.9837 158.571 25.4598 154.793 35.8387C151.017 46.2198 139.54 51.5714 129.16 47.7932C118.781 44.0156 113.429 32.539 117.207 22.1601C120.985 11.7796 132.461 6.42791 142.84 10.2055Z",
                    fill: "#FFD741"
                  }
                }),
                _c("path", {
                  attrs: {
                    d:
                      "M142.84 10.2055C153.221 13.9837 158.571 25.4598 154.793 35.8387C151.017 46.2198 139.54 51.5714 129.16 47.7932C118.781 44.0156 113.429 32.539 117.207 22.1601C120.985 11.7796 132.461 6.42791 142.84 10.2055Z",
                    fill: "url(#anxiety-10-14-paint3_linear)"
                  }
                }),
                _c("path", {
                  attrs: {
                    d:
                      "M121.246 28.9991C121.246 18.8434 128.817 10.4561 138.623 9.17141C137.764 9.0594 136.888 9 135.999 9C124.954 9 116 17.9541 116 28.9991C116 40.0459 124.954 48.9983 135.999 48.9983C136.888 48.9983 137.762 48.9389 138.621 48.8269C128.817 47.5438 121.246 39.1566 121.246 28.9991Z",
                    fill: "#F1C62C"
                  }
                })
              ])
            ]),
            _c(
              "defs",
              [
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-10-14-paint0_linear",
                      x1: "60.0058",
                      y1: "29.3179",
                      x2: "163.016",
                      y2: "29.2774",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#FFD740" } }),
                    _c("stop", {
                      attrs: { offset: "0.2163", "stop-color": "#FFD441" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.3964", "stop-color": "#FEC944" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5631", "stop-color": "#FDB848" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5871", "stop-color": "#FDB549" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.9015", "stop-color": "#FF777E" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-10-14-paint1_linear",
                      x1: "169.1",
                      y1: "31.8135",
                      x2: "169.1",
                      y2: "94.127",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { offset: "0.34375", "stop-color": "#FF777E" }
                    }),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#BF5151" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-10-14-paint2_linear",
                      x1: "45.071",
                      y1: "52.6485",
                      x2: "75.7489",
                      y2: "22.3866",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#29E9CF" } }),
                    _c("stop", {
                      attrs: { offset: "0.1961", "stop-color": "#3DE7C2" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.2769", "stop-color": "#62E4A9" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.4328", "stop-color": "#A5DE7C" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5638", "stop-color": "#D6DA5B" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.6631", "stop-color": "#F4D747" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.7192", "stop-color": "#FFD63F" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-10-14-paint3_linear",
                      x1: "117.207",
                      y1: "22.1593",
                      x2: "154.793",
                      y2: "35.8395",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { offset: "0.364583", "stop-color": "#FFD741" }
                    }),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#FF777E" }
                    })
                  ],
                  1
                ),
                _c("clipPath", { attrs: { id: "anxiety-10-14-clip0" } }, [
                  _c("rect", {
                    attrs: { width: "220", height: "100", fill: "white" }
                  })
                ]),
                _c("clipPath", { attrs: { id: "anxiety-10-14-clip1" } }, [
                  _c("path", {
                    attrs: { d: "M116 9H156V49H116V9Z", fill: "white" }
                  })
                ])
              ],
              1
            )
          ]
        )
      : _vm._e(),
    this.showMax
      ? _c(
          "svg",
          {
            attrs: {
              viewBox: "0 0 220 100",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("g", { attrs: { "clip-path": "url(#anxiety-14-clip0)" } }, [
              _c("rect", {
                attrs: { width: "220", height: "100", fill: "none" }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M207.925 93.4209L198.943 93.4209V91.5313L202.168 88.2705C203.123 87.292 203.747 86.6152 204.04 86.2402C204.333 85.8594 204.544 85.5078 204.673 85.1855C204.802 84.8633 204.866 84.5293 204.866 84.1836C204.866 83.668 204.723 83.2842 204.436 83.0322C204.155 82.7803 203.777 82.6543 203.302 82.6543C202.804 82.6543 202.321 82.7686 201.852 82.9971C201.383 83.2256 200.894 83.5508 200.384 83.9727L198.907 82.2236C199.54 81.6846 200.065 81.3037 200.481 81.0811C200.897 80.8584 201.351 80.6885 201.843 80.5713C202.335 80.4482 202.886 80.3867 203.495 80.3867C204.298 80.3867 205.007 80.5332 205.622 80.8262C206.238 81.1191 206.715 81.5293 207.055 82.0566C207.395 82.584 207.565 83.1875 207.565 83.8672C207.565 84.459 207.459 85.0156 207.248 85.5371C207.043 86.0527 206.721 86.583 206.281 87.1279C205.848 87.6729 205.08 88.4492 203.979 89.457L202.326 91.0127V91.1357L207.925 91.1357V93.4209ZM215.941 93.4209L213.225 93.4209V85.9854L213.251 84.7637L213.295 83.4277C212.844 83.8789 212.531 84.1748 212.355 84.3154L210.878 85.502L209.569 83.8672L213.708 80.5713L215.941 80.5713V93.4209Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M105.054 6C81.0145 6 59.251 15.8639 43.4951 31.8094L55.0388 43.4955L64.0163 52.5837C67.0269 49.5359 70.373 46.8233 73.9867 44.5007L74.0275 44.542C81.885 39.4931 90.9939 36.2663 100.801 35.5365C100.819 35.5365 100.837 35.5365 100.856 35.5319C101.463 35.486 102.071 35.4539 102.683 35.4309C102.832 35.4263 102.982 35.4172 103.132 35.4126C103.771 35.3896 104.41 35.3759 105.059 35.3759C105.707 35.3759 106.346 35.3896 106.986 35.4126C107.135 35.4172 107.285 35.4218 107.434 35.4309C108.047 35.4585 108.654 35.486 109.262 35.5319C109.28 35.5319 109.298 35.5319 109.316 35.5365C119.123 36.2663 128.232 39.4931 136.09 44.542L136.13 44.5007C139.744 46.8233 143.086 49.5359 146.101 52.5837L155.078 43.4955L166.622 31.8094C150.857 15.8639 129.094 6 105.054 6Z",
                  fill: "url(#anxiety-14-paint0_linear)"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M47.018 94.1277H18C18 69.7916 27.7437 47.7597 43.495 31.8096L64.0162 52.5838C53.5153 63.2188 47.018 77.9067 47.018 94.1277Z",
                  fill: "#29E9CF"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M166.613 31.8135L146.092 52.5831C146.092 52.5831 151.211 58.6189 154.04 62.7132C159.744 71.8059 163.09 82.5603 163.09 94.127H192.108C192.108 69.7909 182.364 47.7591 166.613 31.8135Z",
                  fill: "url(#anxiety-14-paint1_linear)"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M34.7625 42.0908L59.0197 58.3485C59.0197 58.3485 66.8817 47.8925 77.5912 42.2422L62.325 17.3921C62.325 17.3921 46.9545 25.0711 34.7625 42.0908Z",
                  fill: "url(#anxiety-14-paint2_linear)"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M9.63281 87.5752C9.63281 89.8193 9.26367 91.4805 8.52539 92.5586C7.79297 93.6367 6.66211 94.1758 5.13281 94.1758C3.65039 94.1758 2.53125 93.6191 1.77539 92.5059C1.02539 91.3926 0.650391 89.749 0.650391 87.5752C0.650391 85.3076 1.0166 83.6377 1.74902 82.5654C2.48145 81.4873 3.60937 80.9482 5.13281 80.9482C6.61523 80.9482 7.73437 81.5107 8.49023 82.6357C9.25195 83.7607 9.63281 85.4072 9.63281 87.5752ZM3.34863 87.5752C3.34863 89.1514 3.4834 90.2822 3.75293 90.9678C4.02832 91.6475 4.48828 91.9873 5.13281 91.9873C5.76562 91.9873 6.22266 91.6416 6.50391 90.9502C6.78516 90.2588 6.92578 89.1338 6.92578 87.5752C6.92578 85.999 6.78223 84.8682 6.49512 84.1826C6.21387 83.4912 5.75977 83.1455 5.13281 83.1455C4.49414 83.1455 4.03711 83.4912 3.76172 84.1826C3.48633 84.8682 3.34863 85.999 3.34863 87.5752Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M100 86.0757C100 88.8789 102.272 91.1514 105.076 91.1514C107.879 91.1514 110.151 88.8789 110.151 86.0757C110.151 83.2725 107.879 81 105.076 81C102.272 81 100 83.2725 100 86.0757Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M103.286 85.9287C103.286 84.773 104.23 83.8286 105.386 83.8286L140.58 83.8286C141.735 83.8286 142.68 84.773 142.68 85.9287C142.68 87.0845 141.735 88.0289 140.58 88.0289L105.386 88.0289C104.23 88.0289 103.286 87.0845 103.286 85.9287Z",
                  fill: "#4F4F4F"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M103.092 86.0755C103.092 87.1711 103.98 88.0592 105.076 88.0592C106.171 88.0592 107.059 87.1711 107.059 86.0755C107.059 84.9799 106.171 84.0918 105.076 84.0918C103.98 84.0918 103.092 84.9799 103.092 86.0755Z",
                  fill: "white"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M197 79.9991C197 91.0459 188.046 99.9983 176.999 99.9983C165.954 100 157 91.0459 157 79.9991C157 68.9541 165.954 60 176.999 60C188.046 60 197 68.9541 197 79.9991Z",
                  fill: "#FF777E"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M162.246 79.9991C162.246 69.8434 169.817 61.4561 179.623 60.1714C178.764 60.0594 177.888 60 176.999 60C165.954 60 157 68.9541 157 79.9991C157 91.0459 165.954 99.9983 176.999 99.9983C177.888 99.9983 178.764 99.9389 179.621 99.8269C169.817 98.5438 162.246 90.1566 162.246 79.9991Z",
                  fill: "#DE5B5C"
                }
              })
            ]),
            _c(
              "defs",
              [
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-14-paint0_linear",
                      x1: "60.0058",
                      y1: "29.3179",
                      x2: "163.016",
                      y2: "29.2774",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#FFD740" } }),
                    _c("stop", {
                      attrs: { offset: "0.2163", "stop-color": "#FFD441" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.3964", "stop-color": "#FEC944" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5631", "stop-color": "#FDB848" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5871", "stop-color": "#FDB549" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.9015", "stop-color": "#FF777E" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-14-paint1_linear",
                      x1: "169.1",
                      y1: "31.8135",
                      x2: "169.1",
                      y2: "94.127",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { offset: "0.34375", "stop-color": "#FF777E" }
                    }),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#BF5151" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "anxiety-14-paint2_linear",
                      x1: "45.071",
                      y1: "52.6485",
                      x2: "75.7489",
                      y2: "22.3866",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#29E9CF" } }),
                    _c("stop", {
                      attrs: { offset: "0.1961", "stop-color": "#3DE7C2" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.2769", "stop-color": "#62E4A9" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.4328", "stop-color": "#A5DE7C" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5638", "stop-color": "#D6DA5B" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.6631", "stop-color": "#F4D747" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.7192", "stop-color": "#FFD63F" }
                    })
                  ],
                  1
                ),
                _c("clipPath", { attrs: { id: "anxiety-14-clip0" } }, [
                  _c("rect", {
                    attrs: { width: "220", height: "100", fill: "white" }
                  })
                ])
              ],
              1
            )
          ]
        )
      : _vm._e(),
    _c("div", { staticClass: "anxiety-text", style: _vm.textColor }, [
      _c("h1", {
        staticClass: "anxiety-value",
        domProps: { textContent: _vm._s(_vm.value) }
      }),
      _c("h2", {
        staticClass: "anxiety-subtext",
        domProps: {
          textContent: _vm._s(
            _vm.$t("app.home.user_stats_card.widgets.anxiety_meter.text")
          )
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }