var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _vm.audioURL
        ? _c("audio-player", {
            ref: "animationPlayer",
            attrs: {
              audioURL: _vm.audioURL,
              transcript: _vm.audioTranscript,
              identifier: _vm.identifier
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }