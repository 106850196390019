










import Vue from 'vue';
import { IStyleContent, StylePeriodEnum } from '@/scripts/store/modules/therapy/types';
import MoodDaily from '@/views/tools/moods/components/moodHistory/mood-daily.vue';
import MoodTrends from '@/views/tools/moods/components/moodHistory/mood-trends.vue';

export default Vue.extend({
  name: 'element-part-mood',
  components: { MoodDaily, MoodTrends },
  props: {
    part: {
      type: Object as () => IStyleContent,
      required: true,
    },
  },
  data() {
    return {
      loading: null as boolean | null,
    };
  },
  computed: {
    isHistory(): boolean {
      return this.part.period === StylePeriodEnum.HISTORY;
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch('moods/getMoods').then(() => {
      this.loading = false;
    });
  },
});
