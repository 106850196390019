var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "assessment-notes-display", attrs: { elevation: 0 } },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-0 ma-0" },
        [
          _c("input-note-wrapper-assessment", {
            attrs: {
              note: _vm.safeNote,
              color: _vm.$t(
                "app.assessments." + _vm.userAssessment.assessmentName
              ).color,
              loading: _vm.loading,
              type: _vm.userAssessment.assessmentName
            },
            on: { updated: _vm.onUpdated }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }