




























import Vue from 'vue';
import { IStyleContentAnimation, StyleHorizontalEnum, StyleSizeEnum } from '@/scripts/store/modules/therapy/types';
import { LottiePlayer } from '@lottiefiles/lottie-player';

interface Animation {
  script?: string,
  autoplay?: boolean,
  count?: number,
  direction?: number,
  hover?: boolean,
  loop?: boolean,
  mode?: string,
  speed?: number,
}

export default Vue.extend({
  name: 'element-part-animation',
  props: {
    part: {
      type: Object as () => IStyleContentAnimation,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      animation: undefined as Animation | undefined,
    };
  },
  computed: {
    horizontalStyles() {
      return StyleHorizontalEnum;
    },
    sizeStyles() {
      return StyleSizeEnum;
    },
    justifyLeft(): boolean {
      return !!this.part.horizontal && this.part.horizontal === StyleHorizontalEnum.LEFT;
    },
    justifyCenter(): boolean {
      return !this.part.horizontal || this.part.horizontal === StyleHorizontalEnum.CENTER;
    },
    justifyRight(): boolean {
      return !!this.part.horizontal && this.part.horizontal === StyleHorizontalEnum.RIGHT;
    },
  },
  mounted() {
    this.getAnimation();
  },
  methods: {
    getAnimation() {
      if (!this.part.id && !this.part.content) return;
      this.loading = true;
      this.getMediaMetadata().then((media?: Animation) => {
        if (!media) return;
        this.animation = media;
      }).finally(() => {
        this.loading = false;
        if (this.part.delay === undefined || this.part.delay <= 0) return;
        const delay = this.part.delay * 1000;
        this.$nextTick(() => {
          const animationPlayer = this.$refs.animationPlayer as any as LottiePlayer;
          if (!animationPlayer) return;
          setTimeout(() => { animationPlayer.play(); }, delay);
        });
      });
    },
    async getMediaMetadata() {
      const animationConfig: Animation = {
        autoplay: this.part.autoplay !== undefined ? this.part.autoplay : true,
        count: this.part.times !== undefined ? parseInt(this.part.times.toString()) : 1,
        direction: this.part.revert ? -1 : 1,
        hover: this.part.hover !== undefined ? this.part.hover : false,
        loop: true,
        mode: this.part.bounce !== undefined && !!this.part.bounce ? 'bounce' : 'normal',
        speed: this.part.speed !== undefined ? parseFloat(this.part.speed.toString()) : 1.0,
      };

      if (!this.part.id) {
        animationConfig.script = this.part.content;
        return animationConfig;
      }

      const media = await this.$store.dispatch('therapy/getMedia', this.part.id);
      animationConfig.script = media.script;

      // Overwrite with default config if custom properties not informed
      if (this.part.autoplay === undefined) animationConfig.autoplay = media.autoplay;
      if (this.part.bounce === undefined) animationConfig.mode = media.bounce ? 'bounce' : 'normal';
      if (this.part.hover === undefined) animationConfig.hover = media.hover;
      if (this.part.revert === undefined) animationConfig.direction = media.revert ? -1 : 1;
      if (this.part.speed === undefined) animationConfig.speed = parseFloat(media.speed);
      if (this.part.times === undefined) animationConfig.count = parseInt(media.times);
      if (this.part.delay !== undefined && this.part.delay > 0) animationConfig.autoplay = false;
      return animationConfig;
    },
  },
});
