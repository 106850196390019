var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "" } },
    [
      _vm.loading
        ? _c("v-progress-circular", {
            attrs: { color: "primary", indeterminate: "" }
          })
        : _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _vm.isHistory
                ? _c("mood-trends", { attrs: { hideEntries: "" } })
                : _c("mood-daily", { attrs: { hideEntries: "" } })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }