<template>
  <v-layout row wrap>
    <v-flex xs12 class="text-center">
      <component v-bind:is="'checkup-results-'+chartType"
                 ref="graph"
                 :values="values"
                 :show-drilldown="showDrilldown"
                 :show-animation="showAnimation"
                 :show-labels="showLabels"
                 :canvas-width="$vuetify.breakpoint.smAndDown?'100%': $vuetify.breakpoint.mdOnly? '90%':'80%'"
                 @selected-assessment="onSelectedAssessment"/>
    </v-flex>

    <v-flex xs12 ref="graphTop" class="text-center mt-3">
      <v-btn
          v-for="type in chartTypes"
          :key="type.type"
          round large
          :color="type.type === chartType ? 'primary' : ''"
          @click="displayAs(type.type)">
        <v-icon>{{ type.icon }}</v-icon>
      </v-btn>
    </v-flex>

    <v-dialog :max-width="$vuetify.breakpoint.smAndDown? '90%':$vuetify.breakpoint.lgAndDown? '700px' : '800px'"
              v-if="selectedAssessment"
              v-model="modalOpen"
              content-class="radius-15">
      <v-card flat>
        <v-card-title>
          <h2 class="starling-h2" :style="'color:'+$t('app.assessments.' + selectedAssessment + '.color')+'!important;'">{{ $t('app.assessments.' + selectedAssessment + '.label') }}</h2>
        </v-card-title>

        <v-card-text class="p-5 text-center " style="min-height: 200px;" v-if="!interpretations[selectedAssessment]">
          <v-progress-circular :size="80" color="primary" indeterminate></v-progress-circular>
        </v-card-text>
        <v-card-text class="p-5" v-if="interpretations[selectedAssessment]">
          <element-styled-content class="pb-4"
            v-if="interpretations[selectedAssessment].matched"
            :content="interpretations[selectedAssessment].content">
          </element-styled-content>

          <v-layout v-else row wrap justify-center align-center>
            <v-flex class="mb-3" xs10>
              <h3 class="starling-h3 StarlingDarkGrey--text">{{ $t('app.assessments.drilldown.unavailable') }}</h3>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              :color="$t('app.assessments.' + selectedAssessment + '.color')"
              flat
              @click="onClose">
            {{ $t('common.actions.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import CheckupResultsBarchart from '@/views/home/components/checkup-results-barchart';
import CheckupResultsRadarchart from '@/views/home/components/checkup-results-radarchart';

export default {
  name: 'checkup-results-quick-glance',
  components: { ElementStyledContent: () => import('@/views/products/components/element-styled-content'), CheckupResultsRadarchart, CheckupResultsBarchart },
  props: {
    values: Object,
    interpretations: Object,
    chartTypes: {
      type: Array,
      default() {
        return [ { type: 'radarchart', icon: 'mdi-chart-donut-variant' }, { type: 'barchart', icon: 'mdi-poll' } ];
      },
      validator(value) {
        return value.length;
      },
    },
    showDrilldown: {
      type: Boolean,
      default: false,
    },
    showAnimation: {
      type: Boolean,
      default: true,
    },
    showLabels: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      chartType: this.chartTypes[0].type,
      selectedAssessment: null,
      modalOpen: false,
    };
  },
  watch: {
    modalOpen(newValue) {
      if (!newValue) {
        this.selectedAssessment = null;
      }
    },
    chartTypes(newValues) {
      if (!newValues.find(v => v.type === this.chartType)) {
        this.displayAs(newValues[0].type);
      }
    },
  },
  methods: {
    displayAs(mode) {
      this.chartType = mode;
    },
    onClose() {
      this.modalOpen = false;
      this.selectedAssessment = null;
    },
    onSelectedAssessment(assessment) {
      if (this.showDrilldown) {
        if (!this.interpretations[assessment]) {
          this.$emit('load-interpretation', assessment);
        }
        this.selectedAssessment = assessment;
        this.modalOpen = true;
      }
    },
  },
};
</script>

<style scoped>

</style>
