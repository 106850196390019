








import Vue from 'vue';
import { IStyleContent } from '@/scripts/store/modules/therapy/types';
import DepressionMeter from '@/views/home/components/depression-meter.vue';

export default Vue.extend({
  name: 'element-part-depression',
  components: { DepressionMeter },
  props: {
    part: {
      type: Object as () => IStyleContent,
      required: true,
    },
  },
});
