var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.part
    ? _c(
        "h3",
        { staticClass: "starling-h3", style: _vm.style },
        [_c("sm-markup-text", { attrs: { value: _vm.part.content } })],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }