













import Vue from 'vue';
import { IStyleContentAudio } from '@/scripts/store/modules/therapy/types';
import AudioPlayer from '@/views/components/utilities/audio-player.vue';

export default Vue.extend({
  name: 'element-part-audio',
  components: {
    AudioPlayer,
  },
  props: {
    part: {
      type: Object as () => IStyleContentAudio,
      required: true,
    },
  },
  data() {
    return {
      audioTitle: null,
      audioURL: null,
      audioTranscript: null,
      identifier: null,
    };
  },
  mounted() {
    this.getMediaAudio();
  },
  methods: {
    async getMediaAudio() {
      const media = await this.$store.dispatch(
        'therapy/getMedia',
        this.part.id,
      );
      if (media) {
        this.audioURL = media.url;
        this.audioTranscript = media.transcript;
        this.audioTitle = media.title;
        this.identifier = media.identifier;
      }
    },
  },
});
