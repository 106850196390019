<template>
    <v-textarea v-model="internalNotes"
                class="assessment-notes-web no-underline pb-0 ba-0"
                row-height="1"
                :placeholder="$t('common.actions.add_situation')"
                :maxlength="2000"
                :color="color"
                auto-grow
                :readonly="recording"
                @input="debounceInput">
      <template v-slot:append v-if="supported">
        <v-btn :color="recording? 'primary' : null"
               fab
               flat
               small
               @click.native="toggleSpeechRecognition">
          <v-icon medium>
            mic
          </v-icon>
        </v-btn>
      </template>
    </v-textarea>
  </template>

<script>
import _ from 'lodash';
import store from '@/scripts/store';
import { mapGetters } from 'vuex';

export default {
  name: 'input-note-web',
  props: {
    note: String,
    color: String,
    loading: Boolean,
    noteIndex: {
      type: Number,
      defualt: null,
    },
  },
  data() {
    return {
      internalNotes: this.note,
      savedNotes: this.note,
      debounceInput: undefined,
      debounceSpeech: undefined,
      error: false,
      supported: false,
      speaking: false,
      recording: false,
      dictation: false,
      recognition: null,
    };
  },
  computed: {
    currentLanguage() {
      return store.getters.currentLanguage;
    },
    ...mapGetters({
      isNativePlatform: 'isNativePlatform',
    }),
  },
  watch: {
    note(newNote) {
      this.internalNotes = newNote;
    },
    currentLanguage(val) {
      if (this.recognition) {
        this.recognition.lang = val;
      }
    },
    messages(newValue) {
      this.$log.info('message #' + newValue.length, newValue[newValue.length - 1]);
    },
    recording(val) {
      this.$emit('recording', val);
    },
  },
  created() {
    this.debounceInput = _.debounce(this.updatedValue, 1000);
    this.debounceSpeech = _.debounce(this.updatedValue, 1000);
  },
  mounted() {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = SpeechRecognition ? new SpeechRecognition() : false;

    this.$log.debug([ 'SpeechRecognition', SpeechRecognition, recognition ]);

    if (!recognition) {
      this.supported = false;
      this.$log.debug([ 'not supported' ]);
      return;
    }
    this.supported = true;

    recognition.interimResults = true;
    recognition.continuous = true;
    recognition.maxAlternatives = 10;
    recognition.lang = this.currentLanguage;

    recognition.onstart = () => {
      this.$log.debug([ 'recognition start' ]);
      this.currentValue = this.internalNotes;
    };
    recognition.onaudiostart = event => {
      this.$log.debug([ 'recognition audiostart', event ]);
      this.recording = true;
    };
    recognition.onsoundstart = event => {
      this.$log.debug([ 'recognition soundstart', event ]);
      this.speaking = true;
    };
    recognition.onspeechstart = event => {
      this.$log.debug([ 'recognition speechstart', event ]);
      this.dictation = true;
    };
    recognition.onspeechend = event => {
      this.$log.debug([ 'recognition speechend', event ]);
    };
    recognition.onsoundend = event => {
      this.$log.debug([ 'recognition soundend', event ]);
      this.speaking = false;
    };
    recognition.onaudioend = event => {
      this.$log.debug([ 'recognition audioend', event ]);
      this.recording = false;
    };
    recognition.onresult = event => {
      const resultList = event.results;
      const final = [ this.currentValue ];
      const parsed = [];
      for (let i = 0; i < resultList.length; i++) {
        parsed.push({
          final: resultList[i].isFinal,
          bestResult: { confidence: resultList[i][0].confidence, transcript: resultList[i][0].transcript },
        });
        if (resultList[i][0].transcript.trim().length > 0 && (resultList[i][0].confidence > 0 || resultList[i].isFinal)) {
          // if (resultList[i].isFinal) {
          final.push(resultList[i][0].transcript);
          // }
        }
      }
      this.internalNotes = final.join('') + (final.length ? '\n' : '');
      this.$log.debug([ 'recognition results', parsed, this.internalNotes, event ]);
      this.debounceSpeech(this.internalNotes);
    };
    recognition.onend = () => {
      this.$log.debug([ 'recognition end' ]);
    };

    this.recognition = recognition;
  },
  beforeDestroy() {
    this.recognition.abort();
  },
  methods: {
    updatedValue(value) {
      this.$log.debug([ 'updated value : ', value ]);
      this.savedNotes = value;
      if (this.noteIndex !== null && !this.loading) {
        this.$emit('updated', { note: this.savedNotes, dictation: this.dictation }, this.noteIndex);
      } else if (!this.loading) {
        this.$emit('updated', { note: this.savedNotes, dictation: this.dictation });
      }
    },
    toggleSpeechRecognition() {
      this.$log.debug([ 'toggle speech recognition', this.recording ]);
      if (this.recording) {
        this.$log.debug([ 'end speech recognition call' ]);
        this.endSpeechRecognition();
      } else {
        this.$log.debug([ 'start speech recognition call' ]);
        this.startSpeechRecognition();
      }
    },
    startSpeechRecognition() {
      if (!this.recognition) {
        this.$log.debug([ 'Speech Recognition is not available on this browser. Please use Chrome or Safari', this.recognition ]);
        return false;
      }
      this.$log.debug([ 'Start recognition' ]);
      try {
        this.recognition.start();
      } catch (err) {
        this.$log.debug([ 'Error when starting', err ]);
      }
    },
    endSpeechRecognition() {
      this.recognition.stop();
    },
  },
};
</script>

<style lang="scss">
.assessment-notes-web {
  .v-input__append-inner {
    color: currentColor;
  }

  &.no-underline .v-input__slot::before,
  &.no-underline .v-input__slot::after {
    margin-bottom: 0px !important;
    display: none !important;
  }

  &.no-underline ::v-deep .v-input__slot {
    margin-bottom: 0px !important;
  }

  .v-messages {
    min-height: 0px !important;
  }
}
</style>
