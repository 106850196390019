var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "note-header" },
      [
        _c("span", { staticClass: "starling-small-text" }, [
          _c("strong", [_vm._v(_vm._s(_vm.formattedNoteType))])
        ]),
        _c("v-divider", {
          staticClass: "note-divider ml-2",
          style: { backgroundColor: _vm.noteColor }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }