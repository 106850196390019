var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "assessment-history-scale",
      staticStyle: {
        position: "relative",
        top: "30px",
        height: "calc(100% - 75px)"
      },
      attrs: { row: "", wrap: "" }
    },
    _vm._l(_vm.scaleDefinition.intervals, function(interval, index) {
      return _c(
        "v-flex",
        {
          key: index,
          style:
            "position:absolute;left:0;top:calc(" +
            _vm.top(interval) +
            " - 18px)",
          attrs: { xs12: "" }
        },
        [
          _c(
            "p",
            {
              staticClass: "y-axis-label font-weight-bold text-left ma-0 pa-0",
              class: _vm.$vuetify.breakpoint.name
            },
            [_vm._v("\n      " + _vm._s(interval.description))]
          ),
          _c("p", {
            staticClass: "y-axis-label font-weight-regular ma-0 pa-0",
            class: _vm.$vuetify.breakpoint.name,
            domProps: { innerHTML: _vm._s(_vm.intervalRange(interval)) }
          })
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }