var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.sessionSteps
    ? _c(
        "v-layout",
        { attrs: { wrap: "", "py-1": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [_c("horizontal-steps", { attrs: { steps: _vm.sessionSteps } })],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }