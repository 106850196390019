























































































































































































































































































































































































import Vue from 'vue';
import { IStyleContent, StyleHorizontalEnum } from '@/scripts/store/modules/therapy/types';

export default Vue.extend({
  name: 'element-part-mm',
  props: {
    part: {
      type: Object as () => IStyleContent,
      required: true,
    },
  },
  data() {
    return {
      opened: false,
      scale: null as number | null,
      suffix: null as string | null,
    };
  },
  computed: {
    isInternetExplorer(): boolean {
      const ua = window.navigator.userAgent;
      const msie = ua.indexOf('MSIE ');
      const trident = ua.indexOf('Trident/');
      return trident > 0 || msie > 0;
    },
    justifyLeft(): boolean {
      return !this.part.horizontal || this.part.horizontal === StyleHorizontalEnum.LEFT;
    },
    justifyCenter(): boolean {
      return !!this.part.horizontal && this.part.horizontal === StyleHorizontalEnum.CENTER;
    },
    justifyRight(): boolean {
      return !!this.part.horizontal && this.part.horizontal === StyleHorizontalEnum.RIGHT;
    },
    computedHeight(): string {
      this.$log.debug(window.screen.availHeight);
      return 'min-height:' + (window.screen.availHeight * 0.75) + 'px';
    },
  },
  watch: {
    opened(newValue) {
      if (newValue) {
        const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') == -1 &&
            navigator.userAgent.indexOf('FxiOS') == -1;

        setTimeout(() => {
          document.getElementsByClassName('v-overlay--active')[0].classList.add('v-overlay--light');

          if (isSafari) {
            const modaleSvg = this.$refs.modaleSvg as SVGSVGElement;
            const rect = modaleSvg.getBoundingClientRect();
            const boxValues = modaleSvg.viewBox.baseVal;
            this.$log.debug(rect, rect.width, boxValues);

            this.scale = (rect.width / boxValues.width);
          } else {
            this.scale = 1;
          }
        }, 200);
      } else {
        this.scale = null;
        document.getElementsByClassName('v-overlay--active')[0].classList.remove('v-overlay--light');
      }
    },
  },
  mounted() {
    this.suffix = '-' + Math.floor(Math.random() * 10000);
  },
  methods: {
    openModal() {
      this.$log.debug('Modal should open');
      this.scale = null;
      this.opened = true;
    },
  },
});
