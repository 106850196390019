var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "styled-content", attrs: { xs12: "" } },
    [
      _c(
        "v-layout",
        {
          class: {
            wrap: _vm.$vuetify.breakpoint.xsOnly && !_vm.thumbnailMaxSize,
            "pa-2": _vm.isCardContent && !_vm.thumbnailMaxSize
          },
          attrs: { row: "", "gap-xs-2": "" }
        },
        [
          _vm.thumbnail
            ? _c(
                "v-flex",
                {
                  class: {
                    "order-xs1":
                      _vm.thumbnail.horizontal === _vm.horizontalStyles.RIGHT,
                    "xs12 sm3": !_vm.thumbnailMaxSize,
                    xs4: _vm.thumbnailMaxSize
                  }
                },
                [
                  _c(
                    "v-layout",
                    {
                      class: _vm.thumbnail.additionalclasses,
                      attrs: { row: "", "fill-height": "" }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c("element-part-thumbnail", {
                            key: _vm.thumbnail.content,
                            attrs: { part: _vm.thumbnail, uen: _vm.uen }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            {
              class: {
                xs12: !_vm.thumbnail,
                "xs12 sm9": _vm.thumbnail && !_vm.thumbnailMaxSize,
                xs8: _vm.thumbnail && _vm.thumbnailMaxSize,
                "py-2":
                  _vm.isCardContent && _vm.thumbnail && _vm.thumbnailMaxSize,
                "pl-2":
                  _vm.isCardContent &&
                  _vm.thumbnail &&
                  _vm.thumbnailMaxSize &&
                  _vm.thumbnail.horizontal === _vm.horizontalStyles.RIGHT,
                "pr-2":
                  _vm.isCardContent &&
                  _vm.thumbnail &&
                  _vm.thumbnailMaxSize &&
                  _vm.thumbnail.horizontal !== _vm.horizontalStyles.RIGHT
              }
            },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "", "fill-height": "" } },
                [
                  _vm._l(_vm.content, function(part, index) {
                    return [
                      part.key === _vm.typeStyles.BACKGROUND
                        ? _c(
                            "v-flex",
                            {
                              key: "back" + index,
                              class: part.additionalclasses,
                              attrs: { xs12: "" }
                            },
                            [
                              _c("element-part-background", {
                                attrs: { part: part, uen: _vm.uen },
                                on: { updateBackground: _vm.updateBackground }
                              })
                            ],
                            1
                          )
                        : _vm.showPart(part)
                        ? _c("v-flex", { key: index, attrs: { xs12: "" } }, [
                            _c(
                              "div",
                              {
                                class: [
                                  {
                                    "text-left":
                                      !part.horizontal ||
                                      part.horizontal ===
                                        _vm.horizontalStyles.LEFT,
                                    "text-center":
                                      part.horizontal &&
                                      part.horizontal ===
                                        _vm.horizontalStyles.CENTER,
                                    "text-right":
                                      part.horizontal &&
                                      part.horizontal ===
                                        _vm.horizontalStyles.RIGHT
                                  },
                                  part.additionalclasses
                                ]
                              },
                              [
                                part.key === _vm.typeStyles.CONTENT ||
                                part.key === _vm.typeStyles.PLACEHOLDER
                                  ? _vm._t("default")
                                  : _vm.componentName(part.key)
                                  ? _c(_vm.componentName(part.key), {
                                      tag: "component",
                                      class: {
                                        "pb-3": _vm.additionalPadding(
                                          part,
                                          index
                                        )
                                      },
                                      attrs: { part: part, uen: _vm.uen }
                                    })
                                  : _vm._e()
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ]
                  }),
                  _vm._t("action")
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }