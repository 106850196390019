var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "" } },
    [
      _c(
        "v-flex",
        { attrs: { xs12: "", md10: "" } },
        [
          _vm.value && _vm.interpretations
            ? _c("checkup-results-quick-glance", {
                attrs: {
                  values: _vm.value,
                  interpretations: _vm.interpretations,
                  "show-drilldown": _vm.part.showDrilldown
                },
                on: { "load-interpretation": _vm.onLoadInterpretation }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }