





import Vue from 'vue';

export default Vue.extend({
  name: 'element-part-graphic-inline',
  props: {
    src: {
      type: String,
      required: true,
    },
    altText: {
      type: String,
      required: false,
    },
    attach: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      svgElement: null as string | null,
    };
  },
  watch: {
    src: {
      immediate: true,
      handler(val) {
        const parser: DOMParser = new DOMParser();
        const svg: SVGElement = parser.parseFromString(val, 'text/html').body.childNodes[0] as SVGElement;
        svg.setAttribute('width', '100%');
        svg.setAttribute('height', '100%');
        svg.setAttribute('role', 'img');
        svg.setAttribute('aria-label', this.altText);
        const title: HTMLTitleElement = document.createElement('title');
        title.innerText = this.altText;
        svg.insertBefore(title, svg.firstChild);
        this.svgElement = svg.outerHTML;
      },
    },
  },
  mounted() {
    if (this.attach) {
      this.$nextTick(() => {
        try {
          const target = this.$el.closest(this.attach);
          target?.insertBefore(this.$el, target.firstChild);
        } catch (err) {
          this.$log.debug('Error attaching image', err);
        }
      });
    }
  },
  beforeDestroy() {
    if (this.attach) {
      try {
        const imageElement = this.$refs.image as HTMLElement;
        imageElement?.parentNode?.removeChild(imageElement);
      } catch (err) {
        this.$log.debug('Error removing attached image', err);
      }
    }
  },
});
