<template>
  <canvas ref="graph-bar"></canvas>
</template>

<script>
import Chart from 'chart.js';

export default {
  name: 'checkup-results-barchart',
  props: {
    values: Object,
    showDrilldown: {
      type: Boolean,
      default: false,
    },
    showAnimation: {
      type: Boolean,
      default: false,
    },
    showLabels: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartRef: null,
      config: {
        type: 'bar',
        data: {
          labels: [],
          datasets: [ {
            id: [],
            data: [],
            backgroundColor: [],
            barPercentage: 0.65,
          } ],
        },
        options: {
          legend: {
            display: false,
          },
          tooltips: {
            callbacks: {
              label: (x) => {
                return x.xLabel + ': ' + this.$t('app.assessments.simplifiedScoreScale').filter(t => !t.hideInScale)[this.$t('app.assessments.simplifiedScoreScale').filter(t => !t.hideInScale).length - x.yLabel].label;
              },
              title: (x) => {
                return '';
              },
            },
          },
          scales: {
            xAxes: [ {
              gridLines: {
                display: false,
              },
              ticks: {
                fontSize: 22,
                fontStyle: 'bold',
              },
            },
            ],
            yAxes: [
              {
                gridLines: {
                  display: true,
                },
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 4,
                  stepSize: 1,
                  fontSize: 16,
                  callback: (value) => {
                    if (value) {
                      return this.$t('app.assessments.simplifiedScoreScale').filter(t => !t.hideInScale)[this.$t('app.assessments.simplifiedScoreScale').filter(t => !t.hideInScale).length - value].label;
                    }
                    return '';
                  },
                },
              },
            ],
          },
          onHover: (event, chartElement) => {
            if (this.showDrilldown) {
              event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
            }
          },
          onClick: (evt) => {
            const elements = this.chartRef.getElementAtEvent(evt);
            if (elements && elements.length) {
              const element = elements[0];
              const dataset = this.chartRef.data.datasets[element._datasetIndex];
              if (dataset) {
                const data = dataset.id[element._index];
                if (data) {
                  this.$emit('selected-assessment', data);
                }
              }
            }
          },
          onResize: (chart, evt) => {
            chart.update();
          },
        },
      },
    };
  },
  watch: {
    values: {
      immediate: false,
      deep: true,
      handler(newValues) {
        this.updateValues(newValues);
      },
    },
    showAnimation: {
      immediate: true,
      handler(newValues) {
        if (!newValues) {
          this.config.options.animation = { duration: 0 };
        } else {
          delete this.config.options.animation;
        }
        if (this.chartRef) {
          if (!newValues) {
            this.chartRef.options.animation = { duration: 0 };
          } else {
            delete this.chartRef.options.animation;
          }
          this.chartRef.update();
        }
      },
    },
    showLabels: {
      immediate: true,
      handler(newValues) {
        if (!newValues) {
          this.config.options.scales.xAxes.forEach(axe => {
            axe.ticks.display = false;
          });
          this.config.options.scales.yAxes.forEach(axe => {
            axe.ticks.display = false;
            axe.gridLines.display = false;
          });
        } else {
          this.config.options.scales.xAxes.forEach(axe => {
            axe.ticks.display = true;
          });
          this.config.options.scales.yAxes.forEach(axe => {
            axe.ticks.display = true;
            axe.gridLines.display = true;
          });
        }
        if (this.chartRef) {
          if (!newValues) {
            this.chartRef.options.scales.xAxes.forEach(axe => {
              axe.ticks.display = false;
            });
            this.chartRef.options.scales.yAxes.forEach(axe => {
              axe.ticks.display = false;
              axe.gridLines.display = false;
            });
          } else {
            this.chartRef.options.scales.xAxes.forEach(axe => {
              axe.ticks.display = true;
            });
            this.chartRef.options.scales.yAxes.forEach(axe => {
              axe.ticks.display = true;
              axe.gridLines.display = true;
            });
          }
          this.chartRef.update();
        }
      },
    },
    '$vuetify.breakpoint': {
      immediate: true,
      deep: true,
      handler(breakpoint) {
        if (breakpoint.xsOnly) {
          this.config.options.scales.yAxes[0].ticks.fontSize = 10;
          this.config.options.scales.xAxes[0].ticks.fontSize = 14;
        } else if (breakpoint.smOnly) {
          this.config.options.scales.yAxes[0].ticks.fontSize = 11;
          this.config.options.scales.xAxes[0].ticks.fontSize = 16;
        } else if (breakpoint.mdOnly) {
          this.config.options.scales.yAxes[0].ticks.fontSize = 13;
          this.config.options.scales.xAxes[0].ticks.fontSize = 18;
        } else if (breakpoint.lgOnly) {
          this.config.options.scales.yAxes[0].ticks.fontSize = 15;
          this.config.options.scales.xAxes[0].ticks.fontSize = 20;
        } else if (breakpoint.xlOnly) {
          this.config.options.scales.yAxes[0].ticks.fontSize = 15;
          this.config.options.scales.xAxes[0].ticks.fontSize = 22;
        }

        if (this.chartRef) {
          this.chartRef.update();
        }
      },
    },
  },
  mounted() {
    const ctx = this.$refs['graph-bar'];
    this.chartRef = new Chart(ctx, this.config);
    this.updateValues(this.values);
  },
  beforeDestroy() {
    this.chartRef.destroy();
  },
  methods: {
    updateValues(newValues) {
      this.config.data.labels = [];
      this.config.data.datasets[0].id = [];
      this.config.data.datasets[0].data = [];
      this.config.data.datasets[0].backgroundColor = [];

      if (newValues) {
        const assessmentNames = Object.keys(newValues);
        assessmentNames.map(assessmentName => {
          return this.$t('app.assessments.' + assessmentName);
        }).sort((a, b) => a.order - b.order).forEach(assessment => {
          const scaleItem = this.$t('app.assessments.simplifiedScoreScale').find(e => e.name === newValues[assessment.name].simplifiedValue);
          this.chartRef.data.labels.push(assessment.label);
          this.chartRef.data.datasets[0].id.push(assessment.name);
          this.chartRef.data.datasets[0].data.push(scaleItem.value + 1);
          this.chartRef.data.datasets[0].backgroundColor.push(assessment.color);
        });
      }

      this.chartRef.update();
    },
    onSelect(assessment) {
      this.$emit('selected-assessment', assessment);
    },
  },
};
</script>

<style scoped>
</style>
