
































































































import Vue from 'vue';
import { IStyleContent } from '@/scripts/store/modules/therapy/types';
import CustomConfirmationModal from '@/views/components/utilities/custom-confirmation-modal.vue';

export default Vue.extend({
  name: 'element-part-webinar-registration',
  components: { CustomConfirmationModal },
  props: {
    part: {
      type: Object as () => IStyleContent,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      isRegistered: false,
      joinUrl: undefined as string | undefined,
      showConfirmation: false,
      showErrorMessage: false,
      showRegisterMessage: false,
      showRemovedMessage: false,
    };
  },
  mounted() {
    this.loading = true;

    this.$store.dispatch('webinar/checkRegistrationWebinar', this.part.id)
      .then(res => {
        this.isRegistered = res.isRegistered;
        this.joinUrl = res.joinUrl;
      }).finally(() => {
        this.loading = false;
      });
  },
  methods: {
    handleRegistrationClick() {
      if (this.loading) return;

      if (this.isRegistered) {
        this.showConfirmation = true;
        return;
      }

      this.loading = true;
      this.showErrorMessage = false;

      this.$store.dispatch('webinar/registerWebinar', this.part.id)
        .then(res => {
          if (res?.status >= 400) throw Error();
          this.isRegistered = res.isRegistered;
          this.joinUrl = res.joinUrl;
        }).catch(() => {
          this.showErrorMessage = true;
        }).finally(() => {
          this.loading = false;
        });
    },
    onConfirmCancel() {
      this.showConfirmation = false;
    },
    onConfirmDelete() {
      this.loading = true;
      this.showConfirmation = false;
      this.showErrorMessage = false;

      this.$store.dispatch('webinar/unregisterWebinar', this.part.id)
        .then(res => {
          if (res?.status >= 400) throw Error();
          this.isRegistered = false;
          this.joinUrl = undefined;
        }).catch(() => {
          this.showErrorMessage = true;
        }).finally(() => {
          this.loading = false;
        });
    },
  },
});
