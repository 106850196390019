var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      class: {
        "justify-start": _vm.justifyLeft,
        "justify-center": _vm.justifyCenter,
        "justify-end": _vm.justifyRight
      },
      style: _vm.justifyCenter ? "margin-left:44px" : ""
    },
    [
      _c("v-flex", { attrs: { shrink: "" } }, [
        _c(
          "svg",
          {
            staticClass: "clickable",
            attrs: {
              width: "98",
              height: "98",
              viewBox: "0 0 98 98",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg",
              "xmlns:xlink": "http://www.w3.org/1999/xlink"
            },
            on: { click: _vm.openModal }
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M98 48.9949C98 61.4512 93.8475 66.4337 89.6949 75.5684C86.622 82.3778 81.3898 88.0247 73.9153 91.3464C66.1915 94.7511 63.1186 97.9898 49 97.9898C35.878 97.9898 22.4237 93.0072 15.1983 84.5369C11.7932 80.4679 9.13559 76.3988 5.64746 71.8315C0.91356 65.7694 0 57.2161 0 48.9949C0 31.556 10.4644 14.2833 22.4237 8.30422C30.7288 4.15211 36.5424 0 49 0C63.1186 0 71.5068 4.48428 80.1441 11.2107C87.2034 16.6084 89.6949 20.9266 92.7678 26.9887C96.0898 33.6321 98 41.1059 98 48.9949Z",
                fill: "url(#mm-inactive-paint0_linear" + _vm.suffix + ")"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M30.1475 76.897C30.1475 76.897 34.1339 73.6583 49.7475 73.6583C63.3678 73.6583 67.1051 77.9765 67.1051 77.9765C67.1051 77.9765 75.5763 97.4914 49.8306 98.8201C24.0848 100.149 30.1475 76.897 30.1475 76.897Z",
                fill: "url(#mm-inactive-paint1_linear" + _vm.suffix + ")"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M34.5491 63.112C34.5491 63.112 34.1338 81.7135 26.4932 85.2012C21.4271 87.4434 36.7084 98.1558 48.9999 98.0728C61.2915 97.9897 67.105 85.2012 67.105 85.2012C67.105 85.2012 64.9457 73.4923 64.9457 65.4372C64.8626 61.2851 34.5491 63.112 34.5491 63.112Z",
                fill: "url(#mm-inactive-paint2_radial" + _vm.suffix + ")"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M47.7542 15.1137C56.6406 14.7815 63.6169 17.688 67.2711 22.3384C70.9254 26.9887 69.5135 41.6872 69.5135 41.6872C69.5135 41.6872 69.7626 38.1164 71.5898 38.1164C73.666 38.1164 73.7491 41.5211 73.666 44.4276C73.4999 51.8183 71.922 55.7213 69.6796 54.9739C68.3508 54.5587 68.4338 55.2231 68.4338 55.2231C68.4338 55.2231 69.5965 62.7799 65.7762 68.842C63.4508 72.6619 56.8067 76.8971 49.083 76.8971C41.3593 76.8971 34.2999 70.9181 32.805 65.8525C31.3101 60.8699 31.5593 54.3927 31.5593 54.3927C31.5593 54.3927 30.0643 54.7248 29.7321 53.9774C29.3999 53.2301 27.0745 48.3306 27.1576 47.168C27.2406 46.0054 26.7423 39.1129 28.8186 38.6977C30.9779 38.2825 31.144 39.9433 31.144 39.9433C31.144 39.9433 28.9016 28.8987 31.2271 24.3314C33.5525 19.7641 38.1203 15.3628 47.7542 15.1137Z",
                fill: "url(#mm-inactive-paint3_linear" + _vm.suffix + ")"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M47.7542 15.1137C56.6406 14.7815 63.6169 17.688 67.2711 22.3384C70.9254 26.9887 69.5135 41.6872 69.5135 41.6872C69.5135 41.6872 69.7626 38.1164 71.5898 38.1164C73.666 38.1164 73.7491 41.5211 73.666 44.4276C73.4999 51.8183 71.922 55.7213 69.6796 54.9739C68.3508 54.5587 68.4338 55.2231 68.4338 55.2231C68.4338 55.2231 69.5965 62.7799 65.7762 68.842C63.4508 72.6619 56.8067 76.8971 49.083 76.8971C41.3593 76.8971 34.2999 70.9181 32.805 65.8525C31.3101 60.8699 31.5593 54.3927 31.5593 54.3927C31.5593 54.3927 30.0643 54.7248 29.7321 53.9774C29.3999 53.2301 27.0745 48.3306 27.1576 47.168C27.2406 46.0054 26.7423 39.1129 28.8186 38.6977C30.9779 38.2825 31.144 39.9433 31.144 39.9433C31.144 39.9433 28.9016 28.8987 31.2271 24.3314C33.5525 19.7641 38.1203 15.3628 47.7542 15.1137Z",
                fill: "url(#mm-inactive-paint4_radial" + _vm.suffix + ")"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M52.8203 44.8428C52.8203 44.8428 56.2254 41.6041 61.0424 44.2615",
                stroke: "#2194DD",
                "stroke-miterlimit": "10",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M36.7085 44.2614C36.7085 44.2614 36.7915 43.1819 39.8644 43.2649C42.9373 43.2649 44.1 44.2614 44.1 44.2614",
                stroke: "#2194DD",
                "stroke-miterlimit": "10",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M49.0001 97.9898C63.1188 97.9898 66.1917 94.7512 73.9154 91.3464C77.3205 89.8517 80.2272 87.8587 82.7188 85.4504C82.2205 85.2013 81.6391 84.9522 81.0578 84.7031C76.573 83.0422 73.6662 83.8726 71.2578 82.627C68.8493 81.3814 67.0222 77.9766 67.0222 77.9766C68.9323 84.537 64.5306 89.1873 54.3984 91.0973C53.9001 91.1803 53.8171 97.9898 51.1595 97.4085C49.2493 96.9933 48.1696 91.0973 47.5883 91.0973C31.0612 90.6821 30.0645 76.8971 30.0645 76.8971C28.4035 77.9766 27.8222 80.9662 26.4103 81.7966C23.5035 83.4574 20.0984 84.9522 16.9425 86.3639C24.4171 93.7547 36.8747 97.9898 49.0001 97.9898Z",
                fill: "url(#mm-inactive-paint5_linear" + _vm.suffix + ")"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M56.8898 97.5746C60.461 98.9033 74.2475 90.9312 71.1746 81.5474C69.6797 79.3053 67.022 77.9766 67.022 77.9766C68.9322 84.537 64.5305 89.1873 54.3983 91.0973C54.4814 91.0973 55.7271 97.1594 56.8898 97.5746Z",
                fill: "url(#mm-inactive-paint6_linear" + _vm.suffix + ")"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M30.1475 76.8971C27.5729 78.641 26.9085 80.3849 26.7424 81.2983C29.4001 92.2599 44.2662 100.979 45.595 96.7442C46.9238 92.3429 47.7543 91.0973 47.7543 91.0973C31.1441 90.6821 30.1475 76.8971 30.1475 76.8971Z",
                fill: "url(#mm-inactive-paint7_linear" + _vm.suffix + ")"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M40.1135 59.9565C40.7779 59.3752 40.944 60.4547 42.1898 60.7869C44.0999 61.2021 47.0067 61.7003 48.9999 61.6173C50.8271 61.5343 53.7338 61.7003 55.4779 60.953C56.4745 60.5378 57.5542 59.3752 58.4677 60.1225C58.966 60.5378 54.066 62.6138 48.9169 62.5308C43.7677 62.3647 39.4491 60.5377 40.1135 59.9565Z",
                fill: "#2194DD"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M38.7849 43.265C38.7019 43.5141 38.7019 44.4276 39.0341 44.5106C39.3663 44.5937 39.5324 44.5106 40.7781 44.5106C42.0239 44.5106 41.8578 43.4311 41.7747 43.4311C41.2764 43.3481 40.612 43.265 39.8646 43.265C39.4493 43.265 39.1171 43.265 38.7849 43.265Z",
                fill: "#2194DD"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M58.7999 43.431C57.5542 43.0988 56.3915 43.2649 55.4779 43.431C55.561 44.0123 55.7271 44.8427 56.0593 44.8427C56.5576 44.7597 57.9694 44.5936 58.3847 44.5105C58.6338 44.5105 58.7999 43.9292 58.7999 43.431Z",
                fill: "#2194DD"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M31.1442 41.6041C31.1442 41.6041 31.8916 37.452 31.9747 35.8742C32.3899 27.57 34.9645 25.8261 36.7086 23.667C36.7086 23.667 43.7679 25.494 48.4187 25.494C57.2221 25.494 62.7035 23.667 62.7035 23.667C62.7035 23.667 66.3577 40.2755 69.2645 43.265L69.6798 41.355C69.6798 41.355 71.5899 28.4835 71.3408 26.1583C71.0916 23.8331 70.5933 15.612 61.3747 14.0342C61.3747 14.0342 58.1357 10.3803 50.2459 9.38379C42.7713 8.47033 34.4662 9.46684 29.0679 14.9476C25.6628 18.4354 25.4967 22.8366 29.0679 26.4074C29.0679 26.4074 28.9848 35.9573 31.1442 41.6041Z",
                fill: "#2194DD"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M34.6322 39.5281C34.383 39.8603 34.7983 40.3585 35.1305 40.1094C36.2101 39.5281 38.1203 38.8638 41.2762 38.7807C43.2694 38.6977 44.0169 39.5281 44.9305 39.1129C47.1728 38.0333 46.0932 36.7047 46.0932 36.7047C46.0932 36.7047 44.4322 36.2064 41.2762 36.7047C38.7847 37.1199 36.044 37.7012 34.6322 39.5281Z",
                fill: "#2194DD"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M63.7001 39.362C64.0323 39.6942 63.617 40.2755 63.2018 40.0264C62.0391 39.4451 60.212 38.8638 57.3052 38.7807C55.312 38.6977 54.5645 39.5281 53.6509 39.1129C51.9069 38.2825 52.156 37.2029 52.4052 36.8708C52.4882 36.7877 52.5713 36.7047 52.6543 36.6217C53.1526 36.5386 54.7306 36.2064 57.2221 36.7047C59.6306 37.1199 62.2052 37.7012 63.7001 39.362Z",
                fill: "#2194DD"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M46.5084 64.1916C46.5084 64.1916 48.4186 64.6068 51.1593 64.1916C53.8999 63.7764 50.9101 64.8559 49.8305 64.8559C48.6677 64.8559 47.0898 64.7729 46.5084 64.1916Z",
                fill: "#2194DD"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M53.1525 54.3096C52.8203 53.9775 52.322 54.8079 50.9932 54.8079C49.6644 54.8079 49.7474 55.2231 48.7508 55.14C47.7542 55.057 47.0898 54.3927 46.4254 54.5587C44.5983 55.14 44.1 53.9775 43.7678 54.2266C43.5186 54.3927 44.183 55.3892 44.5983 55.5553C45.844 56.1366 45.844 55.7213 46.8406 55.8044C48.5017 55.9705 47.3389 56.4687 48.5017 56.4687C50.8271 56.4687 49.9966 55.6383 50.9101 55.8044C52.7372 56.1365 53.4017 54.5588 53.1525 54.3096Z",
                fill: "#2194DD"
              }
            }),
            _c(
              "defs",
              [
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "mm-inactive-paint0_linear" + _vm.suffix,
                      x1: "48.984",
                      y1: "-2.20145",
                      x2: "49.0147",
                      y2: "95.7883",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { offset: "0.0168194", "stop-color": "#FF777E" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.908", "stop-color": "#FFE5E4" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "mm-inactive-paint1_linear" + _vm.suffix,
                      x1: "48.4493",
                      y1: "99.2037",
                      x2: "49.7609",
                      y2: "58.7179",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#DCFCF8" } }),
                    _c("stop", {
                      attrs: { offset: "0.1003", "stop-color": "#D0F5F2" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.2811", "stop-color": "#B0E3E2" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5219", "stop-color": "#7DC6C7" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.81", "stop-color": "#379EA3" }
                    }),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#058189" }
                    })
                  ],
                  1
                ),
                _c(
                  "radialGradient",
                  {
                    attrs: {
                      id: "mm-inactive-paint2_radial" + _vm.suffix,
                      cx: "0",
                      cy: "0",
                      r: "1",
                      gradientUnits: "userSpaceOnUse",
                      gradientTransform:
                        "translate(49.6975 63.71) scale(35.2073 35.2036)"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { offset: "0.00155133", "stop-color": "#4C75AA" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.1661", "stop-color": "#427CB6" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.4616", "stop-color": "#288FD5" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5261", "stop-color": "#2194DD" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.7279", "stop-color": "#2194DD" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.8036", "stop-color": "#2492D9" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.8804", "stop-color": "#2F8ACD" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.9576", "stop-color": "#407EB9" }
                    }),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#4C75AA" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "mm-inactive-paint3_linear" + _vm.suffix,
                      x1: "50.216",
                      y1: "106.803",
                      x2: "50.65",
                      y2: "-13.6953",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { offset: "0.1835", "stop-color": "#2FCEFB" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.8607", "stop-color": "#BFD8E6" }
                    })
                  ],
                  1
                ),
                _c(
                  "radialGradient",
                  {
                    attrs: {
                      id: "mm-inactive-paint4_radial" + _vm.suffix,
                      cx: "0",
                      cy: "0",
                      r: "1",
                      gradientUnits: "userSpaceOnUse",
                      gradientTransform:
                        "translate(59.1062 49.9611) scale(46.1619 46.1571)"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { "stop-color": "#FFE5E4", "stop-opacity": "0.5" }
                    }),
                    _c("stop", {
                      attrs: {
                        offset: "0.9975",
                        "stop-color": "#4C75AA",
                        "stop-opacity": "0"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "mm-inactive-paint5_linear" + _vm.suffix,
                      x1: "49.4494",
                      y1: "98.2625",
                      x2: "50.2008",
                      y2: "75.0693",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#DCFCF8" } }),
                    _c("stop", {
                      attrs: { offset: "0.32", "stop-color": "#E8F4F1" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.7567", "stop-color": "#FFE5E4" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "mm-inactive-paint6_linear" + _vm.suffix,
                      x1: "62.7223",
                      y1: "98.0791",
                      x2: "63.7584",
                      y2: "66.0971",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { "stop-color": "white", "stop-opacity": "0.9" }
                    }),
                    _c("stop", {
                      attrs: {
                        offset: "0.4398",
                        "stop-color": "#FFF4F3",
                        "stop-opacity": "0.9519"
                      }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.8475", "stop-color": "#FFE5E4" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "mm-inactive-paint7_linear" + _vm.suffix,
                      x1: "36.7983",
                      y1: "97.8197",
                      x2: "37.858",
                      y2: "65.1103",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { "stop-color": "white", "stop-opacity": "0.9" }
                    }),
                    _c("stop", {
                      attrs: {
                        offset: "0.4398",
                        "stop-color": "#FFF4F3",
                        "stop-opacity": "0.9519"
                      }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.8475", "stop-color": "#FFE5E4" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "v-flex",
        { attrs: { shrink: "" } },
        [
          _c(
            "v-btn",
            {
              staticStyle: { "margin-left": "-8px", "margin-top": "-8px" },
              attrs: { icon: "", flat: "", large: "", color: "primary" },
              on: { click: _vm.openModal }
            },
            [_c("v-icon", [_vm._v("mdi-information-outline")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "mm-dialog",
            scrollable: false,
            persistent: "",
            "no-click-animation": ""
          },
          model: {
            value: _vm.opened,
            callback: function($$v) {
              _vm.opened = $$v
            },
            expression: "opened"
          }
        },
        [
          !_vm.isInternetExplorer
            ? _c(
                "v-container",
                { staticClass: "pa-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "text-center",
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-center": "",
                        "align-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        {
                          style: _vm.computedHeight,
                          attrs: { xs12: "", md7: "", lg4: "" }
                        },
                        [
                          _c(
                            "svg",
                            {
                              ref: "modaleSvg",
                              staticStyle: { width: "100%", "z-index": "1000" },
                              style: _vm.computedHeight,
                              attrs: {
                                viewBox: _vm.$vuetify.breakpoint.xsOnly
                                  ? "0 0 394 512"
                                  : "0 0 480 512",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M237.098 447.998C237.098 447.998 505.445 451.355 470.936 191.59C436.426 -68.1746 194.779 -3.53023 137.986 42.1998C81.1924 87.9299 -14.567 211.651 89.002 304.175C192.571 396.7 104.58 440.178 104.58 440.178L237.098 447.998Z",
                                  fill:
                                    "url(#mm-active-paint0_linear" +
                                    _vm.suffix +
                                    ")"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M102 459.995C102 472.959 97.678 478.145 93.3559 487.653C90.1576 494.74 84.7119 500.618 76.9322 504.075C68.8932 507.619 65.6949 510.989 51 510.989C37.3424 510.989 23.339 505.803 15.8186 496.987C12.2746 492.752 9.50847 488.517 5.87797 483.763C0.950848 477.454 0 468.551 0 459.995C0 441.844 10.8915 423.866 23.339 417.643C31.9831 413.322 38.0339 409 51 409C65.6949 409 74.4254 413.667 83.4153 420.668C90.7627 426.286 93.3559 430.781 96.5542 437.09C100.012 444.005 102 451.784 102 459.995Z",
                                  fill:
                                    "url(#mm-active-paint1_linear" +
                                    _vm.suffix +
                                    ")"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M31.3781 489.036C31.3781 489.036 35.5272 485.665 51.7781 485.665C65.9543 485.665 69.8442 490.159 69.8442 490.159C69.8442 490.159 78.6611 510.471 51.8645 511.854C25.0679 513.236 31.3781 489.036 31.3781 489.036Z",
                                  fill:
                                    "url(#mm-active-paint2_linear" +
                                    _vm.suffix +
                                    ")"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M35.9595 474.688C35.9595 474.688 35.5273 494.049 27.5747 497.679C22.3018 500.012 38.2069 511.162 51.0002 511.076C63.7934 510.989 69.8442 497.679 69.8442 497.679C69.8442 497.679 67.5968 485.492 67.5968 477.108C67.5103 472.786 35.9595 474.688 35.9595 474.688Z",
                                  fill:
                                    "url(#mm-active-paint3_radial" +
                                    _vm.suffix +
                                    ")"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M49.7035 424.731C58.9526 424.385 66.2136 427.41 70.017 432.25C73.8204 437.09 72.3509 452.389 72.3509 452.389C72.3509 452.389 72.6102 448.672 74.5119 448.672C76.673 448.672 76.7594 452.216 76.673 455.241C76.5001 462.933 74.8577 466.996 72.5238 466.218C71.1408 465.786 71.2272 466.477 71.2272 466.477C71.2272 466.477 72.4374 474.342 68.4611 480.652C66.0407 484.628 59.1255 489.036 51.0865 489.036C43.0475 489.036 35.7001 482.813 34.1441 477.54C32.5882 472.354 32.8475 465.613 32.8475 465.613C32.8475 465.613 31.2916 465.959 30.9458 465.181C30.6001 464.403 28.1797 459.303 28.2662 458.093C28.3526 456.883 27.834 449.709 29.995 449.277C32.2424 448.845 32.4153 450.574 32.4153 450.574C32.4153 450.574 30.0814 439.078 32.5018 434.325C34.9221 429.571 39.6763 424.99 49.7035 424.731Z",
                                  fill:
                                    "url(#mm-active-paint4_linear" +
                                    _vm.suffix +
                                    ")"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M49.7035 424.731C58.9526 424.385 66.2136 427.41 70.017 432.25C73.8204 437.09 72.3509 452.389 72.3509 452.389C72.3509 452.389 72.6102 448.672 74.5119 448.672C76.673 448.672 76.7594 452.216 76.673 455.241C76.5001 462.933 74.8577 466.996 72.5238 466.218C71.1408 465.786 71.2272 466.477 71.2272 466.477C71.2272 466.477 72.4374 474.342 68.4611 480.652C66.0407 484.628 59.1255 489.036 51.0865 489.036C43.0475 489.036 35.7001 482.813 34.1441 477.54C32.5882 472.354 32.8475 465.613 32.8475 465.613C32.8475 465.613 31.2916 465.959 30.9458 465.181C30.6001 464.403 28.1797 459.303 28.2662 458.093C28.3526 456.883 27.834 449.709 29.995 449.277C32.2424 448.845 32.4153 450.574 32.4153 450.574C32.4153 450.574 30.0814 439.078 32.5018 434.325C34.9221 429.571 39.6763 424.99 49.7035 424.731Z",
                                  fill:
                                    "url(#mm-active-paint5_radial" +
                                    _vm.suffix +
                                    ")"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M54.9763 455.673C54.9763 455.673 58.5204 452.302 63.534 455.068",
                                  stroke: "#2194DD",
                                  "stroke-miterlimit": "10",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M38.2068 455.068C38.2068 455.068 38.2932 453.944 41.4915 454.031C44.6898 454.031 45.9 455.068 45.9 455.068",
                                  stroke: "#2194DD",
                                  "stroke-miterlimit": "10",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M50.9999 510.989C65.6948 510.989 68.8931 507.619 76.9321 504.075C80.4762 502.519 83.5016 500.445 86.0948 497.938C85.5762 497.679 84.9711 497.42 84.366 497.16C79.6982 495.432 76.6728 496.296 74.166 495C71.6592 493.703 69.7575 490.159 69.7575 490.159C71.7456 496.987 67.1643 501.828 56.6185 503.816C56.0999 503.902 56.0134 510.989 53.2473 510.384C51.2592 509.952 50.1355 503.816 49.5304 503.816C32.3287 503.383 31.2914 489.036 31.2914 489.036C29.5626 490.159 28.9575 493.271 27.488 494.135C24.4626 495.864 20.9185 497.42 17.6338 498.889C25.4134 506.581 38.3795 510.989 50.9999 510.989Z",
                                  fill:
                                    "url(#mm-active-paint6_linear" +
                                    _vm.suffix +
                                    ")"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M59.2116 510.557C62.9286 511.94 77.2777 503.643 74.0794 493.876C72.5235 491.542 69.7574 490.159 69.7574 490.159C71.7455 496.987 67.1642 501.828 56.6184 503.816C56.7048 503.816 58.0015 510.125 59.2116 510.557Z",
                                  fill:
                                    "url(#mm-active-paint7_linear" +
                                    _vm.suffix +
                                    ")"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M31.3781 489.036C28.6984 490.851 28.0069 492.666 27.834 493.617C30.6001 505.026 46.073 514.101 47.456 509.693C48.8391 505.112 49.7035 503.816 49.7035 503.816C32.4153 503.383 31.3781 489.036 31.3781 489.036Z",
                                  fill:
                                    "url(#mm-active-paint8_linear" +
                                    _vm.suffix +
                                    ")"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M41.7507 471.404C42.4422 470.799 42.6151 471.922 43.9117 472.268C45.8998 472.7 48.9252 473.219 50.9998 473.132C52.9015 473.046 55.9269 473.219 57.7422 472.441C58.7795 472.009 59.9032 470.799 60.8541 471.577C61.3727 472.009 56.2727 474.169 50.9134 474.083C45.5541 473.91 41.0591 472.009 41.7507 471.404Z",
                                  fill: "#2194DD"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M40.3678 454.031C40.2814 454.29 40.2814 455.241 40.6272 455.327C40.9729 455.414 41.1458 455.327 42.4424 455.327C43.739 455.327 43.5661 454.204 43.4797 454.204C42.9611 454.117 42.2695 454.031 41.4916 454.031C41.0594 454.031 40.7136 454.031 40.3678 454.031Z",
                                  fill: "#2194DD"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M61.1998 454.204C59.9032 453.858 58.693 454.031 57.7422 454.204C57.8286 454.809 58.0015 455.673 58.3473 455.673C58.8659 455.587 60.3354 455.414 60.7676 455.327C61.0269 455.327 61.1998 454.722 61.1998 454.204Z",
                                  fill: "#2194DD"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M32.4153 452.302C32.4153 452.302 33.1933 447.981 33.2797 446.338C33.7119 437.695 36.3916 435.88 38.2068 433.633C38.2068 433.633 45.5543 435.535 50.3949 435.535C59.5577 435.535 65.2627 433.633 65.2627 433.633C65.2627 433.633 69.0661 450.919 72.0916 454.031L72.5238 452.043C72.5238 452.043 74.5119 438.646 74.2526 436.226C73.9933 433.806 73.4746 425.249 63.8797 423.607C63.8797 423.607 60.5085 419.804 52.2966 418.767C44.517 417.816 35.8729 418.853 30.2543 424.558C26.7102 428.188 26.5373 432.769 30.2543 436.485C30.2543 436.485 30.1678 446.425 32.4153 452.302Z",
                                  fill: "#2194DD"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M36.0457 450.142C35.7863 450.487 36.2185 451.006 36.5643 450.747C37.688 450.142 39.6762 449.45 42.9609 449.364C45.0355 449.277 45.8134 450.142 46.7643 449.709C49.0982 448.586 47.9745 447.203 47.9745 447.203C47.9745 447.203 46.2457 446.684 42.9609 447.203C40.3677 447.635 37.5151 448.24 36.0457 450.142Z",
                                  fill: "#2194DD"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M66.3001 449.969C66.6459 450.314 66.2137 450.919 65.7815 450.66C64.5713 450.055 62.6696 449.45 59.6442 449.364C57.5696 449.277 56.7917 450.142 55.8408 449.709C54.0256 448.845 54.2849 447.721 54.5442 447.376C54.6306 447.289 54.7171 447.203 54.8035 447.116C55.3222 447.03 56.9645 446.684 59.5578 447.203C62.0645 447.635 64.7442 448.24 66.3001 449.969Z",
                                  fill: "#2194DD"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M48.4067 475.812C48.4067 475.812 50.3949 476.244 53.2474 475.812C56.1 475.38 52.9881 476.503 51.8644 476.503C50.6542 476.503 49.0118 476.417 48.4067 475.812Z",
                                  fill: "#2194DD"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M55.322 465.526C54.9762 465.181 54.4575 466.045 53.0745 466.045C51.6914 466.045 51.7779 466.477 50.7406 466.391C49.7033 466.304 49.0118 465.613 48.3203 465.786C46.4186 466.391 45.8999 465.181 45.5542 465.44C45.2948 465.613 45.9864 466.65 46.4186 466.823C47.7152 467.428 47.7152 466.996 48.7525 467.082C50.4813 467.255 49.2711 467.774 50.4813 467.774C52.9016 467.774 52.0372 466.909 52.9881 467.082C54.8898 467.428 55.5813 465.786 55.322 465.526Z",
                                  fill: "#2194DD"
                                }
                              }),
                              _vm.opened
                                ? _c(
                                    "g",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.scale,
                                          expression: "scale"
                                        }
                                      ],
                                      ref: "textContent"
                                    },
                                    [
                                      _c(
                                        "foreignObject",
                                        {
                                          attrs: {
                                            x: 85,
                                            y: 60,
                                            width: 290,
                                            height: 300,
                                            transform: "scale(" + 1 + ")"
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                height: "100%",
                                                width: "100%"
                                              },
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/1999/xhtml"
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "text-align": "right",
                                                    position: "fixed",
                                                    width: "100%",
                                                    height: "100%"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        icon: "",
                                                        small: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.opened = false
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("mdi-close")
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _c("p", {
                                                    staticClass:
                                                      "mm-talk mm-talk-voice",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.part.content
                                                      )
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "defs",
                                [
                                  _c(
                                    "linearGradient",
                                    {
                                      attrs: {
                                        id:
                                          "mm-active-paint0_linear" +
                                          _vm.suffix,
                                        x1: "473.97",
                                        y1: "223.992",
                                        x2: "43.0031",
                                        y2: "223.992",
                                        gradientUnits: "userSpaceOnUse"
                                      }
                                    },
                                    [
                                      _c("stop", {
                                        attrs: { "stop-color": "#F4F5F4" }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.466",
                                          "stop-color": "#F1F4F4"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.7139",
                                          "stop-color": "#E9F0F5"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.9099",
                                          "stop-color": "#DBEAF6"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "1",
                                          "stop-color": "#D1E7F7"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "linearGradient",
                                    {
                                      attrs: {
                                        id:
                                          "mm-active-paint1_linear" +
                                          _vm.suffix,
                                        x1: "50.9833",
                                        y1: "406.709",
                                        x2: "51.0153",
                                        y2: "508.698",
                                        gradientUnits: "userSpaceOnUse"
                                      }
                                    },
                                    [
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.0168194",
                                          "stop-color": "#FF777E"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.908",
                                          "stop-color": "#FFE5E4"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "linearGradient",
                                    {
                                      attrs: {
                                        id:
                                          "mm-active-paint2_linear" +
                                          _vm.suffix,
                                        x1: "50.4269",
                                        y1: "512.253",
                                        x2: "51.7921",
                                        y2: "470.115",
                                        gradientUnits: "userSpaceOnUse"
                                      }
                                    },
                                    [
                                      _c("stop", {
                                        attrs: { "stop-color": "#DCFCF8" }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.1003",
                                          "stop-color": "#D0F5F2"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.2811",
                                          "stop-color": "#B0E3E2"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.5219",
                                          "stop-color": "#7DC6C7"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.81",
                                          "stop-color": "#379EA3"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "1",
                                          "stop-color": "#058189"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "radialGradient",
                                    {
                                      attrs: {
                                        id:
                                          "mm-active-paint3_radial" +
                                          _vm.suffix,
                                        cx: "0",
                                        cy: "0",
                                        r: "1",
                                        gradientUnits: "userSpaceOnUse",
                                        gradientTransform:
                                          "translate(51.7262 475.31) scale(36.6443 36.6404)"
                                      }
                                    },
                                    [
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.00155133",
                                          "stop-color": "#4C75AA"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.1661",
                                          "stop-color": "#427CB6"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.4616",
                                          "stop-color": "#288FD5"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.5261",
                                          "stop-color": "#2194DD"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.7279",
                                          "stop-color": "#2194DD"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.8036",
                                          "stop-color": "#2492D9"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.8804",
                                          "stop-color": "#2F8ACD"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.9576",
                                          "stop-color": "#407EB9"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "1",
                                          "stop-color": "#4C75AA"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "linearGradient",
                                    {
                                      attrs: {
                                        id:
                                          "mm-active-paint4_linear" +
                                          _vm.suffix,
                                        x1: "52.2657",
                                        y1: "520.163",
                                        x2: "52.7175",
                                        y2: "394.746",
                                        gradientUnits: "userSpaceOnUse"
                                      }
                                    },
                                    [
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.1835",
                                          "stop-color": "#2FCEFB"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.8607",
                                          "stop-color": "#BFD8E6"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "radialGradient",
                                    {
                                      attrs: {
                                        id:
                                          "mm-active-paint5_radial" +
                                          _vm.suffix,
                                        cx: "0",
                                        cy: "0",
                                        r: "1",
                                        gradientUnits: "userSpaceOnUse",
                                        gradientTransform:
                                          "translate(61.5189 461) scale(48.0461 48.041)"
                                      }
                                    },
                                    [
                                      _c("stop", {
                                        attrs: {
                                          "stop-color": "#FFE5E4",
                                          "stop-opacity": "0.5"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.9975",
                                          "stop-color": "#4C75AA",
                                          "stop-opacity": "0"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "linearGradient",
                                    {
                                      attrs: {
                                        id:
                                          "mm-active-paint6_linear" +
                                          _vm.suffix,
                                        x1: "51.4674",
                                        y1: "511.273",
                                        x2: "52.2496",
                                        y2: "487.133",
                                        gradientUnits: "userSpaceOnUse"
                                      }
                                    },
                                    [
                                      _c("stop", {
                                        attrs: { "stop-color": "#DCFCF8" }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.32",
                                          "stop-color": "#E8F4F1"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.7567",
                                          "stop-color": "#FFE5E4"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "linearGradient",
                                    {
                                      attrs: {
                                        id:
                                          "mm-active-paint7_linear" +
                                          _vm.suffix,
                                        x1: "65.2822",
                                        y1: "511.082",
                                        x2: "66.3606",
                                        y2: "477.795",
                                        gradientUnits: "userSpaceOnUse"
                                      }
                                    },
                                    [
                                      _c("stop", {
                                        attrs: {
                                          "stop-color": "white",
                                          "stop-opacity": "0.9"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.4398",
                                          "stop-color": "#FFF4F3",
                                          "stop-opacity": "0.9519"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.8475",
                                          "stop-color": "#FFE5E4"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "linearGradient",
                                    {
                                      attrs: {
                                        id:
                                          "mm-active-paint8_linear" +
                                          _vm.suffix,
                                        x1: "38.3003",
                                        y1: "510.812",
                                        x2: "39.4032",
                                        y2: "476.768",
                                        gradientUnits: "userSpaceOnUse"
                                      }
                                    },
                                    [
                                      _c("stop", {
                                        attrs: {
                                          "stop-color": "white",
                                          "stop-opacity": "0.9"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.4398",
                                          "stop-color": "#FFF4F3",
                                          "stop-opacity": "0.9519"
                                        }
                                      }),
                                      _c("stop", {
                                        attrs: {
                                          offset: "0.8475",
                                          "stop-color": "#FFE5E4"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isInternetExplorer
            ? _c(
                "v-container",
                { staticClass: "pa-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "text-center",
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-center": "",
                        "align-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md7: "", lg4: "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "card-fallback pa-3" },
                            [
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", small: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.opened = false
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-close")])],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "text-center mt-0 pt-0" },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "98",
                                        height: "98",
                                        viewBox: "0 0 98 98",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg",
                                        "xmlns:xlink":
                                          "http://www.w3.org/1999/xlink"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M98 48.9949C98 61.4512 93.8475 66.4337 89.6949 75.5684C86.622 82.3778 81.3898 88.0247 73.9153 91.3464C66.1915 94.7511 63.1186 97.9898 49 97.9898C35.878 97.9898 22.4237 93.0072 15.1983 84.5369C11.7932 80.4679 9.13559 76.3988 5.64746 71.8315C0.91356 65.7694 0 57.2161 0 48.9949C0 31.556 10.4644 14.2833 22.4237 8.30422C30.7288 4.15211 36.5424 0 49 0C63.1186 0 71.5068 4.48428 80.1441 11.2107C87.2034 16.6084 89.6949 20.9266 92.7678 26.9887C96.0898 33.6321 98 41.1059 98 48.9949Z",
                                          fill:
                                            "url(#mm-inactive-paint0_linear" +
                                            _vm.suffix +
                                            ")"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M30.1475 76.897C30.1475 76.897 34.1339 73.6583 49.7475 73.6583C63.3678 73.6583 67.1051 77.9765 67.1051 77.9765C67.1051 77.9765 75.5763 97.4914 49.8306 98.8201C24.0848 100.149 30.1475 76.897 30.1475 76.897Z",
                                          fill:
                                            "url(#mm-inactive-paint1_linear" +
                                            _vm.suffix +
                                            ")"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M34.5491 63.112C34.5491 63.112 34.1338 81.7135 26.4932 85.2012C21.4271 87.4434 36.7084 98.1558 48.9999 98.0728C61.2915 97.9897 67.105 85.2012 67.105 85.2012C67.105 85.2012 64.9457 73.4923 64.9457 65.4372C64.8626 61.2851 34.5491 63.112 34.5491 63.112Z",
                                          fill:
                                            "url(#mm-inactive-paint2_radial" +
                                            _vm.suffix +
                                            ")"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M47.7542 15.1137C56.6406 14.7815 63.6169 17.688 67.2711 22.3384C70.9254 26.9887 69.5135 41.6872 69.5135 41.6872C69.5135 41.6872 69.7626 38.1164 71.5898 38.1164C73.666 38.1164 73.7491 41.5211 73.666 44.4276C73.4999 51.8183 71.922 55.7213 69.6796 54.9739C68.3508 54.5587 68.4338 55.2231 68.4338 55.2231C68.4338 55.2231 69.5965 62.7799 65.7762 68.842C63.4508 72.6619 56.8067 76.8971 49.083 76.8971C41.3593 76.8971 34.2999 70.9181 32.805 65.8525C31.3101 60.8699 31.5593 54.3927 31.5593 54.3927C31.5593 54.3927 30.0643 54.7248 29.7321 53.9774C29.3999 53.2301 27.0745 48.3306 27.1576 47.168C27.2406 46.0054 26.7423 39.1129 28.8186 38.6977C30.9779 38.2825 31.144 39.9433 31.144 39.9433C31.144 39.9433 28.9016 28.8987 31.2271 24.3314C33.5525 19.7641 38.1203 15.3628 47.7542 15.1137Z",
                                          fill:
                                            "url(#mm-inactive-paint3_linear" +
                                            _vm.suffix +
                                            ")"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M47.7542 15.1137C56.6406 14.7815 63.6169 17.688 67.2711 22.3384C70.9254 26.9887 69.5135 41.6872 69.5135 41.6872C69.5135 41.6872 69.7626 38.1164 71.5898 38.1164C73.666 38.1164 73.7491 41.5211 73.666 44.4276C73.4999 51.8183 71.922 55.7213 69.6796 54.9739C68.3508 54.5587 68.4338 55.2231 68.4338 55.2231C68.4338 55.2231 69.5965 62.7799 65.7762 68.842C63.4508 72.6619 56.8067 76.8971 49.083 76.8971C41.3593 76.8971 34.2999 70.9181 32.805 65.8525C31.3101 60.8699 31.5593 54.3927 31.5593 54.3927C31.5593 54.3927 30.0643 54.7248 29.7321 53.9774C29.3999 53.2301 27.0745 48.3306 27.1576 47.168C27.2406 46.0054 26.7423 39.1129 28.8186 38.6977C30.9779 38.2825 31.144 39.9433 31.144 39.9433C31.144 39.9433 28.9016 28.8987 31.2271 24.3314C33.5525 19.7641 38.1203 15.3628 47.7542 15.1137Z",
                                          fill:
                                            "url(#mm-inactive-paint4_radial" +
                                            _vm.suffix +
                                            ")"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M52.8203 44.8428C52.8203 44.8428 56.2254 41.6041 61.0424 44.2615",
                                          stroke: "#2194DD",
                                          "stroke-miterlimit": "10",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M36.7085 44.2614C36.7085 44.2614 36.7915 43.1819 39.8644 43.2649C42.9373 43.2649 44.1 44.2614 44.1 44.2614",
                                          stroke: "#2194DD",
                                          "stroke-miterlimit": "10",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M49.0001 97.9898C63.1188 97.9898 66.1917 94.7512 73.9154 91.3464C77.3205 89.8517 80.2272 87.8587 82.7188 85.4504C82.2205 85.2013 81.6391 84.9522 81.0578 84.7031C76.573 83.0422 73.6662 83.8726 71.2578 82.627C68.8493 81.3814 67.0222 77.9766 67.0222 77.9766C68.9323 84.537 64.5306 89.1873 54.3984 91.0973C53.9001 91.1803 53.8171 97.9898 51.1595 97.4085C49.2493 96.9933 48.1696 91.0973 47.5883 91.0973C31.0612 90.6821 30.0645 76.8971 30.0645 76.8971C28.4035 77.9766 27.8222 80.9662 26.4103 81.7966C23.5035 83.4574 20.0984 84.9522 16.9425 86.3639C24.4171 93.7547 36.8747 97.9898 49.0001 97.9898Z",
                                          fill:
                                            "url(#mm-inactive-paint5_linear" +
                                            _vm.suffix +
                                            ")"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M56.8898 97.5746C60.461 98.9033 74.2475 90.9312 71.1746 81.5474C69.6797 79.3053 67.022 77.9766 67.022 77.9766C68.9322 84.537 64.5305 89.1873 54.3983 91.0973C54.4814 91.0973 55.7271 97.1594 56.8898 97.5746Z",
                                          fill:
                                            "url(#mm-inactive-paint6_linear" +
                                            _vm.suffix +
                                            ")"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M30.1475 76.8971C27.5729 78.641 26.9085 80.3849 26.7424 81.2983C29.4001 92.2599 44.2662 100.979 45.595 96.7442C46.9238 92.3429 47.7543 91.0973 47.7543 91.0973C31.1441 90.6821 30.1475 76.8971 30.1475 76.8971Z",
                                          fill:
                                            "url(#mm-inactive-paint7_linear" +
                                            _vm.suffix +
                                            ")"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M40.1135 59.9565C40.7779 59.3752 40.944 60.4547 42.1898 60.7869C44.0999 61.2021 47.0067 61.7003 48.9999 61.6173C50.8271 61.5343 53.7338 61.7003 55.4779 60.953C56.4745 60.5378 57.5542 59.3752 58.4677 60.1225C58.966 60.5378 54.066 62.6138 48.9169 62.5308C43.7677 62.3647 39.4491 60.5377 40.1135 59.9565Z",
                                          fill: "#2194DD"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M38.7849 43.265C38.7019 43.5141 38.7019 44.4276 39.0341 44.5106C39.3663 44.5937 39.5324 44.5106 40.7781 44.5106C42.0239 44.5106 41.8578 43.4311 41.7747 43.4311C41.2764 43.3481 40.612 43.265 39.8646 43.265C39.4493 43.265 39.1171 43.265 38.7849 43.265Z",
                                          fill: "#2194DD"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M58.7999 43.431C57.5542 43.0988 56.3915 43.2649 55.4779 43.431C55.561 44.0123 55.7271 44.8427 56.0593 44.8427C56.5576 44.7597 57.9694 44.5936 58.3847 44.5105C58.6338 44.5105 58.7999 43.9292 58.7999 43.431Z",
                                          fill: "#2194DD"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M31.1442 41.6041C31.1442 41.6041 31.8916 37.452 31.9747 35.8742C32.3899 27.57 34.9645 25.8261 36.7086 23.667C36.7086 23.667 43.7679 25.494 48.4187 25.494C57.2221 25.494 62.7035 23.667 62.7035 23.667C62.7035 23.667 66.3577 40.2755 69.2645 43.265L69.6798 41.355C69.6798 41.355 71.5899 28.4835 71.3408 26.1583C71.0916 23.8331 70.5933 15.612 61.3747 14.0342C61.3747 14.0342 58.1357 10.3803 50.2459 9.38379C42.7713 8.47033 34.4662 9.46684 29.0679 14.9476C25.6628 18.4354 25.4967 22.8366 29.0679 26.4074C29.0679 26.4074 28.9848 35.9573 31.1442 41.6041Z",
                                          fill: "#2194DD"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M34.6322 39.5281C34.383 39.8603 34.7983 40.3585 35.1305 40.1094C36.2101 39.5281 38.1203 38.8638 41.2762 38.7807C43.2694 38.6977 44.0169 39.5281 44.9305 39.1129C47.1728 38.0333 46.0932 36.7047 46.0932 36.7047C46.0932 36.7047 44.4322 36.2064 41.2762 36.7047C38.7847 37.1199 36.044 37.7012 34.6322 39.5281Z",
                                          fill: "#2194DD"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M63.7001 39.362C64.0323 39.6942 63.617 40.2755 63.2018 40.0264C62.0391 39.4451 60.212 38.8638 57.3052 38.7807C55.312 38.6977 54.5645 39.5281 53.6509 39.1129C51.9069 38.2825 52.156 37.2029 52.4052 36.8708C52.4882 36.7877 52.5713 36.7047 52.6543 36.6217C53.1526 36.5386 54.7306 36.2064 57.2221 36.7047C59.6306 37.1199 62.2052 37.7012 63.7001 39.362Z",
                                          fill: "#2194DD"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M46.5084 64.1916C46.5084 64.1916 48.4186 64.6068 51.1593 64.1916C53.8999 63.7764 50.9101 64.8559 49.8305 64.8559C48.6677 64.8559 47.0898 64.7729 46.5084 64.1916Z",
                                          fill: "#2194DD"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M53.1525 54.3096C52.8203 53.9775 52.322 54.8079 50.9932 54.8079C49.6644 54.8079 49.7474 55.2231 48.7508 55.14C47.7542 55.057 47.0898 54.3927 46.4254 54.5587C44.5983 55.14 44.1 53.9775 43.7678 54.2266C43.5186 54.3927 44.183 55.3892 44.5983 55.5553C45.844 56.1366 45.844 55.7213 46.8406 55.8044C48.5017 55.9705 47.3389 56.4687 48.5017 56.4687C50.8271 56.4687 49.9966 55.6383 50.9101 55.8044C52.7372 56.1365 53.4017 54.5588 53.1525 54.3096Z",
                                          fill: "#2194DD"
                                        }
                                      }),
                                      _c(
                                        "defs",
                                        [
                                          _c(
                                            "linearGradient",
                                            {
                                              attrs: {
                                                id:
                                                  "mm-inactive-paint0_linear" +
                                                  _vm.suffix,
                                                x1: "48.984",
                                                y1: "-2.20145",
                                                x2: "49.0147",
                                                y2: "95.7883",
                                                gradientUnits: "userSpaceOnUse"
                                              }
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.0168194",
                                                  "stop-color": "#FF777E"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.908",
                                                  "stop-color": "#FFE5E4"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "linearGradient",
                                            {
                                              attrs: {
                                                id:
                                                  "mm-inactive-paint1_linear" +
                                                  _vm.suffix,
                                                x1: "48.4493",
                                                y1: "99.2037",
                                                x2: "49.7609",
                                                y2: "58.7179",
                                                gradientUnits: "userSpaceOnUse"
                                              }
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  "stop-color": "#DCFCF8"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.1003",
                                                  "stop-color": "#D0F5F2"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.2811",
                                                  "stop-color": "#B0E3E2"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.5219",
                                                  "stop-color": "#7DC6C7"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.81",
                                                  "stop-color": "#379EA3"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "1",
                                                  "stop-color": "#058189"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "radialGradient",
                                            {
                                              attrs: {
                                                id:
                                                  "mm-inactive-paint2_radial" +
                                                  _vm.suffix,
                                                cx: "0",
                                                cy: "0",
                                                r: "1",
                                                gradientUnits: "userSpaceOnUse",
                                                gradientTransform:
                                                  "translate(49.6975 63.71) scale(35.2073 35.2036)"
                                              }
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.00155133",
                                                  "stop-color": "#4C75AA"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.1661",
                                                  "stop-color": "#427CB6"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.4616",
                                                  "stop-color": "#288FD5"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.5261",
                                                  "stop-color": "#2194DD"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.7279",
                                                  "stop-color": "#2194DD"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.8036",
                                                  "stop-color": "#2492D9"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.8804",
                                                  "stop-color": "#2F8ACD"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.9576",
                                                  "stop-color": "#407EB9"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "1",
                                                  "stop-color": "#4C75AA"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "linearGradient",
                                            {
                                              attrs: {
                                                id:
                                                  "mm-inactive-paint3_linear" +
                                                  _vm.suffix,
                                                x1: "50.216",
                                                y1: "106.803",
                                                x2: "50.65",
                                                y2: "-13.6953",
                                                gradientUnits: "userSpaceOnUse"
                                              }
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.1835",
                                                  "stop-color": "#2FCEFB"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.8607",
                                                  "stop-color": "#BFD8E6"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "radialGradient",
                                            {
                                              attrs: {
                                                id:
                                                  "mm-inactive-paint4_radial" +
                                                  _vm.suffix,
                                                cx: "0",
                                                cy: "0",
                                                r: "1",
                                                gradientUnits: "userSpaceOnUse",
                                                gradientTransform:
                                                  "translate(59.1062 49.9611) scale(46.1619 46.1571)"
                                              }
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  "stop-color": "#FFE5E4",
                                                  "stop-opacity": "0.5"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.9975",
                                                  "stop-color": "#4C75AA",
                                                  "stop-opacity": "0"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "linearGradient",
                                            {
                                              attrs: {
                                                id:
                                                  "mm-inactive-paint5_linear" +
                                                  _vm.suffix,
                                                x1: "49.4494",
                                                y1: "98.2625",
                                                x2: "50.2008",
                                                y2: "75.0693",
                                                gradientUnits: "userSpaceOnUse"
                                              }
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  "stop-color": "#DCFCF8"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.32",
                                                  "stop-color": "#E8F4F1"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.7567",
                                                  "stop-color": "#FFE5E4"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "linearGradient",
                                            {
                                              attrs: {
                                                id:
                                                  "mm-inactive-paint6_linear" +
                                                  _vm.suffix,
                                                x1: "62.7223",
                                                y1: "98.0791",
                                                x2: "63.7584",
                                                y2: "66.0971",
                                                gradientUnits: "userSpaceOnUse"
                                              }
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  "stop-color": "white",
                                                  "stop-opacity": "0.9"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.4398",
                                                  "stop-color": "#FFF4F3",
                                                  "stop-opacity": "0.9519"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.8475",
                                                  "stop-color": "#FFE5E4"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "linearGradient",
                                            {
                                              attrs: {
                                                id:
                                                  "mm-inactive-paint7_linear" +
                                                  _vm.suffix,
                                                x1: "36.7983",
                                                y1: "97.8197",
                                                x2: "37.858",
                                                y2: "65.1103",
                                                gradientUnits: "userSpaceOnUse"
                                              }
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  "stop-color": "white",
                                                  "stop-opacity": "0.9"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.4398",
                                                  "stop-color": "#FFF4F3",
                                                  "stop-opacity": "0.9519"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.8475",
                                                  "stop-color": "#FFE5E4"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c("v-card-text", { staticClass: "pt-4 pb-4" }, [
                                _c("p", {
                                  staticClass: "mm-talk-voice",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.part.content)
                                  }
                                })
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }