var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      staticClass: "element-instructional",
      class: {
        "justify-start":
          !_vm.part.horizontal ||
          _vm.part.horizontal === _vm.horizontalStyles.LEFT,
        "justify-center":
          _vm.part.horizontal &&
          _vm.part.horizontal === _vm.horizontalStyles.CENTER,
        "justify-end":
          _vm.part.horizontal &&
          _vm.part.horizontal === _vm.horizontalStyles.RIGHT
      }
    },
    [
      _c("v-flex", { attrs: { shrink: "" } }, [
        _c(
          "svg",
          {
            staticClass: "d-block",
            attrs: {
              width: "40",
              height: "39",
              viewBox: "0 0 40 39",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M39.9079 0.196087C39.9079 0.196087 38.2185 4.4952 35.5348 5.79672C32.8511 7.09823 30.9629 6.84765 29.2735 7.82192C27.5841 8.79618 25.0993 10.1968 23.5084 14.9952C23.5084 14.9952 17.8437 3.49475 11.1847 7.59565C3.72648 12.1884 9.39492 19.3953 11.8797 21.2952C11.8797 21.2952 17.0278 25.5943 18.9253 27.195C20.8247 28.7958 23.4081 32.4946 19.3324 38.0952C19.3324 38.0952 15.5559 32.1954 7.46951 26.1946C1.97019 22.1143 -3.62577 12.3941 3.03326 4.89351C9.69228 -2.60703 18.7358 -0.50703 23.2092 4.69342C23.2092 4.69342 27.086 -0.183522 34.1409 0.00534747C41.1958 0.194217 39.9079 0.196087 39.9079 0.196087Z",
                fill: "url(#instructional-linear-" + _vm.uuid + ")"
              }
            }),
            _c(
              "defs",
              [
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "instructional-linear-" + _vm.uuid,
                      x1: "0.00203726",
                      y1: "19.0489",
                      x2: "40.0004",
                      y2: "19.0489",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { "stop-color": "var(--button-gradient-2)" }
                    }),
                    _c("stop", {
                      attrs: {
                        offset: "1",
                        "stop-color": "var(--button-gradient-1)"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      ]),
      _c("v-flex", { attrs: { shrink: "" } }, [
        _vm.part
          ? _c(
              "p",
              { staticClass: "starling-instructional", style: _vm.style },
              [_c("sm-markup-text", { attrs: { value: _vm.part.content } })],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }