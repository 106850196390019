

















import Vue from 'vue';
import { IStyleContentVideo, ITherapyVideo } from '@/scripts/store/modules/therapy/types';
import vimeoVideo from '@/views/components/utilities/vimeo-video.vue';

export default Vue.extend({
  name: 'element-part-video',
  components: { vimeoVideo },
  props: {
    part: {
      type: Object as () => IStyleContentVideo,
      required: true,
    },
    uen: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      video: undefined as ITherapyVideo | undefined,
    };
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch('therapy/getTherapyVideo', this.part.id ? this.part.id : this.part.content)
      .then((res: ITherapyVideo) => {
        this.video = res;
        this.loading = false;
      });
  },
  methods: {
    onVideoEvent(event: string) {
      this.$store.dispatch('therapy/watchVideoEvent', { uen: this.uen, videoId: this.video?.vimeoId, status: event });
    },
  },
});
