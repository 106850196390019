import { render, staticRenderFns } from "./horizontal-steps.vue?vue&type=template&id=0274674c&"
import script from "./horizontal-steps.vue?vue&type=script&lang=ts&"
export * from "./horizontal-steps.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src233137091/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0274674c')) {
      api.createRecord('0274674c', component.options)
    } else {
      api.reload('0274674c', component.options)
    }
    module.hot.accept("./horizontal-steps.vue?vue&type=template&id=0274674c&", function () {
      api.rerender('0274674c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/steppers/horizontal-steps.vue"
export default component.exports