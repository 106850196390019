<template>
    <v-btn large class="pl-0 pr-3 ml-0 my-1" @click="AddNote">
        <v-icon color="#2194DD">add</v-icon>{{ $t('app.assessments.'+ noteType + '.action_label') }}
    </v-btn>
  </template>
<script>
export default {
  name: 'ButtonNote',
  props: {
    noteType: {
      type: String,
      required: true,
    },
  },
  methods: {
    AddNote() {
      this.$emit('addNote', this.noteType);
    },
  },
};
</script>
