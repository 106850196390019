import { render, staticRenderFns } from "./input-note-wrapper-assessment.vue?vue&type=template&id=5a197b70&scoped=true&"
import script from "./input-note-wrapper-assessment.vue?vue&type=script&lang=ts&"
export * from "./input-note-wrapper-assessment.vue?vue&type=script&lang=ts&"
import style0 from "./input-note-wrapper-assessment.vue?vue&type=style&index=0&id=5a197b70&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a197b70",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VFlex,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/starling-app-dev-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5a197b70')) {
      api.createRecord('5a197b70', component.options)
    } else {
      api.reload('5a197b70', component.options)
    }
    module.hot.accept("./input-note-wrapper-assessment.vue?vue&type=template&id=5a197b70&scoped=true&", function () {
      api.rerender('5a197b70', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/tools/notes/input-note-wrapper-assessment.vue"
export default component.exports