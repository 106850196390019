






import Vue from 'vue';
import { IStyleContent } from '@/scripts/store/modules/therapy/types';

export default Vue.extend({
  name: 'element-part-citation',
  props: {
    part: {
      type: Object as () => IStyleContent,
      required: true,
    },
  },
  computed: {
    style(): Partial<CSSStyleDeclaration> {
      const style: Partial<CSSStyleDeclaration> = {};
      if (this.part.color) {
        style.color = this.part.color;
      }
      return style;
    },
  },
});
