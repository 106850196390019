














import Vue from 'vue';
import { IStyleContent, StyleHorizontalEnum, StyleSizeEnum } from '@/scripts/store/modules/therapy/types';

export default Vue.extend({
  name: 'element-part-explanatory',
  props: {
    part: {
      type: Object as () => IStyleContent,
      required: true,
    },
  },
  computed: {
    justifyLeft(): boolean {
      return !this.part.horizontal || this.part.horizontal === StyleHorizontalEnum.LEFT;
    },
    justifyCenter(): boolean {
      return !!this.part.horizontal && this.part.horizontal === StyleHorizontalEnum.CENTER;
    },
    justifyRight(): boolean {
      return !!this.part.horizontal && this.part.horizontal === StyleHorizontalEnum.RIGHT;
    },
    halfSize(): boolean {
      return this.part.size !== StyleSizeEnum.XL;
    },
    style(): Partial<CSSStyleDeclaration> {
      const style: Partial<CSSStyleDeclaration> = {};
      if (this.part.color) {
        style.color = this.part.color;
      }
      return style;
    },
  },
});
