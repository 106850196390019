var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "element-part-video", attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _vm.loading
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-progress-circular", {
                    attrs: { size: 50, color: "primary", indeterminate: "" }
                  })
                ],
                1
              )
            : _vm.video
            ? _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("vimeo-video", {
                    attrs: {
                      identifier: _vm.video.identifier,
                      videoId: _vm.video.vimeoId,
                      transcript: _vm.video.transcript
                    },
                    on: { videoEvent: _vm.onVideoEvent }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }