





import Vue from 'vue';
import store from '@/scripts/store';
import { IStyleContentStepNumber } from '@/scripts/store/modules/therapy/types';

export default Vue.extend({
  name: 'element-part-step-number',
  props: {
    part: {
      type: Object as () => IStyleContentStepNumber,
      required: true,
    },
  },
  mounted() {
    const progress = Math.round(parseInt('' + this.part.step) / parseInt('' + this.part.of) * 100);
    store.commit('navigation/setExtensionProgress', progress);
  },
  destroyed() {
    store.commit('navigation/removeExtensionProgress');
  },
});
