













import Vue from 'vue';
import InputNoteWrapperAssessment from '@/views/tools/notes/input-note-wrapper-assessment.vue';
import { INote } from '@/scripts/store/modules/tools/notes/types';

export default Vue.extend({
  name: 'assessment-note-display',
  components: { InputNoteWrapperAssessment },
  props: {
    userAssessment: Object,
    scaleDefinition: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    color() {
      return this.$t(`app.assessments.${this.userAssessment.assessmentName}.color`);
    },
    safeDate() {
      return this.userAssessment && this.userAssessment.completed ? this.userAssessment.completed : new Date();
    },
    safeNote(): INote {
      return this.userAssessment.notes ? this.userAssessment.notes : { id: undefined, note: '', date: this.safeDate, type: this.userAssessment.assessmentName, uen: '' } as INote;
    },
  },
  methods: {
    onUpdated(note: INote) {
      this.loading = true;
      this.$store.dispatch('assessment/addAssessmentNotes', {
        userAssessmentId: this.userAssessment.assessmentId,
        note,
      }).then(res => {
        this.$emit('updated', res);
      }).finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
  },
});
