













import Vue from 'vue';
import { IStyleContent, ITherapyImage, StyleDeviceEnum, StyleHorizontalEnum, StyleSizeEnum, StyleVerticalEnum } from '@/scripts/store/modules/therapy/types';

export default Vue.extend({
  name: 'element-part-background',
  props: {
    part: {
      type: Object as () => IStyleContent,
      required: true,
    },
  },
  data() {
    return {
      imageLoaded: false,
      image: {
        src: '' as string | null,
        alt: '',
      },
      isRendered: false,
    };
  },
  computed: {
    isBackground(): boolean {
      return this.part && ((!this.part.device || !this.part.device.length) ||
        (this.part.device === StyleDeviceEnum.MOBILE && this.$vuetify.breakpoint.xsOnly) ||
        (this.part.device === StyleDeviceEnum.DESKTOP && this.$vuetify.breakpoint.smAndUp));
    },
    vertical(): string {
      if (!this.part.vertical || this.part.vertical === StyleVerticalEnum.MIDDLE) return 'center';
      return this.part.vertical;
    },
    horizontal(): string {
      if (!this.part.horizontal) return StyleHorizontalEnum.CENTER;
      return this.part.horizontal;
    },
    isColorBg(): boolean {
      return this.part && !!this.part.content && this.part.content.startsWith('#');
    },
    sizeStyles() {
      return StyleSizeEnum;
    },
  },
  mounted() {
    this.$log.debug('Displaying background', this.part);
    if (this.isColorBg) {
      this.colorHandler(this.part.content);
      return;
    }
    this.$store.dispatch('therapy/getTherapyImage', this.part.id ? this.part.id : this.part.content)
      .then((res: ITherapyImage) => {
        if (!res || !res.image) return;
        const blob = new Blob([ res.image ], { type: 'image/svg+xml' });
        this.image.src = URL.createObjectURL(blob);
        this.image.alt = res.altText;
        this.imageLoaded = true;
      });
  },
  destroyed() {
    this.destroyImage();
  },
  methods: {
    destroyImage() {
      if (this.image.src) {
        URL.revokeObjectURL(this.image.src);
        this.image.src = null;
      }
    },
    colorHandler(color: string) {
      const bgStyle: Partial<CSSStyleDeclaration> = {};
      bgStyle.backgroundColor = color;
      this.$emit('updateBackground', bgStyle);
    },
    loadedHandler(event: { target: HTMLImageElement }): void {
      // Sets the parent background using computed size and position
      const bgStyle: Partial<CSSStyleDeclaration> = {};
      bgStyle.background = 'url(' + this.image.src + ')';
      bgStyle.backgroundRepeat = 'no-repeat';
      bgStyle.backgroundPosition = this.horizontal + ' ' + this.vertical;
      bgStyle.backgroundSize = event.target.width + 'px auto';
      bgStyle.minHeight = event.target.height + 'px';

      this.$emit('updateBackground', bgStyle);

      // Removes the image component, since it's now a background property
      this.isRendered = true;
    },
  },
});
