var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-center" }, [
    this.showBatteryScale
      ? _c(
          "svg",
          {
            ref: "batterySVG",
            staticClass: "widget-battery",
            class: "level-" + _vm.roundedLevel,
            style: "color:" + this.color,
            attrs: {
              viewBox: "0 0 156 156",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            },
            on: {
              mousedown: _vm.startDrag,
              mousemove: _vm.drag,
              mouseup: _vm.endDrag,
              mouseleave: _vm.endDrag,
              click: function($event) {
                $event.stopPropagation()
              },
              touchstart: _vm.startDrag,
              touchmove: _vm.drag,
              touchend: _vm.endDrag,
              touchleave: _vm.endDrag,
              touchcancel: _vm.endDrag
            }
          },
          [
            _c("g", { attrs: { transform: "translate(40.5, 10.5)" } }, [
              _c("path", {
                attrs: {
                  d:
                    "M23.6647 9.2508H8.88569C4.48454 9.2508 0.9 12.7525 0.9 17.0613V126.29C0.9 130.599 4.48454 134.1 8.88569 134.1H65.9591C70.3625 134.1 73.9471 130.599 73.9471 126.29V17.0613C73.9471 12.7525 70.3625 9.2508 65.9614 9.2508H51.1824V0.447266V0.347266H51.0823H23.7647H23.6647V0.447266V9.2508ZM28.4176 4.99903H46.4294V9.2508H28.4176V4.99903ZM69.1941 126.29C69.1941 128.031 67.7454 129.448 65.9614 129.448H8.88569C7.10164 129.448 5.65294 128.031 5.65294 126.29V17.0613C5.65294 15.3201 7.10164 13.9026 8.88569 13.9026H23.7647H51.0823H65.9614C67.7454 13.9026 69.1941 15.3201 69.1941 17.0613V126.29Z",
                  fill: "#C4C4C4",
                  stroke: "#C4C4C4",
                  "stroke-width": "0.2"
                }
              }),
              _c(
                "g",
                { staticClass: "state" },
                [
                  _vm._l(_vm.levelsY, function(posY, index) {
                    return [
                      _c("rect", {
                        key: index,
                        attrs: {
                          x: "10",
                          y: posY,
                          width: "54.5",
                          height: "7.56"
                        }
                      })
                    ]
                  })
                ],
                2
              )
            ])
          ]
        )
      : _vm._e(),
    this.fullyChargedLongTime
      ? _c(
          "svg",
          {
            staticClass: "widget-battery",
            class: "level-" + _vm.roundedLevel,
            attrs: {
              viewBox: "0 0 156 156",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("g", { attrs: { transform: "translate(40.5, 10.5)" } }, [
              _c("path", {
                attrs: {
                  d:
                    "M23.6647 9.2508H8.88569C4.48454 9.2508 0.9 12.7525 0.9 17.0613V126.29C0.9 130.599 4.48454 134.1 8.88569 134.1H65.9591C70.3625 134.1 73.9471 130.599 73.9471 126.29V17.0613C73.9471 12.7525 70.3625 9.2508 65.9614 9.2508H51.1824V0.447266V0.347266H51.0823H23.7647H23.6647V0.447266V9.2508ZM28.4176 4.99903H46.4294V9.2508H28.4176V4.99903ZM69.1941 126.29C69.1941 128.031 67.7454 129.448 65.9614 129.448H8.88569C7.10164 129.448 5.65294 128.031 5.65294 126.29V17.0613C5.65294 15.3201 7.10164 13.9026 8.88569 13.9026H23.7647H51.0823H65.9614C67.7454 13.9026 69.1941 15.3201 69.1941 17.0613V126.29Z",
                  fill: "#C4C4C4",
                  stroke: "#C4C4C4",
                  "stroke-width": "0.2"
                }
              }),
              _c("rect", {
                attrs: {
                  x: "5",
                  y: "14",
                  width: "65",
                  height: "116",
                  rx: "5",
                  fill: "#08EACF"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M67 63.9987C67 80.5688 53.5688 93.9974 36.9987 93.9974C20.4312 94 7 80.5688 7 63.9987C7 47.4312 20.4312 34 36.9987 34C53.5688 34 67 47.4312 67 63.9987Z",
                  fill: "#29E9CF"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M21.2968 59.8525C20.2097 59.8525 19.3289 60.7334 19.3289 61.8205V64.6616C19.3289 65.7487 20.2097 66.6295 21.2968 66.6295C22.3838 66.6295 23.2647 65.7487 23.2647 64.6616V61.8205C23.2622 60.7359 22.3813 59.8525 21.2968 59.8525Z",
                  fill: "#494A59"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M52.7061 59.8525C51.619 59.8525 50.7382 60.7334 50.7382 61.8205V64.6616C50.7382 65.7487 51.619 66.6295 52.7061 66.6295C53.7931 66.6295 54.674 65.7487 54.674 64.6616V61.8205C54.6715 60.7359 53.7906 59.8525 52.7061 59.8525Z",
                  fill: "#494A59"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M43.1742 63.5185C42.357 62.8031 41.1147 62.8871 40.3993 63.7043C39.8188 64.3662 38.9482 64.7456 38.0037 64.7456C37.0617 64.7456 36.1885 64.3662 35.608 63.7043C34.8952 62.8871 33.6503 62.8031 32.8331 63.5185C32.0159 64.2338 31.9319 65.4762 32.6473 66.2934C33.9736 67.8107 35.9263 68.6814 38.0037 68.6814C40.0811 68.6814 42.0363 67.8107 43.3601 66.2934C44.0755 65.4737 43.9914 64.2313 43.1742 63.5185Z",
                  fill: "#494A59"
                }
              }),
              _c("rect", {
                attrs: {
                  x: "5",
                  y: "14",
                  width: "8",
                  height: "116",
                  rx: "4",
                  fill: "#0FD4BD"
                }
              })
            ])
          ]
        )
      : _vm._e(),
    this.emptyBattery
      ? _c(
          "svg",
          {
            staticClass: "widget-battery",
            class: "level-" + _vm.roundedLevel,
            attrs: {
              viewBox: "0 0 156 156",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("g", { attrs: { transform: "translate(40.5, 10.5)" } }, [
              _c("path", {
                attrs: {
                  d:
                    "M23.6647 9.2508H8.88569C4.48454 9.2508 0.9 12.7525 0.9 17.0613V126.29C0.9 130.599 4.48454 134.1 8.88569 134.1H65.9591C70.3625 134.1 73.9471 130.599 73.9471 126.29V17.0613C73.9471 12.7525 70.3625 9.2508 65.9614 9.2508H51.1824V0.447266V0.347266H51.0823H23.7647H23.6647V0.447266V9.2508ZM28.4176 4.99903H46.4294V9.2508H28.4176V4.99903ZM69.1941 126.29C69.1941 128.031 67.7454 129.448 65.9614 129.448H8.88569C7.10164 129.448 5.65294 128.031 5.65294 126.29V17.0613C5.65294 15.3201 7.10164 13.9026 8.88569 13.9026H23.7647H51.0823H65.9614C67.7454 13.9026 69.1941 15.3201 69.1941 17.0613V126.29Z",
                  fill: "#EDEDED",
                  stroke: "#C4C4C4",
                  "stroke-width": "0.2"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M45.7343 41.7821C45.7782 40.8726 45.292 40.0148 44.4719 39.617L42.8442 38.9312L41.8133 40.2427L19.0321 78.0999H31.127L31.1312 105.8C31.0853 106.686 31.5506 107.548 32.3415 107.957L34.0005 108.712L35.0564 107.344L55.4441 67.3499H45.7343V41.7821ZM48.5452 71.6499L35.3047 97.6756L35.3005 73.7999H26.5278L41.5608 48.7997V71.6499H48.5452Z",
                  fill: "#EDEDED"
                }
              })
            ])
          ]
        )
      : _vm._e(),
    this.notChargedForLongTime
      ? _c(
          "svg",
          {
            staticClass: "widget-battery",
            class: "level-" + _vm.roundedLevel,
            attrs: {
              viewBox: "0 0 156 156",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("g", { attrs: { transform: "translate(40.5, 10.5)" } }, [
              _c("path", {
                attrs: {
                  d:
                    "M19.9909 8.54235H7.61309C3.91379 8.54235 0.9 11.4808 0.9 15.0976V106.545C0.9 110.162 3.91379 113.1 7.61309 113.1H55.4759C59.1771 113.1 62.1909 110.162 62.1909 106.545V15.0976C62.1909 11.4808 59.1771 8.54235 55.4778 8.54235H43.1V1.18823V1.08823H43H20.0909H19.9909V1.18823V8.54235ZM24.0091 5.01529H39.0818V8.54235H24.0091V5.01529ZM58.1727 106.545C58.1727 107.993 56.9656 109.173 55.4778 109.173H7.61309C6.12535 109.173 4.91818 107.993 4.91818 106.545V15.0976C4.91818 13.6494 6.12535 12.4694 7.61309 12.4694H20.0909H43H55.4778C56.9656 12.4694 58.1727 13.6494 58.1727 15.0976V106.545Z",
                  fill: "#C4C4C4",
                  stroke: "#C4C4C4",
                  "stroke-width": "0.2"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M43.6486 48.094C43.2822 48.5922 42.6721 49.4574 42.1534 50.3667C41.8941 50.8214 41.6571 51.2879 41.4848 51.7258C41.3128 52.1629 41.2037 52.5751 41.2037 52.92C41.2037 54.6878 42.7334 56.1197 44.6082 56.1197C46.483 56.1197 48.0126 54.6878 48.0126 52.92C48.0126 52.5754 47.9036 52.1632 47.7316 51.7262C47.5593 51.2884 47.3222 50.8218 47.0629 50.3671C46.5442 49.4577 45.9342 48.5923 45.5678 48.094C45.3487 47.7943 44.976 47.6476 44.6082 47.6476C44.2404 47.6476 43.8677 47.7943 43.6486 48.094ZM44.6082 53.9193C44.0166 53.9193 43.5401 53.4685 43.5397 52.9207C43.5417 52.8493 43.5697 52.7388 43.6241 52.5932C43.6782 52.4486 43.7565 52.2741 43.855 52.0769C44.0434 51.7 44.3045 51.2429 44.6082 50.7587C44.9118 51.2414 45.1721 51.6981 45.3601 52.0749C45.4585 52.2721 45.5367 52.4468 45.5909 52.5916C45.6454 52.7371 45.6739 52.848 45.6767 52.9199C45.6762 53.4687 45.1997 53.9193 44.6082 53.9193Z",
                  fill: "#4F4F4F",
                  stroke: "#C4C4C4",
                  "stroke-width": "0.1"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M26.7222 56.2062C27.4075 56.8051 28.4494 56.7348 29.0493 56.0506C29.5361 55.4964 30.2662 55.1789 31.0583 55.1789C31.8482 55.1789 32.5805 55.4964 33.0673 56.0506C33.6651 56.7348 34.7091 56.8051 35.3944 56.2062C36.0798 55.6073 36.1502 54.5672 35.5503 53.883C34.438 52.6127 32.8004 51.8837 31.0583 51.8837C29.3162 51.8837 27.6765 52.6127 26.5663 53.883C25.9664 54.5693 26.0368 55.6094 26.7222 56.2062Z",
                  fill: "#494A59"
                }
              })
            ])
          ]
        )
      : _vm._e(),
    this.showBatterySubtext
      ? _c("div", { staticClass: "battery-text", style: _vm.textColor }, [
          _c("h1", {
            staticClass: "battery-percentage",
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("app.home.user_stats_card.widgets.battery.value", {
                  value:
                    _vm.roundedLevel !== undefined && _vm.roundedLevel !== null
                      ? _vm.roundedLevel / 10
                      : "&nbsp;&nbsp;&nbsp;&nbsp;"
                })
              )
            }
          })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }