<template>
    <v-textarea v-model="internalNotes"
                class="assessment-notes-native no-underline pb-0 ba-0"
                row-height="1"
                :placeholder="$t('common.actions.add_situation')"
                :maxlength="2000"
                :color="color"
                auto-grow
                :readonly="recording"
                @input="debounceInput">
      <template v-slot:append v-if="supported">
        <v-btn :color="recording? 'primary' : null"
               fab
               flat
               small
               @click.native="toggleSpeechRecognition">
          <v-icon medium>
            mic
          </v-icon>
        </v-btn>
      </template>
    </v-textarea>
</template>

<script>
import _ from 'lodash';
import store from '@/scripts/store';
import { mapGetters } from 'vuex';
import { SpeechRecognition } from '@capacitor-community/speech-recognition';

export default {
  name: 'input-note-native',
  props: {
    note: String,
    color: String,
    loading: Boolean,
    noteIndex: {
      type: Number,
      defaultValue: null,
    },
  },
  data() {
    return {
      internalNotes: this.note,
      savedNotes: this.note,
      debounceInput: undefined,
      debounceSpeech: undefined,
      error: false,
      supported: false,
      speaking: false,
      recording: false,
      dictation: false,
      forceStop: false,
    };
  },
  computed: {
    currentLanguage() {
      return store.getters.currentLanguage;
    },
    ...mapGetters({
      isNativePlatform: 'isNativePlatform',
      platform: 'getPlatform',
    }),
  },
  watch: {
    note(newNote) {
      this.internalNotes = newNote;
    },
    currentLanguage(val) {
      if (this.recognition) {
        this.recognition.lang = val;
      }
    },
    recording(val) {
      this.$emit('recording', val);
    },
  },
  created() {
    this.debounceInput = _.debounce(this.updatedValue, 1000);
    this.debounceSpeech = _.debounce(this.updatedValue, 1000);
  },
  mounted() {
    SpeechRecognition.available().then(a => {
      if (a.available) {
        this.supported = true;
        this.$log.debug([ 'supported / available' ]);
      } else {
        this.supported = false;
        this.$log.debug([ 'not supported' ]);
      }
    }, err => {
      this.$log.debug([ 'not supported / not available', err, this.platform ]);
    });
  },
  beforeDestroy() {
    SpeechRecognition.stop();
    SpeechRecognition.removeAllListeners();
  },
  methods: {
    updatedValue(value) {
      this.$log.debug([ 'updated value : ', value ]);
      this.savedNotes = value;
      if (this.noteIndex !== null && !this.loading) {
        this.$emit('updated', { note: this.savedNotes, dictation: this.dictation }, this.noteIndex);
      } else if (!this.loading) {
        this.$emit('updated', { note: this.savedNotes, dictation: this.dictation });
      }
    },
    toggleSpeechRecognition() {
      if (this.recording) {
        this.endSpeechRecognition();
      } else {
        this.startSpeechRecognition();
      }
    },
    async startSpeechRecognition() {
      let currentPermission = await SpeechRecognition.checkPermissions();

      if (currentPermission.speechRecognition !== 'granted') {
        currentPermission = await SpeechRecognition.requestPermissions();
      }

      if (currentPermission.speechRecognition !== 'granted') {
        return;
      }
      await SpeechRecognition.removeAllListeners();

      this.currentValue = this.internalNotes;
      this.recording = true;

      await SpeechRecognition.addListener('listeningState', evt => {
        this.$log.debug([ 'recognition listeningState', evt ]);
        switch (evt.status) {
          case 'started':
            this.speaking = true;
            break;
          case 'stopped':
            if (!this.forceStop) {
              this.$log.debug([ 'recognition stopped after no sound!' ]);
              // this.recording = false;
              // this.speaking = false;
              setTimeout(() => {
                try {
                  SpeechRecognition.start({
                    language: this.currentLanguage,
                    maxResults: 1,
                    prompt: '',
                    partialResults: true,
                    popup: false,
                  }).then(() => {
                    this.$log.debug([ 'recognition restarted !' ]);
                  });
                  this.$log.debug([ 'recognition should start again' ]);
                } catch (e) {
                  this.$log.debug([ 'error restarting', e ]);
                }
              }, 500);
            } else {
              this.forceStop = false;
              this.recording = false;
              this.speaking = false;
            }
            break;
        }
      });

      await SpeechRecognition.addListener('partialResults', evt => {
        const final = [ this.currentValue ];
        evt.matches.forEach(m => final.push(m));
        this.$log.debug([ 'recognition partialResults', evt ]);
        this.internalNotes = final.join('') + (final.length ? '\n' : '');
        this.$log.debug([ 'recognition partial results', evt, this.internalNotes ]);
        this.debounceSpeech(this.internalNotes);
      });

      await SpeechRecognition.start({
        language: this.currentLanguage,
        maxResults: 1,
        prompt: '',
        partialResults: true,
        popup: false,
      });
    },
    endSpeechRecognition() {
      this.forceStop = true;
      SpeechRecognition.stop();
    },
  },
};
</script>

<style lang="scss">
.assessment-notes-native {
  .v-input__append-inner {
    color: currentColor;
  }

  &.no-underline .v-input__slot::before,
  &.no-underline .v-input__slot::after {
    margin-bottom: 0px !important;
    display: none !important;
  }

  &.no-underline ::v-deep .v-input__slot {
    margin-bottom: 0px !important;
  }

  .v-messages {
    min-height: 0px !important;
  }
}
</style>
