

































import Vue from 'vue';
import moment from 'moment';
import { INote } from '@/scripts/store/modules/tools/notes/types';
import HorizontalLineNote from '@/views/tools/notes/horizontal-line-note.vue';
import InputNoteNative from '@/views/tools/notes/input-note-native.vue';
import InputNoteWeb from '@/views/tools/notes/input-note-web.vue';
import ButtonNote from '@/views/tools/notes/button-note.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'InputNoteWrapperAssessment',
  components: { InputNoteNative, InputNoteWeb, ButtonNote, HorizontalLineNote },
  props: {
    note: {
      type: Object as () => INote,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isAssessmentNote: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showInputNote: false as boolean,
      generalNotes: [] as INote[],
      loadingNote: false as boolean,
      readonly: false,
    };
  },
  computed: {
    safeNote(): INote {
      return this.note !== null ? this.note : { id: undefined, note: '', date: new Date(), type: this.type, uen: '' } as INote;
    },
    noteTitle(): string {
      return `${this.$t('app.tools.note.note')}: ${this.formatDate(this.safeNote.date)}`;
    },
    showButtonNote(): boolean {
      return this.isAssessmentNote && this.note === null && !this.showInputNote;
    },
    ...mapGetters({
      isNativePlatform: 'isNativePlatform',
    }),
  },
  mounted() {
    this.loadingNote = this.loading;
    this.fetchGeneralNote();
  },
  methods: {
    formatDate(date: Date) {
      const locale = this.$i18n.locale;
      const momentDate = moment(date);
      return momentDate.locale(locale).format('LL');
    },
    onGeneralNoteUpdated(updatedNote: string, noteIndex: number) {
      this.loadingNote = true;
      const newNote: INote = {
        ...this.generalNotes[noteIndex],
        note: updatedNote,
      };
      this.generalNotes.splice(noteIndex, 1, newNote);

      try {
        this.$store.dispatch('notes/saveNote', newNote).then(res => {
          this.$emit('completeElement', { valid: true });
        });
      } catch (error) {
        this.$log.debug(error);
      } finally {
        setTimeout(() => {
          this.loadingNote = false;
        }, 500);
      }
    },
    onNoteUpdated(noteUpdate: { note: string, dictation: boolean }) {
      this.loadingNote = true;
      const newNote: INote = {
        ...this.safeNote,
        note: noteUpdate.note,
        type: this.type,
        dictation: noteUpdate.dictation,
      };
      try {
        this.$store.dispatch('notes/saveNote', newNote).then(res => {
          this.$emit('completeElement', { valid: true });
        });
      } catch (error) {
        this.$log.debug(error);
      } finally {
        setTimeout(() => {
          this.loadingNote = false;
        }, 500);
      }
      this.$emit('updated', newNote);
    },
    onUpdated(updatedNote: string) {
      const newNote: INote = {
        ...this.note,
        note: updatedNote,
        date: new Date(),
      };
      this.$emit('updated', newNote);
    },
    fetchGeneralNote() {
      this.loadingNote = true;
      try {
        const day = moment(this.safeNote.date).toISOString();
        this.$store.dispatch('notes/getNotes', { type: 'GENERAL', day })
          .then(res => {
            if (res && res.content && res.content.length > 0) {
              this.generalNotes = res.content;
            }
          });
      } catch (error) {
        this.$log.debug(error);
      } finally {
        this.loadingNote = false;
      }
    },
    onAddNote() {
      this.showInputNote = !this.showInputNote;
    },
    onRecordingStateChanged(recording: boolean) {
      this.readonly = recording;
    },
  },
});
