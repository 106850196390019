var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.part.id
    ? _c(
        "v-layout",
        {
          staticClass: "element-part-webinar-registration",
          attrs: { wrap: "" }
        },
        [
          _vm.joinUrl
            ? _c(
                "v-flex",
                { attrs: { xs12: "", "mb-3": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { row: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c("v-hover", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var hover = ref.hover
                                    return [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.joinUrl,
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "join-card radius-20",
                                              class: {
                                                mobile:
                                                  _vm.$vuetify.breakpoint
                                                    .smAndDown,
                                                "elevation-4": !hover,
                                                "elevation-12":
                                                  hover && !_vm.loading
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card-text",
                                                {
                                                  staticClass:
                                                    "px-2 py-1 StarlingAlmostWhite--bg",
                                                  class: {
                                                    "cursor-pointer": !_vm.loading
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      attrs: {
                                                        "align-center": "",
                                                        "text-center": "",
                                                        "gap-xs-2": ""
                                                      }
                                                    },
                                                    [
                                                      _c("v-flex", [
                                                        _c(
                                                          "h4",
                                                          {
                                                            staticClass:
                                                              "starling-h4"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "app.products.element.webinar_registration.join_now_button"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2499961209
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var hover = ref.hover
                                return [
                                  !_vm.isRegistered
                                    ? _c(
                                        "v-card",
                                        {
                                          staticClass:
                                            "registration-card register radius-20",
                                          class: {
                                            mobile:
                                              _vm.$vuetify.breakpoint.smAndDown,
                                            "elevation-4": !hover,
                                            "elevation-12":
                                              hover && !_vm.loading
                                          },
                                          on: {
                                            click: _vm.handleRegistrationClick
                                          }
                                        },
                                        [
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass:
                                                "px-2 py-1 StarlingAlmostWhite--bg",
                                              class: {
                                                "cursor-pointer": !_vm.loading
                                              }
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    wrap: "",
                                                    "align-center": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    {
                                                      attrs: {
                                                        xs12: "",
                                                        "text-center": ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "h4",
                                                        {
                                                          staticClass:
                                                            "starling-h4"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "app.products.element.webinar_registration.register_button"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm.loading
                                                        ? _c(
                                                            "v-progress-circular",
                                                            {
                                                              attrs: {
                                                                indeterminate:
                                                                  "",
                                                                color:
                                                                  "StarlingPrimary1",
                                                                size: "42"
                                                              }
                                                            }
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "v-card",
                                        {
                                          staticClass:
                                            "registration-card unregister elevation-0 radius-20",
                                          class: {
                                            mobile:
                                              _vm.$vuetify.breakpoint.smAndDown
                                          }
                                        },
                                        [
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass:
                                                "px-2 py-2 StarlingAlmostWhite--bg"
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    wrap: "",
                                                    "align-center": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _c(
                                                        "v-layout",
                                                        [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                grow: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "starling-text StarlingPrimary1--text"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "app.products.element.webinar_registration.unregister_button"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                shrink: ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-switch", {
                                                                staticClass:
                                                                  "ma-0 pa-0",
                                                                attrs: {
                                                                  value: "",
                                                                  "input-value":
                                                                    "true",
                                                                  color:
                                                                    "StarlingGreen",
                                                                  "hide-details":
                                                                    "",
                                                                  loading:
                                                                    _vm.loading
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.handleRegistrationClick(
                                                                      $event
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs12: "" } },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "starling-text StarlingPrimary1--text"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "app.products.element.webinar_registration.success_message"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          700810912
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.showErrorMessage
            ? _c("v-flex", { attrs: { xs12: "", "mt-3": "" } }, [
                _c("p", {
                  staticClass:
                    "starling-text StarlingPrimary1--text text-center",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t(
                        "app.products.element.webinar_registration.error_message"
                      )
                    )
                  }
                })
              ])
            : _vm._e(),
          _c("custom-confirmation-modal", {
            attrs: {
              display: _vm.showConfirmation,
              title: _vm.$t(
                "app.products.element.webinar_registration.confirmation.title"
              ),
              description: _vm.$t(
                "app.products.element.webinar_registration.confirmation.description"
              ),
              primaryConfirmLabel: _vm.$t("common.actions.delete"),
              secondaryCancelLabel: _vm.$t("common.actions.cancel")
            },
            on: {
              "cancel-event": _vm.onConfirmCancel,
              "confirm-event": _vm.onConfirmDelete
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }