








import Vue from 'vue';
import { IStyleContent } from '@/scripts/store/modules/therapy/types';
import assessmentHistoryWrapper from '@/views/home/components/assessment-history-wrapper.vue';

export default Vue.extend({
  name: 'element-part-assessment-chart',
  components: { assessmentHistoryWrapper },
  props: {
    part: {
      type: Object as () => IStyleContent,
      required: true,
    },
  },
});
