












import Vue from 'vue';
import { IStyleContent } from '@/scripts/store/modules/therapy/types';
import CheckupResultsQuickGlance from '@/views/home/components/checkup-results-quick-glance.vue';

export default Vue.extend({
  name: 'element-part-checkup-result-chart',
  components: { CheckupResultsQuickGlance },
  props: {
    part: {
      type: Object as () => IStyleContent,
      required: true,
    },
  },
  data() {
    return {
      value: null as any,
      interpretations: {} as any,
    };
  },
  mounted() {
    this.$store
      .dispatch('assessment/getMostRecentAssessments')
      .then((res) => {
        if (res.status >= 400) {
          this.value = {};
          this.interpretations = {};
        } else {
          this.value = res;
          this.interpretations = {};
        }
      });
  },
  methods: {
    onLoadInterpretation(assessmentName: string) {
      this.$store
        .dispatch('assessment/getInterpretation', assessmentName)
        .then(res => {
          if (res.status >= 400) {
            this.$set(this.interpretations, assessmentName, null);
          } else {
            this.$set(this.interpretations, assessmentName, res);
          }
        });
    },
  },
});
