









import Vue from 'vue';
import { IStyleContent, ITherapySession } from '@/scripts/store/modules/therapy/types';
import HorizontalSteps, { IHorizontalStep } from '@/views/components/steppers/horizontal-steps.vue';

export default Vue.extend({
  name: 'element-part-sessions',
  components: {
    HorizontalSteps,
  },
  props: {
    part: {
      type: Object as () => IStyleContent,
      required: true,
    },
  },
  data() {
    return {
      sessionSteps: undefined as Array<IHorizontalStep> | undefined,
    };
  },
  mounted() {
    if (!this.part.content) return;

    this.$store.dispatch('therapy/getTherapySessionsStatusByUen', this.part.content).then((res: Array<ITherapySession>) => {
      this.sessionSteps = res.map(session => {
        return {
          text: session.order.toString(),
          status: session.status,
        } as IHorizontalStep;
      });
    });
  },
});
