var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "pl-0 pr-3 ml-0 my-1",
      attrs: { large: "" },
      on: { click: _vm.AddNote }
    },
    [
      _c("v-icon", { attrs: { color: "#2194DD" } }, [_vm._v("add")]),
      _vm._v(
        _vm._s(_vm.$t("app.assessments." + _vm.noteType + ".action_label")) +
          "\n"
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }