








import Vue from 'vue';
import { IStyleContent } from '@/scripts/store/modules/therapy/types';
import Battery from '@/views/home/components/battery.vue';

export default Vue.extend({
  name: 'element-part-battery',
  components: { Battery },
  props: {
    part: {
      type: Object as () => IStyleContent,
      required: true,
    },
  },
});
