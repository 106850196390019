var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      class: {
        "justify-start": _vm.justifyLeft,
        "justify-center": _vm.justifyCenter,
        "justify-end": _vm.justifyRight
      }
    },
    [
      _c(
        "v-flex",
        { attrs: { shrink: "" } },
        [
          _c(
            "primary-button",
            {
              staticStyle: { width: "150px" },
              attrs: { large: "", loading: _vm.loading },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.handleClick($event)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(_vm.buttonLabel) + "\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }