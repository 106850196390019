














import Vue from 'vue';
import { IStyleContentNavigation, StyleHorizontalEnum } from '@/scripts/store/modules/therapy/types';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export default Vue.extend({
  name: 'element-part-navigation',
  components: { PrimaryButton },
  props: {
    part: {
      type: Object as () => IStyleContentNavigation,
      required: true,
    },
  },
  computed: {
    buttonLabel(): string {
      return this.part.label ? this.part.label : this.$t('app.products.parts.navigation.button') as string;
    },
    justifyLeft(): boolean {
      return !!this.part.horizontal && this.part.horizontal === StyleHorizontalEnum.LEFT;
    },
    justifyCenter(): boolean {
      return !this.part.horizontal || this.part.horizontal === StyleHorizontalEnum.CENTER;
    },
    justifyRight(): boolean {
      return !!this.part.horizontal && this.part.horizontal === StyleHorizontalEnum.RIGHT;
    },
  },
  methods: {
    handleClick() {
      this.capacitorBrowserOpen(this.part.content);
    },
  },
});
