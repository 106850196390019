var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showImage
    ? _c(
        "div",
        {
          staticClass: "element-part-thumbnail",
          class: { "max-size": _vm.isMaxSize }
        },
        [
          !_vm.loaded
            ? _c(
                "div",
                {
                  staticClass: "d-flex fill-height align-center justify-center"
                },
                [
                  _c("v-progress-circular", {
                    attrs: { size: 50, color: "primary", indeterminate: "" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.imageUrl
            ? _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loaded,
                    expression: "loaded"
                  }
                ],
                staticClass: "thumbnail",
                class: {
                  mobile: _vm.$vuetify.breakpoint.smAndDown && !_vm.isMaxSize
                },
                attrs: { src: _vm.imageUrl, alt: _vm.imageAlt }
              })
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }