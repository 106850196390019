




import Vue from 'vue';

export default Vue.extend({
  name: 'element-part-graphic-content',
  props: {
    src: {
      type: String,
      required: true,
    },
    altText: {
      type: String,
      required: false,
    },
    attach: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      imageUrl: null as string | null,
      imageAlt: null as string | null,
    };
  },
  mounted() {
    const blob = new Blob([ this.src ], { type: 'image/svg+xml' });
    this.imageUrl = URL.createObjectURL(blob);
    this.imageAlt = this.altText;

    if (this.attach) {
      this.$nextTick(() => {
        try {
          const target = this.$el.closest(this.attach);
          target?.insertBefore(this.$el, target.firstChild);
        } catch (err) {
          this.$log.debug('Error attaching image', err);
        }
      });
    }
  },
  beforeDestroy() {
    if (this.attach) {
      try {
        const imageElement = this.$refs.image as HTMLElement;
        imageElement?.parentNode?.removeChild(imageElement);
      } catch (err) {
        this.$log.debug('Error removing attached image', err);
      }
    }

    if (this.imageUrl) {
      URL.revokeObjectURL(this.imageUrl);
      this.imageUrl = null;
    }
  },
});
