






















































import Vue from 'vue';
import { IStyleContent, StyleDeviceEnum, StyleHorizontalEnum, StyleSizeEnum, StyleTypeEnum } from '@/scripts/store/modules/therapy/types';
import ElementPartAnimation from '@/views/products/components/elements-parts/element-part-animation.vue';
import ElementPartAnxiety from '@/views/products/components/elements-parts/element-part-anxiety.vue';
import ElementPartAssessmentChart from '@/views/products/components/elements-parts/element-part-assessment-chart.vue';
import ElementPartAudio from '@/views/products/components/elements-parts/element-part-audio.vue';
import ElementPartBackground from '@/views/products/components/elements-parts/element-part-background.vue';
import ElementPartBattery from '@/views/products/components/elements-parts/element-part-battery.vue';
import ElementPartBody from '@/views/products/components/elements-parts/element-part-body.vue';
import ElementPartCheckupResultChart from '@/views/products/components/elements-parts/element-part-checkup-result-chart.vue';
import ElementPartCitation from '@/views/products/components/elements-parts/element-part-citation.vue';
import ElementPartDepression from '@/views/products/components/elements-parts/element-part-depression.vue';
import ElementPartDownload from '@/views/products/components/elements-parts/element-part-download.vue';
import ElementPartDownloadReport from '@/views/products/components/elements-parts/element-part-download-report.vue';
import ElementPartExplanatory from '@/views/products/components/elements-parts/element-part-explanatory.vue';
import ElementPartGraphic from '@/views/products/components/elements-parts/element-part-graphic.vue';
import ElementPartGuide from '@/views/products/components/elements-parts/element-part-guide.vue';
import ElementPartH1 from '@/views/products/components/elements-parts/element-part-h1.vue';
import ElementPartH2 from '@/views/products/components/elements-parts/element-part-h2.vue';
import ElementPartH3 from '@/views/products/components/elements-parts/element-part-h3.vue';
import ElementPartH4 from '@/views/products/components/elements-parts/element-part-h4.vue';
import ElementPartH5 from '@/views/products/components/elements-parts/element-part-h5.vue';
import ElementPartHyperlink from '@/views/products/components/elements-parts/element-part-hyperlink.vue';
import ElementPartInstructional from '@/views/products/components/elements-parts/element-part-instructional.vue';
import ElementPartMj from '@/views/products/components/elements-parts/element-part-mj.vue';
import ElementPartMm from '@/views/products/components/elements-parts/element-part-mm.vue';
import ElementPartMood from '@/views/products/components/elements-parts/element-part-mood.vue';
import ElementPartNavigation from '@/views/products/components/elements-parts/element-part-navigation.vue';
import ElementPartResilience from '@/views/products/components/elements-parts/element-part-resilience.vue';
import ElementPartSessions from '@/views/products/components/elements-parts/element-part-sessions.vue';
import ElementPartStepNumber from '@/views/products/components/elements-parts/element-part-step-number.vue';
import ElementPartText from '@/views/products/components/elements-parts/element-part-text.vue';
import ElementPartThumbnail from '@/views/products/components/elements-parts/element-part-thumbnail.vue';
import ElementPartVideo from '@/views/products/components/elements-parts/element-part-video.vue';
import ElementPartWebinarRegistration from '@/views/products/components/elements-parts/element-part-webinar-registration.vue';

const headerElements = [
  StyleTypeEnum.H1,
  StyleTypeEnum.H2,
  StyleTypeEnum.H3,
  StyleTypeEnum.H4,
  StyleTypeEnum.H5,
  StyleTypeEnum.STEP_NUMBER,
];

const graphicalElements = [
  StyleTypeEnum.ANIMATION,
  StyleTypeEnum.BACKGROUND,
  StyleTypeEnum.GRAPHIC,
  StyleTypeEnum.THUMBNAIL,
];

export default Vue.extend({
  name: 'element-styled-content',
  components: {
    ElementPartAnimation,
    ElementPartAnxiety,
    ElementPartAssessmentChart,
    ElementPartAudio,
    ElementPartBackground,
    ElementPartBattery,
    ElementPartBody,
    ElementPartCheckupResultChart,
    ElementPartCitation,
    ElementPartDepression,
    ElementPartDownload,
    ElementPartDownloadReport,
    ElementPartExplanatory,
    ElementPartGraphic,
    ElementPartGuide,
    ElementPartH1,
    ElementPartH2,
    ElementPartH3,
    ElementPartH4,
    ElementPartH5,
    ElementPartHyperlink,
    ElementPartInstructional,
    ElementPartMj,
    ElementPartMm,
    ElementPartMood,
    ElementPartNavigation,
    ElementPartResilience,
    ElementPartSessions,
    ElementPartStepNumber,
    ElementPartText,
    ElementPartThumbnail,
    ElementPartVideo,
    ElementPartWebinarRegistration,
  },
  props: {
    content: {
      type: Array as () => Array<IStyleContent>,
      required: true,
    },
    uen: {
      type: String,
      required: false,
    },
    isCardContent: {
      type: Boolean,
      required: false,
      default: false,
    },
    removeBottomMargin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    typeStyles() {
      return StyleTypeEnum;
    },
    horizontalStyles() {
      return StyleHorizontalEnum;
    },
    sizeStyles() {
      return StyleSizeEnum;
    },
    thumbnail(): IStyleContent | undefined {
      return this.content.filter(c => c.key === StyleTypeEnum.THUMBNAIL).find(c => !c.device ||
        (this.$vuetify.breakpoint.xsOnly && c.device === StyleDeviceEnum.MOBILE) ||
        (this.$vuetify.breakpoint.smAndUp && c.device == StyleDeviceEnum.DESKTOP));
    },
    thumbnailMaxSize(): boolean {
      return this.thumbnail?.size === StyleSizeEnum.XL;
    },
  },
  methods: {
    showPart(part: IStyleContent): boolean {
      const imageTypes = [ StyleTypeEnum.BACKGROUND, StyleTypeEnum.THUMBNAIL, StyleTypeEnum.ACTION_LABEL ];
      return imageTypes.every(image => part.key !== image);
    },
    updateBackground(bgStyle: CSSStyleDeclaration) {
      this.$emit('updateBackground', bgStyle);
    },
    componentName(partName: StyleTypeEnum) {
      const partType = Object.entries(StyleTypeEnum).filter(([ key, value ]) => value === partName).map(([ key, value ]) => key);
      if (!partType || !partType.length) {
        this.$log.warn('Element part not found: ', partName);
        return;
      }
      return `element-part-${partType.toString().toLowerCase().replaceAll('_', '-')}`;
    },
    additionalPadding(part: IStyleContent, index: number): boolean {
      // Don't add padding if card content
      if (this.isCardContent) return false;
      // Add padding if last part and not remove bottom margin
      if (index === this.content.length - 1) return !this.removeBottomMargin;
      // Don't add padding if graphical
      if (graphicalElements.some(item => item === part.key)) return false;
      // Don't add padding between h5 and body
      const nextPart = this.content[index + 1];
      if (part.key === StyleTypeEnum.H5 && nextPart.key === StyleTypeEnum.BODY) return false;
      // Don't add padding if header and next also header
      if (headerElements.some(item => item === part.key) && headerElements.some(item => item === nextPart.key)) return false;
      // Add padding if next part is not graphical
      return graphicalElements.every(item => item !== nextPart.key);
    },
  },
});
