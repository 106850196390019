var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-flex",
        {
          class: {
            xs12: _vm.part.size === _vm.sizeStyles.XL,
            "xs10 sm8": _vm.part.size === _vm.sizeStyles.LG,
            "xs8 sm5": !_vm.part.size || _vm.part.size === _vm.sizeStyles.MD,
            "xs6 sm4": _vm.part.size === _vm.sizeStyles.SM,
            "xs4 sm3": _vm.part.size === _vm.sizeStyles.XS,
            "text-left": _vm.justifyLeft,
            "text-center": _vm.justifyCenter,
            "text-right": _vm.justifyRight
          },
          attrs: { shrink: "" }
        },
        [
          _vm.loading
            ? _c("v-progress-circular", {
                attrs: { size: 100, color: "primary", indeterminate: "" }
              })
            : _vm._e(),
          _vm.animation && _vm.animation.script
            ? _c("lottie-player", {
                ref: "animationPlayer",
                attrs: {
                  src: _vm.animation.script,
                  autoplay: _vm.animation.autoplay,
                  count: _vm.animation.count,
                  direction: _vm.animation.direction,
                  hover: _vm.animation.hover,
                  loop: _vm.animation.loop,
                  mode: _vm.animation.mode,
                  speed: _vm.animation.speed
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }