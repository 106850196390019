<template>
  <v-layout row wrap class="assessment-history-scale" style="position:relative;top:30px;height: calc(100% - 75px);">
    <v-flex v-for="(interval, index) in scaleDefinition.intervals"
            xs12
            :key="index"
            :style="'position:absolute;left:0;top:calc('+top(interval)+' - 18px)'">
      <p class="y-axis-label font-weight-bold text-left ma-0 pa-0" :class="$vuetify.breakpoint.name">
        {{ interval.description }}</p>
      <p class="y-axis-label font-weight-regular ma-0 pa-0" :class="$vuetify.breakpoint.name" v-html="intervalRange(interval)"></p>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'assessment-history-scale',
  props: {
    scaleDefinition: Object,
  },
  data() {
    return { show: false };
  },
  mounted() {
    this.show = true;
  },
  methods: {
    intervalRange(interval) {
      if (interval.min != null && interval.max != null) {
        return interval.min + '&nbsp;-&nbsp;' + interval.max;
      }
      if (interval.max != null) {
        return '<&nbsp;' + interval.max;
      }
      if (interval.min != null) {
        return '>&nbsp;' + interval.min;
      }
    },
    top(interval) {
      const fixedMinimum = !interval.min ? this.scaleDefinition.min : interval.min;
      const fixedMaximum = !interval.max ? this.scaleDefinition.max : interval.max;

      return (100 - (100 * ((fixedMinimum + (fixedMaximum - fixedMinimum) / 2) / (this.scaleDefinition.max - this.scaleDefinition.min)))) + '%';
    },
  },
};
</script>

<style lang="scss" scoped>
.assessment-history-scale {
  .y-axis-label {
    color: var(--StarlingAlmostBlack);
    font-size: 16px;
    line-height: 125%;

    &.xs, &.sm {
      font-size: 10px;
    }

    &.md {
      font-size: 13px;
    }

    &.lg {
      font-size: 14px;
    }

    &.xl {
      font-size: 15px;
    }
  }
}
</style>
