var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      class: {
        "justify-start": _vm.justifyLeft,
        "justify-center": _vm.justifyCenter,
        "justify-end": _vm.justifyRight
      },
      style: _vm.justifyCenter ? "margin-left:44px" : ""
    },
    [
      _c("v-flex", { attrs: { shrink: "" } }, [
        _c(
          "svg",
          {
            staticClass: "clickable",
            attrs: {
              width: "98",
              height: "98",
              viewBox: "0 0 98 98",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            },
            on: { click: _vm.openModal }
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M98 49C98 61.4576 93.8475 66.4407 89.6949 75.5763C86.622 82.3864 81.3898 88.0339 73.9153 91.3559C66.1915 94.761 63.1186 98 49 98C35.878 98 22.4237 93.017 15.1983 84.5458C11.7932 80.4763 9.13559 76.4068 5.64746 71.839C0.91356 65.7763 0 57.222 0 49C0 31.5593 10.4644 14.2847 22.4237 8.30508C30.7288 4.15254 36.5424 0 49 0C63.1186 0 71.5068 4.48475 80.1441 11.2119C87.2034 16.6102 89.6949 20.9288 92.7678 26.9915C96.0898 33.6356 98 41.1102 98 49Z",
                fill: "url(#mj-inactive-paint0_linear" + _vm.suffix + ")"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M33.6357 73.6661C33.6357 73.6661 40.0306 70.5933 52.156 70.4271C64.2814 70.261 70.8425 73.6661 70.8425 73.6661C70.8425 73.6661 77.7357 88.6983 52.156 88.6983C24.7492 88.6983 33.6357 73.6661 33.6357 73.6661Z",
                fill: "url(#mj-inactive-paint1_linear" + _vm.suffix + ")"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M50.4949 17.1915C63.783 17.1915 64.2813 15.0322 68.2678 17.1915C80.061 23.6695 71.7559 37.3729 75.4101 42.6881C77.0712 45.0966 79.9779 50.3288 78.5661 57.9695C77.1542 65.6102 71.1746 76.5729 53.1525 76.5729C33.3864 76.6559 25.2474 69.7627 25.2474 51.8237C25.2474 51.8237 32.8051 17.1915 50.4949 17.1915Z",
                fill: "#2194DD"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M48.9999 97.9999C63.1185 97.9999 66.1914 94.761 73.9152 91.3559C78.4829 89.3627 82.2202 86.4559 85.127 82.8847C81.4728 79.5627 75.9914 78.2338 70.427 76.3237C61.2084 73.1677 66.8558 58.4677 66.2745 55.9762C65.6931 53.4847 63.7829 47.9203 51.0762 47.505C38.2033 47.1728 38.0372 56.4745 38.0372 56.4745C38.0372 56.4745 39.6982 67.7694 38.0372 71.922C35.9609 77.2372 32.2236 78.3169 23.9185 80.4762C20.5135 81.3067 17.6897 82.8016 15.2812 84.5457C22.5067 93.0169 35.9609 97.9999 48.9999 97.9999Z",
                fill: "url(#mj-inactive-paint2_radial" + _vm.suffix + ")"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M52.9033 17.2746C64.6135 17.2746 66.1914 23.3373 67.6863 26.2441C69.3474 29.317 69.0982 39.4492 69.2643 40.861C69.3474 42.2729 71.4236 43.9339 71.4236 49.0831C71.4236 53.817 71.4236 62.9526 61.6236 67.7695C58.7999 69.1814 58.3016 72.5865 52.8202 72.5865C47.9202 72.5865 46.4253 69.2644 43.9338 68.1017C39.0338 65.6932 29.2338 57.056 34.466 43.1865C35.2965 39.2 34.1338 30.6458 37.2067 25.3305C38.9507 22.4238 41.8575 17.2746 52.9033 17.2746Z",
                fill: "url(#mj-inactive-paint3_linear" + _vm.suffix + ")"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M52.9033 17.2746C64.6135 17.2746 66.1914 23.3373 67.6863 26.2441C69.3474 29.317 69.0982 39.4492 69.2643 40.861C69.3474 42.2729 71.4236 43.9339 71.4236 49.0831C71.4236 53.817 71.4236 62.9526 61.6236 67.7695C58.7999 69.1814 58.3016 72.5865 52.8202 72.5865C47.9202 72.5865 46.4253 69.2644 43.9338 68.1017C39.0338 65.6932 29.2338 57.056 34.466 43.1865C35.2965 39.2 34.1338 30.6458 37.2067 25.3305C38.9507 22.4238 41.8575 17.2746 52.9033 17.2746Z",
                fill: "url(#mj-inactive-paint4_radial" + _vm.suffix + ")"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M60.9593 57.8864C61.3745 57.5542 61.7067 57.5542 61.9559 57.4712C62.4542 57.3051 62.6203 57.3051 62.3712 56.8898C61.9559 56.3084 61.9559 57.139 60.6271 57.5542C56.9729 58.5508 55.561 57.3881 54.6474 57.6373C53.6508 57.8034 53.4847 58.3017 53.2356 58.3017C52.9864 58.3017 52.7373 57.7203 51.6576 57.8034C50.5779 57.8864 47.339 58.2186 45.5949 57.7203C45.1796 57.6373 44.7644 57.4712 44.5152 57.3881C43.6847 57.0559 43.8508 56.7237 43.4356 57.139C43.1034 57.3881 43.6017 57.7203 44.0169 57.7203C44.2661 57.7203 44.7644 57.9695 45.1796 58.2186C46.5915 58.883 48.9169 59.2152 52.7373 59.2983C52.9034 59.2983 53.0695 59.4644 53.1525 59.4644C53.2356 59.4644 53.4017 59.2983 53.4847 59.2983C57.3881 59.1322 59.4644 59.0491 60.9593 57.8864Z",
                fill: "#2194DD"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M59.9628 60.2949C59.3814 60.1288 59.2153 61.4576 52.9034 61.4576C47.0899 61.4576 46.3424 59.7966 46.0933 60.0457C45.678 60.3779 48.834 62.0389 52.9034 62.0389C57.056 62.0389 60.7933 60.544 59.9628 60.2949Z",
                fill: "#2194DD"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M57.9695 42.7711C57.9695 42.7711 58.5508 42.605 58.9661 42.2728C59.4644 41.9406 60.1288 40.5287 61.9559 40.5287C64.3644 40.5287 65.7763 42.1898 65.7763 42.1898",
                stroke: "#2194DD",
                "stroke-miterlimit": "10",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M47.5051 42.605C46.7577 42.522 46.5085 42.1898 46.0933 42.0237C45.678 41.7745 45.5119 40.6949 43.1865 40.861C40.6119 41.0271 39.2831 43.1864 39.2831 43.1864",
                stroke: "#2194DD",
                "stroke-miterlimit": "10",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M44.4323 42.6881C44.4323 42.6881 44.9306 42.0237 45.2628 41.1931C44.8475 40.944 44.2662 40.7779 43.1865 40.7779C42.5221 40.7779 41.8577 41.027 41.3594 41.2762C41.5255 41.9406 41.8577 42.6881 42.439 42.6881C43.3526 42.6881 44.4323 42.6881 44.4323 42.6881Z",
                fill: "#2194DD"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M63.5339 40.7779C63.0356 40.6118 62.5373 40.5287 61.8729 40.5287C61.2916 40.5287 60.8763 40.6949 60.4611 40.861C60.6272 41.4423 60.6272 42.522 61.956 42.4389C63.617 42.4389 63.4509 41.6915 63.5339 40.7779Z",
                fill: "#2194DD"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M44.5983 25.9118C45.761 25.7457 46.3424 27.3237 53.2356 26.9084C60.0458 26.4932 63.1187 23.005 65.1119 23.5033C67.1051 24.0016 65.8593 35.1305 69.5136 37.2898C72.8356 39.283 78.0678 21.0118 68.3509 17.1084C64.3644 15.5305 63.8661 16.6932 63.8661 16.6932C63.8661 16.6932 63.617 11.1288 55.2288 10.1322C45.0966 8.96944 37.3729 12.0423 33.3865 21.4271C30.3136 28.5694 31.8915 35.3796 27.4068 38.8677C17.7729 46.4254 19.1017 58.3847 29.4 59.1322C35.1305 59.5474 30.1475 61.4576 33.3865 48.3355C34.7153 43.1033 35.5458 45.5949 38.7017 41.3593C43.3526 35.1305 37.3729 26.8254 44.5983 25.9118Z",
                fill: "#2194DD"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M15.1983 84.5458C22.4237 93.017 35.8779 98 49 98C63.1186 98 66.1915 94.761 73.9152 91.356C78.5661 89.2797 82.3034 86.2898 85.2932 82.7187C82.7186 80.2271 79.1474 78.8153 75.3271 77.4865C74.4966 77.2373 74.2474 77.1543 73.4169 76.8221C72.6695 76.5729 71.5068 73.9153 70.7593 73.5831C68.2678 80.9746 62.7034 83.7983 56.2254 85.1271C53.983 85.5424 53.1525 91.9373 52.4051 91.9373C51.6576 91.9373 50.744 85.1271 48.4186 84.7949C40.6118 83.8814 33.8847 79.3966 33.5525 73.5831C32.2237 74.5797 30.1474 78.1509 30.1474 78.1509C28.8186 78.7322 25.4135 79.6458 23.9186 80.061C20.4305 80.9746 17.5237 82.4695 15.1152 84.2966C15.1983 84.3797 15.1983 84.4627 15.1983 84.5458Z",
                fill: "url(#mj-inactive-paint5_linear" + _vm.suffix + ")"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M58.3847 92.8509C66.6067 91.5221 70.6762 80.3932 73.4169 76.8221C72.6694 76.5729 71.5067 73.9153 70.7592 73.5831C68.2677 81.3068 62.7033 83.7983 56.2253 85.1271C58.883 87.5356 55.4779 93.3492 58.3847 92.8509Z",
                fill: "url(#mj-inactive-paint6_linear" + _vm.suffix + ")"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M30.1475 78.2339C31.9746 85.1271 41.0271 90.1102 46.5085 92.4356C51.9898 94.761 44.9305 87.2034 48.4186 84.961C40.6119 84.0475 34.3 79.5627 33.5525 73.7491C32.2237 74.6627 30.1475 78.2339 30.1475 78.2339Z",
                fill: "url(#mj-inactive-paint7_linear" + _vm.suffix + ")"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M38.9508 38.7848C38.9508 38.7848 40.1135 37.539 44.6813 37.539C48.4186 37.539 49.4983 37.7051 49.4983 37.7051",
                stroke: "#2194DD",
                "stroke-width": "2",
                "stroke-miterlimit": "10",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M56.0593 37.9543C56.0593 37.9543 60.0458 36.8746 64.0322 37.456C68.0186 38.1204 68.517 39.8644 68.517 39.8644",
                stroke: "#2194DD",
                "stroke-width": "2",
                "stroke-miterlimit": "10",
                "stroke-linecap": "round",
                "stroke-linejoin": "round"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M50.9933 42.2729C50.9933 46.3424 46.9238 49.6644 43.6017 49.6644C39.5322 49.6644 36.2102 46.3424 36.2102 42.2729C36.2102 38.2034 39.5322 35.7119 43.6017 35.7119C47.6712 35.7119 50.9933 38.2034 50.9933 42.2729Z",
                stroke: "#4C75AA",
                "stroke-width": "1.25",
                "stroke-miterlimit": "10"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M69.5136 42.2729C69.5136 46.3424 66.1916 49.6644 62.1221 49.6644C58.8831 49.6644 54.7306 46.3424 54.7306 42.2729C54.7306 38.2034 58.0526 35.7119 62.1221 35.7119C66.1916 35.7119 69.5136 38.2034 69.5136 42.2729Z",
                stroke: "#4C75AA",
                "stroke-width": "1.25",
                "stroke-miterlimit": "10"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M50.9932 42.2729C50.9932 42.2729 51.5745 40.6119 52.9033 40.6119C54.2321 40.6119 54.8135 42.2729 54.8135 42.2729",
                stroke: "#4C75AA",
                "stroke-width": "1.25",
                "stroke-miterlimit": "10"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M54.8137 53.4017C53.8171 53.7339 52.6544 53.8169 51.7408 53.4847C50.7442 53.1525 50.9933 52.5712 48.5018 52.322C47.9205 52.239 47.2561 51.5746 47.09 51.8237C46.9239 52.0729 47.4222 52.6542 47.9205 52.8203C49.9137 53.5678 50.495 54.3152 51.2425 54.7305C51.99 55.1458 53.5679 55.7271 54.7306 54.7305C55.8933 53.7339 57.3052 52.8203 58.551 52.4881C58.9662 52.4051 59.3815 51.9898 59.2154 51.5746C59.1323 51.3254 58.3849 51.9068 57.2222 52.1559C56.1425 52.5712 55.6442 53.0695 54.8137 53.4017Z",
                fill: "#2194DD"
              }
            }),
            _c(
              "defs",
              [
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "mj-inactive-paint0_linear" + _vm.suffix,
                      x1: "49.1149",
                      y1: "-0.701032",
                      x2: "48.8884",
                      y2: "97.2987",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { offset: "0.0168194", "stop-color": "#FF777E" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.908", "stop-color": "#FFE5E4" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "mj-inactive-paint1_linear" + _vm.suffix,
                      x1: "51.8589",
                      y1: "88.674",
                      x2: "52.0332",
                      y2: "64.0086",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#DCFCF8" } }),
                    _c("stop", {
                      attrs: { offset: "0.1003", "stop-color": "#D0F5F2" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.2811", "stop-color": "#B0E3E2" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5219", "stop-color": "#7DC6C7" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.81", "stop-color": "#379EA3" }
                    }),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#058189" }
                    })
                  ],
                  1
                ),
                _c(
                  "radialGradient",
                  {
                    attrs: {
                      id: "mj-inactive-paint2_radial" + _vm.suffix,
                      cx: "0",
                      cy: "0",
                      r: "1",
                      gradientUnits: "userSpaceOnUse",
                      gradientTransform:
                        "translate(54.6307 59.5022) scale(42.8449)"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { offset: "0.3413", "stop-color": "#4C75AA" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.6093", "stop-color": "#19AEF6" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.7012", "stop-color": "#19AEF6" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.72", "stop-color": "#20A6EC" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.7816", "stop-color": "#3391CF" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.843", "stop-color": "#4181BA" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.9036", "stop-color": "#4978AE" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.9625", "stop-color": "#4C75AA" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "mj-inactive-paint3_linear" + _vm.suffix,
                      x1: "51.8109",
                      y1: "110.012",
                      x2: "52.6038",
                      y2: "-2.2157",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { offset: "0.1129", "stop-color": "#2FCEFB" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.2276", "stop-color": "#34CEFA" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.3719", "stop-color": "#44CFF8" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.5321", "stop-color": "#5ED1F4" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.7038", "stop-color": "#82D4EF" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.8829", "stop-color": "#AFD7E8" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.939", "stop-color": "#BFD8E6" }
                    })
                  ],
                  1
                ),
                _c(
                  "radialGradient",
                  {
                    attrs: {
                      id: "mj-inactive-paint4_radial" + _vm.suffix,
                      cx: "0",
                      cy: "0",
                      r: "1",
                      gradientUnits: "userSpaceOnUse",
                      gradientTransform:
                        "translate(64.3393 51.053) scale(45.1159)"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { "stop-color": "#FFE5E4", "stop-opacity": "0.5" }
                    }),
                    _c("stop", {
                      attrs: {
                        offset: "0.1972",
                        "stop-color": "#EAE1E5",
                        "stop-opacity": "0.3982"
                      }
                    }),
                    _c("stop", {
                      attrs: {
                        offset: "0.48",
                        "stop-color": "#D2DCE5",
                        "stop-opacity": "0.2522"
                      }
                    }),
                    _c("stop", {
                      attrs: {
                        offset: "0.7434",
                        "stop-color": "#C4D9E6",
                        "stop-opacity": "0.1161"
                      }
                    }),
                    _c("stop", {
                      attrs: {
                        offset: "0.9664",
                        "stop-color": "#BFD8E6",
                        "stop-opacity": "0.01"
                      }
                    }),
                    _c("stop", {
                      attrs: {
                        offset: "0.9998",
                        "stop-color": "#BFD8E6",
                        "stop-opacity": "0"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "mj-inactive-paint5_linear" + _vm.suffix,
                      x1: "50.1994",
                      y1: "96.3716",
                      x2: "50.1983",
                      y2: "77.9417",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", { attrs: { "stop-color": "#DCFCF8" } }),
                    _c("stop", {
                      attrs: { offset: "0.32", "stop-color": "#E8F4F1" }
                    }),
                    _c("stop", {
                      attrs: { offset: "0.7567", "stop-color": "#FFE5E4" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "mj-inactive-paint6_linear" + _vm.suffix,
                      x1: "64.8558",
                      y1: "91.6125",
                      x2: "64.8549",
                      y2: "77.049",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { "stop-color": "white", "stop-opacity": "0.9" }
                    }),
                    _c("stop", {
                      attrs: {
                        offset: "0.5189",
                        "stop-color": "#FFF4F3",
                        "stop-opacity": "0.9519"
                      }
                    }),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#FFE5E4" }
                    })
                  ],
                  1
                ),
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "mj-inactive-paint7_linear" + _vm.suffix,
                      x1: "39.4404",
                      y1: "91.5883",
                      x2: "39.4395",
                      y2: "77.0439",
                      gradientUnits: "userSpaceOnUse"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { "stop-color": "white", "stop-opacity": "0.9" }
                    }),
                    _c("stop", {
                      attrs: {
                        offset: "0.5189",
                        "stop-color": "#FFF4F3",
                        "stop-opacity": "0.9519"
                      }
                    }),
                    _c("stop", {
                      attrs: { offset: "1", "stop-color": "#FFE5E4" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "v-flex",
        { attrs: { shrink: "" } },
        [
          _c(
            "v-btn",
            {
              staticStyle: { "margin-left": "-8px", "margin-top": "-8px" },
              attrs: { icon: "", flat: "", large: "", color: "primary" },
              on: { click: _vm.openModal }
            },
            [_c("v-icon", [_vm._v("mdi-information-outline")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "mj-dialog",
            scrollable: false,
            persistent: "",
            "no-click-animation": ""
          },
          model: {
            value: _vm.opened,
            callback: function($$v) {
              _vm.opened = $$v
            },
            expression: "opened"
          }
        },
        [
          !_vm.isInternetExplorer
            ? _c(
                "v-container",
                { staticClass: "pa-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "text-center",
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-center": "",
                        "align-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md7: "", lg4: "" } },
                        [
                          _c("v-img", { attrs: { contain: "" } }, [
                            _c(
                              "svg",
                              {
                                ref: "modaleSvg",
                                attrs: {
                                  viewBox: _vm.$vuetify.breakpoint.xsOnly
                                    ? "0 0 394 512"
                                    : "0 0 480 512",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M237.098 447.998C237.098 447.998 505.445 451.355 470.936 191.59C436.426 -68.1746 194.779 -3.53023 137.986 42.1998C81.1924 87.9299 -14.567 211.651 89.002 304.175C192.571 396.7 104.58 440.178 104.58 440.178L237.098 447.998Z",
                                    fill:
                                      "url(#mj-active-paint0_linear" +
                                      _vm.suffix +
                                      ")"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M103.5 460.5C103.5 473.593 99.1356 478.831 94.7712 488.432C91.5415 495.59 86.0424 501.525 78.1864 505.017C70.0686 508.596 66.839 512 52 512C38.2085 512 24.0678 506.763 16.4737 497.859C12.8949 493.582 10.1017 489.305 6.43559 484.504C1.46017 478.132 0.5 469.142 0.5 460.5C0.5 442.169 11.4983 424.014 24.0678 417.729C32.7966 413.364 38.9068 409 52 409C66.839 409 75.6551 413.714 84.7331 420.784C92.1525 426.458 94.7712 430.997 98.0008 437.369C101.492 444.352 103.5 452.208 103.5 460.5Z",
                                    fill:
                                      "url(#mj-active-paint1_linear" +
                                      _vm.suffix +
                                      ")"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M35.8516 486.425C35.8516 486.425 42.5728 483.195 55.3168 483.02C68.0609 482.846 74.9567 486.425 74.9567 486.425C74.9567 486.425 82.2016 502.224 55.3168 502.224C26.5118 502.224 35.8516 486.425 35.8516 486.425Z",
                                    fill:
                                      "url(#mj-active-paint2_linear" +
                                      _vm.suffix +
                                      ")"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M53.5712 427.069C67.5373 427.069 68.0611 424.799 72.2509 427.069C84.6458 433.877 75.917 448.28 79.7577 453.866C81.5034 456.397 84.5585 461.897 83.0746 469.927C81.5907 477.958 75.306 489.48 56.3645 489.48C35.5899 489.567 27.0356 482.322 27.0356 463.468C27.0356 463.468 34.9789 427.069 53.5712 427.069Z",
                                    fill: "#2194DD"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M52 512C66.839 512 70.0687 508.596 78.1865 505.017C82.9873 502.922 86.9153 499.867 89.9704 496.113C86.1297 492.622 80.3687 491.225 74.5204 489.218C64.8314 485.901 70.767 470.451 70.156 467.832C69.5449 465.213 67.5373 459.365 54.1822 458.929C40.6526 458.58 40.478 468.356 40.478 468.356C40.478 468.356 42.2237 480.227 40.478 484.591C38.2958 490.178 34.3678 491.313 25.639 493.582C22.0602 494.455 19.0924 496.026 16.561 497.859C24.1551 506.763 38.2958 512 52 512Z",
                                    fill:
                                      "url(#mj-active-paint3_radial" +
                                      _vm.suffix +
                                      ")"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M56.1025 427.156C68.4101 427.156 70.0686 433.528 71.6398 436.583C73.3855 439.813 73.1237 450.462 73.2983 451.946C73.3855 453.43 75.5677 455.175 75.5677 460.587C75.5677 465.563 75.5677 475.164 65.2677 480.227C62.2999 481.711 61.7762 485.29 56.0152 485.29C50.8652 485.29 49.294 481.798 46.6754 480.576C41.5254 478.045 31.2254 468.967 36.7245 454.39C37.5974 450.2 36.3754 441.209 39.605 435.623C41.4381 432.568 44.4932 427.156 56.1025 427.156Z",
                                    fill:
                                      "url(#mj-active-paint4_linear" +
                                      _vm.suffix +
                                      ")"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M56.1025 427.156C68.4101 427.156 70.0686 433.528 71.6398 436.583C73.3855 439.813 73.1237 450.462 73.2983 451.946C73.3855 453.43 75.5677 455.175 75.5677 460.587C75.5677 465.563 75.5677 475.164 65.2677 480.227C62.2999 481.711 61.7762 485.29 56.0152 485.29C50.8652 485.29 49.294 481.798 46.6754 480.576C41.5254 478.045 31.2254 468.967 36.7245 454.39C37.5974 450.2 36.3754 441.209 39.605 435.623C41.4381 432.568 44.4932 427.156 56.1025 427.156Z",
                                    fill:
                                      "url(#mj-active-paint5_radial" +
                                      _vm.suffix +
                                      ")"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M64.5697 469.84C65.0062 469.491 65.3553 469.491 65.6172 469.403C66.1409 469.229 66.3155 469.229 66.0536 468.792C65.6172 468.181 65.6172 469.054 64.2206 469.491C60.3799 470.538 58.896 469.316 57.9358 469.578C56.8884 469.753 56.7138 470.276 56.4519 470.276C56.1901 470.276 55.9282 469.665 54.7934 469.753C53.6587 469.84 50.2545 470.189 48.4214 469.665C47.985 469.578 47.5485 469.403 47.2867 469.316C46.4138 468.967 46.5884 468.618 46.1519 469.054C45.8028 469.316 46.3265 469.665 46.7629 469.665C47.0248 469.665 47.5485 469.927 47.985 470.189C49.4689 470.887 51.9129 471.236 55.9282 471.324C56.1028 471.324 56.2773 471.498 56.3646 471.498C56.4519 471.498 56.6265 471.324 56.7138 471.324C60.8163 471.149 62.9985 471.062 64.5697 469.84Z",
                                    fill: "#2194DD"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M63.522 472.371C62.911 472.197 62.7364 473.593 56.1025 473.593C49.9923 473.593 49.2067 471.847 48.9449 472.109C48.5084 472.458 51.8254 474.204 56.1025 474.204C60.4669 474.204 64.3949 472.633 63.522 472.371Z",
                                    fill: "#2194DD"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M61.4272 453.953C61.4272 453.953 62.0383 453.779 62.4747 453.43C62.9984 453.08 63.6967 451.597 65.6171 451.597C68.1484 451.597 69.6323 453.342 69.6323 453.342",
                                    stroke: "#2194DD",
                                    "stroke-miterlimit": "10",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M50.4291 453.779C49.6435 453.691 49.3817 453.342 48.9452 453.168C48.5088 452.906 48.3342 451.771 45.8901 451.946C43.1842 452.12 41.7876 454.39 41.7876 454.39",
                                    stroke: "#2194DD",
                                    "stroke-miterlimit": "10",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M47.1994 453.866C47.1994 453.866 47.7231 453.168 48.0723 452.295C47.6358 452.033 47.0248 451.858 45.8901 451.858C45.1918 451.858 44.4935 452.12 43.9697 452.382C44.1443 453.08 44.4935 453.866 45.1045 453.866C46.0646 453.866 47.1994 453.866 47.1994 453.866Z",
                                    fill: "#2194DD"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M67.2756 451.858C66.7518 451.684 66.2281 451.597 65.5298 451.597C64.9188 451.597 64.4823 451.771 64.0459 451.946C64.2205 452.557 64.2205 453.691 65.6171 453.604C67.3628 453.604 67.1883 452.819 67.2756 451.858Z",
                                    fill: "#2194DD"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M47.3739 436.234C48.5959 436.059 49.207 437.718 56.4519 437.281C63.6095 436.845 66.8392 433.179 68.9341 433.703C71.029 434.226 69.7197 445.923 73.5603 448.192C77.0519 450.287 82.551 431.084 72.3383 426.981C68.1485 425.323 67.6248 426.545 67.6248 426.545C67.6248 426.545 67.3629 420.697 58.5468 419.649C47.8976 418.427 39.7798 421.657 35.59 431.52C32.3603 439.027 34.0188 446.185 29.3053 449.851C19.1798 457.794 20.5764 470.364 31.4002 471.149C37.4231 471.586 32.1858 473.593 35.59 459.802C36.9866 454.303 37.8595 456.921 41.1764 452.469C46.0646 445.923 39.7798 437.194 47.3739 436.234Z",
                                    fill: "#2194DD"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M16.4738 497.859C24.0678 506.763 38.2085 512 52 512C66.839 512 70.0687 508.596 78.1865 505.017C83.0746 502.835 87.0026 499.692 90.145 495.939C87.439 493.32 83.6856 491.836 79.6704 490.44C78.7975 490.178 78.5356 490.091 77.6628 489.742C76.8772 489.48 75.6551 486.686 74.8695 486.337C72.2509 494.106 66.4026 497.074 59.5941 498.47C57.2373 498.907 56.3644 505.628 55.5789 505.628C54.7933 505.628 53.8331 498.47 51.389 498.121C43.1839 497.161 36.1136 492.447 35.7644 486.337C34.3678 487.385 32.1856 491.138 32.1856 491.138C30.789 491.749 27.2102 492.709 25.639 493.146C21.9729 494.106 18.9178 495.677 16.3865 497.597C16.4738 497.685 16.4738 497.772 16.4738 497.859Z",
                                    fill:
                                      "url(#mj-active-paint6_linear" +
                                      _vm.suffix +
                                      ")"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M61.8635 506.588C70.505 505.192 74.7821 493.495 77.6626 489.742C76.8771 489.48 75.655 486.686 74.8694 486.337C72.2508 494.455 66.4025 497.074 59.594 498.47C62.3872 501.002 58.8084 507.112 61.8635 506.588Z",
                                    fill:
                                      "url(#mj-active-paint7_linear" +
                                      _vm.suffix +
                                      ")"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M32.1855 491.225C34.1059 498.47 43.6203 503.708 49.3813 506.152C55.1423 508.596 47.7228 500.653 51.3889 498.296C43.1839 497.336 36.55 492.622 35.7644 486.512C34.3678 487.472 32.1855 491.225 32.1855 491.225Z",
                                    fill:
                                      "url(#mj-active-paint8_linear" +
                                      _vm.suffix +
                                      ")"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M41.438 449.764C41.438 449.764 42.66 448.454 47.4609 448.454C51.3888 448.454 52.5236 448.629 52.5236 448.629",
                                    stroke: "#2194DD",
                                    "stroke-width": "2",
                                    "stroke-miterlimit": "10",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M59.4194 448.891C59.4194 448.891 63.6093 447.756 67.7991 448.367C71.9889 449.065 72.5127 450.898 72.5127 450.898",
                                    stroke: "#2194DD",
                                    "stroke-width": "2",
                                    "stroke-miterlimit": "10",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M54.0949 453.43C54.0949 457.707 49.8178 461.198 46.3263 461.198C42.0491 461.198 38.5576 457.707 38.5576 453.43C38.5576 449.153 42.0491 446.534 46.3263 446.534C50.6034 446.534 54.0949 449.153 54.0949 453.43Z",
                                    stroke: "#4C75AA",
                                    "stroke-width": "1.25",
                                    "stroke-miterlimit": "10"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M73.5605 453.43C73.5605 457.707 70.069 461.198 65.7918 461.198C62.3876 461.198 58.0232 457.707 58.0232 453.43C58.0232 449.153 61.5147 446.534 65.7918 446.534C70.069 446.534 73.5605 449.153 73.5605 453.43Z",
                                    stroke: "#4C75AA",
                                    "stroke-width": "1.25",
                                    "stroke-miterlimit": "10"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M54.0947 453.43C54.0947 453.43 54.7057 451.684 56.1024 451.684C57.499 451.684 58.11 453.43 58.11 453.43",
                                    stroke: "#4C75AA",
                                    "stroke-width": "1.25",
                                    "stroke-miterlimit": "10"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M58.1103 465.126C57.0628 465.475 55.8408 465.563 54.8806 465.214C53.8332 464.864 54.095 464.253 51.4764 463.991C50.8654 463.904 50.1671 463.206 49.9925 463.468C49.8179 463.73 50.3416 464.341 50.8654 464.515C52.9603 465.301 53.5713 466.086 54.3569 466.523C55.1425 466.959 56.801 467.57 58.023 466.523C59.245 465.475 60.7289 464.515 62.0383 464.166C62.4747 464.079 62.9111 463.642 62.7366 463.206C62.6493 462.944 61.8637 463.555 60.6416 463.817C59.5069 464.253 58.9832 464.777 58.1103 465.126Z",
                                    fill: "#2194DD"
                                  }
                                }),
                                _vm.opened
                                  ? _c(
                                      "g",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.scale,
                                            expression: "scale"
                                          }
                                        ],
                                        ref: "textContent"
                                      },
                                      [
                                        _c(
                                          "foreignObject",
                                          {
                                            attrs: {
                                              x: 85,
                                              y: 60,
                                              width: 290,
                                              height: 300,
                                              transform: "scale(" + 1 + ")"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  height: "100%",
                                                  width: "100%"
                                                },
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/1999/xhtml"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "right",
                                                      position: "fixed",
                                                      width: "100%",
                                                      height: "100%"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          small: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.opened = false
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("mdi-close")
                                                        ])
                                                      ],
                                                      1
                                                    ),
                                                    _c("p", {
                                                      staticClass:
                                                        "mm-talk mj-talk-voice",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.part.content
                                                        )
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "defs",
                                  [
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          id:
                                            "mj-active-paint0_linear" +
                                            _vm.suffix,
                                          x1: "473.97",
                                          y1: "223.992",
                                          x2: "43.0031",
                                          y2: "223.992",
                                          gradientUnits: "userSpaceOnUse"
                                        }
                                      },
                                      [
                                        _c("stop", {
                                          attrs: { "stop-color": "#F4F5F4" }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.466",
                                            "stop-color": "#F1F4F4"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.7139",
                                            "stop-color": "#E9F0F5"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.9099",
                                            "stop-color": "#DBEAF6"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "1",
                                            "stop-color": "#D1E7F7"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          id:
                                            "mj-active-paint1_linear" +
                                            _vm.suffix,
                                          x1: "52.1207",
                                          y1: "408.263",
                                          x2: "51.8827",
                                          y2: "511.263",
                                          gradientUnits: "userSpaceOnUse"
                                        }
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.0168194",
                                            "stop-color": "#FF777E"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.908",
                                            "stop-color": "#FFE5E4"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          id:
                                            "mj-active-paint2_linear" +
                                            _vm.suffix,
                                          x1: "55.0046",
                                          y1: "502.198",
                                          x2: "55.1877",
                                          y2: "476.274",
                                          gradientUnits: "userSpaceOnUse"
                                        }
                                      },
                                      [
                                        _c("stop", {
                                          attrs: { "stop-color": "#DCFCF8" }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.1003",
                                            "stop-color": "#D0F5F2"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.2811",
                                            "stop-color": "#B0E3E2"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.5219",
                                            "stop-color": "#7DC6C7"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.81",
                                            "stop-color": "#379EA3"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "1",
                                            "stop-color": "#058189"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "radialGradient",
                                      {
                                        attrs: {
                                          id:
                                            "mj-active-paint3_radial" +
                                            _vm.suffix,
                                          cx: "0",
                                          cy: "0",
                                          r: "1",
                                          gradientUnits: "userSpaceOnUse",
                                          gradientTransform:
                                            "translate(57.9181 471.538) scale(45.0309)"
                                        }
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.3413",
                                            "stop-color": "#4C75AA"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.6093",
                                            "stop-color": "#19AEF6"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.7012",
                                            "stop-color": "#19AEF6"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.72",
                                            "stop-color": "#20A6EC"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.7816",
                                            "stop-color": "#3391CF"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.843",
                                            "stop-color": "#4181BA"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.9036",
                                            "stop-color": "#4978AE"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.9625",
                                            "stop-color": "#4C75AA"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          id:
                                            "mj-active-paint4_linear" +
                                            _vm.suffix,
                                          x1: "54.9544",
                                          y1: "524.625",
                                          x2: "55.7877",
                                          y2: "406.671",
                                          gradientUnits: "userSpaceOnUse"
                                        }
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.1129",
                                            "stop-color": "#2FCEFB"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.2276",
                                            "stop-color": "#34CEFA"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.3719",
                                            "stop-color": "#44CFF8"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.5321",
                                            "stop-color": "#5ED1F4"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.7038",
                                            "stop-color": "#82D4EF"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.8829",
                                            "stop-color": "#AFD7E8"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.939",
                                            "stop-color": "#BFD8E6"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "radialGradient",
                                      {
                                        attrs: {
                                          id:
                                            "mj-active-paint5_radial" +
                                            _vm.suffix,
                                          cx: "0",
                                          cy: "0",
                                          r: "1",
                                          gradientUnits: "userSpaceOnUse",
                                          gradientTransform:
                                            "translate(68.122 462.658) scale(47.4177)"
                                        }
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "#FFE5E4",
                                            "stop-opacity": "0.5"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.1972",
                                            "stop-color": "#EAE1E5",
                                            "stop-opacity": "0.3982"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.48",
                                            "stop-color": "#D2DCE5",
                                            "stop-opacity": "0.2522"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.7434",
                                            "stop-color": "#C4D9E6",
                                            "stop-opacity": "0.1161"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.9664",
                                            "stop-color": "#BFD8E6",
                                            "stop-opacity": "0.01"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.9998",
                                            "stop-color": "#BFD8E6",
                                            "stop-opacity": "0"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          id:
                                            "mj-active-paint6_linear" +
                                            _vm.suffix,
                                          x1: "53.2607",
                                          y1: "510.288",
                                          x2: "53.2595",
                                          y2: "490.918",
                                          gradientUnits: "userSpaceOnUse"
                                        }
                                      },
                                      [
                                        _c("stop", {
                                          attrs: { "stop-color": "#DCFCF8" }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.32",
                                            "stop-color": "#E8F4F1"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.7567",
                                            "stop-color": "#FFE5E4"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          id:
                                            "mj-active-paint7_linear" +
                                            _vm.suffix,
                                          x1: "68.6648",
                                          y1: "505.287",
                                          x2: "68.6638",
                                          y2: "489.98",
                                          gradientUnits: "userSpaceOnUse"
                                        }
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "white",
                                            "stop-opacity": "0.9"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.5189",
                                            "stop-color": "#FFF4F3",
                                            "stop-opacity": "0.9519"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "1",
                                            "stop-color": "#FFE5E4"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          id:
                                            "mj-active-paint8_linear" +
                                            _vm.suffix,
                                          x1: "41.9527",
                                          y1: "505.261",
                                          x2: "41.9517",
                                          y2: "489.975",
                                          gradientUnits: "userSpaceOnUse"
                                        }
                                      },
                                      [
                                        _c("stop", {
                                          attrs: {
                                            "stop-color": "white",
                                            "stop-opacity": "0.9"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "0.5189",
                                            "stop-color": "#FFF4F3",
                                            "stop-opacity": "0.9519"
                                          }
                                        }),
                                        _c("stop", {
                                          attrs: {
                                            offset: "1",
                                            "stop-color": "#FFE5E4"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isInternetExplorer
            ? _c(
                "v-container",
                { staticClass: "pa-0", attrs: { fluid: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "text-center",
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-center": "",
                        "align-center": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", md7: "", lg4: "" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "card-fallback pa-3" },
                            [
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", small: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.opened = false
                                        }
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-close")])],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "text-center mt-0 pt-0" },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "98",
                                        height: "98",
                                        viewBox: "0 0 98 98",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M98 49C98 61.4576 93.8475 66.4407 89.6949 75.5763C86.622 82.3864 81.3898 88.0339 73.9153 91.3559C66.1915 94.761 63.1186 98 49 98C35.878 98 22.4237 93.017 15.1983 84.5458C11.7932 80.4763 9.13559 76.4068 5.64746 71.839C0.91356 65.7763 0 57.222 0 49C0 31.5593 10.4644 14.2847 22.4237 8.30508C30.7288 4.15254 36.5424 0 49 0C63.1186 0 71.5068 4.48475 80.1441 11.2119C87.2034 16.6102 89.6949 20.9288 92.7678 26.9915C96.0898 33.6356 98 41.1102 98 49Z",
                                          fill:
                                            "url(#mj-inactive-paint0_linear" +
                                            _vm.suffix +
                                            ")"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M33.6357 73.6661C33.6357 73.6661 40.0306 70.5933 52.156 70.4271C64.2814 70.261 70.8425 73.6661 70.8425 73.6661C70.8425 73.6661 77.7357 88.6983 52.156 88.6983C24.7492 88.6983 33.6357 73.6661 33.6357 73.6661Z",
                                          fill:
                                            "url(#mj-inactive-paint1_linear" +
                                            _vm.suffix +
                                            ")"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M50.4949 17.1915C63.783 17.1915 64.2813 15.0322 68.2678 17.1915C80.061 23.6695 71.7559 37.3729 75.4101 42.6881C77.0712 45.0966 79.9779 50.3288 78.5661 57.9695C77.1542 65.6102 71.1746 76.5729 53.1525 76.5729C33.3864 76.6559 25.2474 69.7627 25.2474 51.8237C25.2474 51.8237 32.8051 17.1915 50.4949 17.1915Z",
                                          fill: "#2194DD"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M48.9999 97.9999C63.1185 97.9999 66.1914 94.761 73.9152 91.3559C78.4829 89.3627 82.2202 86.4559 85.127 82.8847C81.4728 79.5627 75.9914 78.2338 70.427 76.3237C61.2084 73.1677 66.8558 58.4677 66.2745 55.9762C65.6931 53.4847 63.7829 47.9203 51.0762 47.505C38.2033 47.1728 38.0372 56.4745 38.0372 56.4745C38.0372 56.4745 39.6982 67.7694 38.0372 71.922C35.9609 77.2372 32.2236 78.3169 23.9185 80.4762C20.5135 81.3067 17.6897 82.8016 15.2812 84.5457C22.5067 93.0169 35.9609 97.9999 48.9999 97.9999Z",
                                          fill:
                                            "url(#mj-inactive-paint2_radial" +
                                            _vm.suffix +
                                            ")"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M52.9033 17.2746C64.6135 17.2746 66.1914 23.3373 67.6863 26.2441C69.3474 29.317 69.0982 39.4492 69.2643 40.861C69.3474 42.2729 71.4236 43.9339 71.4236 49.0831C71.4236 53.817 71.4236 62.9526 61.6236 67.7695C58.7999 69.1814 58.3016 72.5865 52.8202 72.5865C47.9202 72.5865 46.4253 69.2644 43.9338 68.1017C39.0338 65.6932 29.2338 57.056 34.466 43.1865C35.2965 39.2 34.1338 30.6458 37.2067 25.3305C38.9507 22.4238 41.8575 17.2746 52.9033 17.2746Z",
                                          fill:
                                            "url(#mj-inactive-paint3_linear" +
                                            _vm.suffix +
                                            ")"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M52.9033 17.2746C64.6135 17.2746 66.1914 23.3373 67.6863 26.2441C69.3474 29.317 69.0982 39.4492 69.2643 40.861C69.3474 42.2729 71.4236 43.9339 71.4236 49.0831C71.4236 53.817 71.4236 62.9526 61.6236 67.7695C58.7999 69.1814 58.3016 72.5865 52.8202 72.5865C47.9202 72.5865 46.4253 69.2644 43.9338 68.1017C39.0338 65.6932 29.2338 57.056 34.466 43.1865C35.2965 39.2 34.1338 30.6458 37.2067 25.3305C38.9507 22.4238 41.8575 17.2746 52.9033 17.2746Z",
                                          fill:
                                            "url(#mj-inactive-paint4_radial" +
                                            _vm.suffix +
                                            ")"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M60.9593 57.8864C61.3745 57.5542 61.7067 57.5542 61.9559 57.4712C62.4542 57.3051 62.6203 57.3051 62.3712 56.8898C61.9559 56.3084 61.9559 57.139 60.6271 57.5542C56.9729 58.5508 55.561 57.3881 54.6474 57.6373C53.6508 57.8034 53.4847 58.3017 53.2356 58.3017C52.9864 58.3017 52.7373 57.7203 51.6576 57.8034C50.5779 57.8864 47.339 58.2186 45.5949 57.7203C45.1796 57.6373 44.7644 57.4712 44.5152 57.3881C43.6847 57.0559 43.8508 56.7237 43.4356 57.139C43.1034 57.3881 43.6017 57.7203 44.0169 57.7203C44.2661 57.7203 44.7644 57.9695 45.1796 58.2186C46.5915 58.883 48.9169 59.2152 52.7373 59.2983C52.9034 59.2983 53.0695 59.4644 53.1525 59.4644C53.2356 59.4644 53.4017 59.2983 53.4847 59.2983C57.3881 59.1322 59.4644 59.0491 60.9593 57.8864Z",
                                          fill: "#2194DD"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M59.9628 60.2949C59.3814 60.1288 59.2153 61.4576 52.9034 61.4576C47.0899 61.4576 46.3424 59.7966 46.0933 60.0457C45.678 60.3779 48.834 62.0389 52.9034 62.0389C57.056 62.0389 60.7933 60.544 59.9628 60.2949Z",
                                          fill: "#2194DD"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M57.9695 42.7711C57.9695 42.7711 58.5508 42.605 58.9661 42.2728C59.4644 41.9406 60.1288 40.5287 61.9559 40.5287C64.3644 40.5287 65.7763 42.1898 65.7763 42.1898",
                                          stroke: "#2194DD",
                                          "stroke-miterlimit": "10",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M47.5051 42.605C46.7577 42.522 46.5085 42.1898 46.0933 42.0237C45.678 41.7745 45.5119 40.6949 43.1865 40.861C40.6119 41.0271 39.2831 43.1864 39.2831 43.1864",
                                          stroke: "#2194DD",
                                          "stroke-miterlimit": "10",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M44.4323 42.6881C44.4323 42.6881 44.9306 42.0237 45.2628 41.1931C44.8475 40.944 44.2662 40.7779 43.1865 40.7779C42.5221 40.7779 41.8577 41.027 41.3594 41.2762C41.5255 41.9406 41.8577 42.6881 42.439 42.6881C43.3526 42.6881 44.4323 42.6881 44.4323 42.6881Z",
                                          fill: "#2194DD"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M63.5339 40.7779C63.0356 40.6118 62.5373 40.5287 61.8729 40.5287C61.2916 40.5287 60.8763 40.6949 60.4611 40.861C60.6272 41.4423 60.6272 42.522 61.956 42.4389C63.617 42.4389 63.4509 41.6915 63.5339 40.7779Z",
                                          fill: "#2194DD"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M44.5983 25.9118C45.761 25.7457 46.3424 27.3237 53.2356 26.9084C60.0458 26.4932 63.1187 23.005 65.1119 23.5033C67.1051 24.0016 65.8593 35.1305 69.5136 37.2898C72.8356 39.283 78.0678 21.0118 68.3509 17.1084C64.3644 15.5305 63.8661 16.6932 63.8661 16.6932C63.8661 16.6932 63.617 11.1288 55.2288 10.1322C45.0966 8.96944 37.3729 12.0423 33.3865 21.4271C30.3136 28.5694 31.8915 35.3796 27.4068 38.8677C17.7729 46.4254 19.1017 58.3847 29.4 59.1322C35.1305 59.5474 30.1475 61.4576 33.3865 48.3355C34.7153 43.1033 35.5458 45.5949 38.7017 41.3593C43.3526 35.1305 37.3729 26.8254 44.5983 25.9118Z",
                                          fill: "#2194DD"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M15.1983 84.5458C22.4237 93.017 35.8779 98 49 98C63.1186 98 66.1915 94.761 73.9152 91.356C78.5661 89.2797 82.3034 86.2898 85.2932 82.7187C82.7186 80.2271 79.1474 78.8153 75.3271 77.4865C74.4966 77.2373 74.2474 77.1543 73.4169 76.8221C72.6695 76.5729 71.5068 73.9153 70.7593 73.5831C68.2678 80.9746 62.7034 83.7983 56.2254 85.1271C53.983 85.5424 53.1525 91.9373 52.4051 91.9373C51.6576 91.9373 50.744 85.1271 48.4186 84.7949C40.6118 83.8814 33.8847 79.3966 33.5525 73.5831C32.2237 74.5797 30.1474 78.1509 30.1474 78.1509C28.8186 78.7322 25.4135 79.6458 23.9186 80.061C20.4305 80.9746 17.5237 82.4695 15.1152 84.2966C15.1983 84.3797 15.1983 84.4627 15.1983 84.5458Z",
                                          fill:
                                            "url(#mj-inactive-paint5_linear" +
                                            _vm.suffix +
                                            ")"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M58.3847 92.8509C66.6067 91.5221 70.6762 80.3932 73.4169 76.8221C72.6694 76.5729 71.5067 73.9153 70.7592 73.5831C68.2677 81.3068 62.7033 83.7983 56.2253 85.1271C58.883 87.5356 55.4779 93.3492 58.3847 92.8509Z",
                                          fill:
                                            "url(#mj-inactive-paint6_linear" +
                                            _vm.suffix +
                                            ")"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M30.1475 78.2339C31.9746 85.1271 41.0271 90.1102 46.5085 92.4356C51.9898 94.761 44.9305 87.2034 48.4186 84.961C40.6119 84.0475 34.3 79.5627 33.5525 73.7491C32.2237 74.6627 30.1475 78.2339 30.1475 78.2339Z",
                                          fill:
                                            "url(#mj-inactive-paint7_linear" +
                                            _vm.suffix +
                                            ")"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M38.9508 38.7848C38.9508 38.7848 40.1135 37.539 44.6813 37.539C48.4186 37.539 49.4983 37.7051 49.4983 37.7051",
                                          stroke: "#2194DD",
                                          "stroke-width": "2",
                                          "stroke-miterlimit": "10",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M56.0593 37.9543C56.0593 37.9543 60.0458 36.8746 64.0322 37.456C68.0186 38.1204 68.517 39.8644 68.517 39.8644",
                                          stroke: "#2194DD",
                                          "stroke-width": "2",
                                          "stroke-miterlimit": "10",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M50.9933 42.2729C50.9933 46.3424 46.9238 49.6644 43.6017 49.6644C39.5322 49.6644 36.2102 46.3424 36.2102 42.2729C36.2102 38.2034 39.5322 35.7119 43.6017 35.7119C47.6712 35.7119 50.9933 38.2034 50.9933 42.2729Z",
                                          stroke: "#4C75AA",
                                          "stroke-width": "1.25",
                                          "stroke-miterlimit": "10"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M69.5136 42.2729C69.5136 46.3424 66.1916 49.6644 62.1221 49.6644C58.8831 49.6644 54.7306 46.3424 54.7306 42.2729C54.7306 38.2034 58.0526 35.7119 62.1221 35.7119C66.1916 35.7119 69.5136 38.2034 69.5136 42.2729Z",
                                          stroke: "#4C75AA",
                                          "stroke-width": "1.25",
                                          "stroke-miterlimit": "10"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M50.9932 42.2729C50.9932 42.2729 51.5745 40.6119 52.9033 40.6119C54.2321 40.6119 54.8135 42.2729 54.8135 42.2729",
                                          stroke: "#4C75AA",
                                          "stroke-width": "1.25",
                                          "stroke-miterlimit": "10"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M54.8137 53.4017C53.8171 53.7339 52.6544 53.8169 51.7408 53.4847C50.7442 53.1525 50.9933 52.5712 48.5018 52.322C47.9205 52.239 47.2561 51.5746 47.09 51.8237C46.9239 52.0729 47.4222 52.6542 47.9205 52.8203C49.9137 53.5678 50.495 54.3152 51.2425 54.7305C51.99 55.1458 53.5679 55.7271 54.7306 54.7305C55.8933 53.7339 57.3052 52.8203 58.551 52.4881C58.9662 52.4051 59.3815 51.9898 59.2154 51.5746C59.1323 51.3254 58.3849 51.9068 57.2222 52.1559C56.1425 52.5712 55.6442 53.0695 54.8137 53.4017Z",
                                          fill: "#2194DD"
                                        }
                                      }),
                                      _c(
                                        "defs",
                                        [
                                          _c(
                                            "linearGradient",
                                            {
                                              attrs: {
                                                id:
                                                  "mj-inactive-paint0_linear" +
                                                  _vm.suffix,
                                                x1: "49.1149",
                                                y1: "-0.701032",
                                                x2: "48.8884",
                                                y2: "97.2987",
                                                gradientUnits: "userSpaceOnUse"
                                              }
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.0168194",
                                                  "stop-color": "#FF777E"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.908",
                                                  "stop-color": "#FFE5E4"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "linearGradient",
                                            {
                                              attrs: {
                                                id:
                                                  "mj-inactive-paint1_linear" +
                                                  _vm.suffix,
                                                x1: "51.8589",
                                                y1: "88.674",
                                                x2: "52.0332",
                                                y2: "64.0086",
                                                gradientUnits: "userSpaceOnUse"
                                              }
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  "stop-color": "#DCFCF8"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.1003",
                                                  "stop-color": "#D0F5F2"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.2811",
                                                  "stop-color": "#B0E3E2"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.5219",
                                                  "stop-color": "#7DC6C7"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.81",
                                                  "stop-color": "#379EA3"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "1",
                                                  "stop-color": "#058189"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "radialGradient",
                                            {
                                              attrs: {
                                                id:
                                                  "mj-inactive-paint2_radial" +
                                                  _vm.suffix,
                                                cx: "0",
                                                cy: "0",
                                                r: "1",
                                                gradientUnits: "userSpaceOnUse",
                                                gradientTransform:
                                                  "translate(54.6307 59.5022) scale(42.8449)"
                                              }
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.3413",
                                                  "stop-color": "#4C75AA"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.6093",
                                                  "stop-color": "#19AEF6"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.7012",
                                                  "stop-color": "#19AEF6"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.72",
                                                  "stop-color": "#20A6EC"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.7816",
                                                  "stop-color": "#3391CF"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.843",
                                                  "stop-color": "#4181BA"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.9036",
                                                  "stop-color": "#4978AE"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.9625",
                                                  "stop-color": "#4C75AA"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "linearGradient",
                                            {
                                              attrs: {
                                                id:
                                                  "mj-inactive-paint3_linear" +
                                                  _vm.suffix,
                                                x1: "51.8109",
                                                y1: "110.012",
                                                x2: "52.6038",
                                                y2: "-2.2157",
                                                gradientUnits: "userSpaceOnUse"
                                              }
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.1129",
                                                  "stop-color": "#2FCEFB"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.2276",
                                                  "stop-color": "#34CEFA"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.3719",
                                                  "stop-color": "#44CFF8"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.5321",
                                                  "stop-color": "#5ED1F4"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.7038",
                                                  "stop-color": "#82D4EF"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.8829",
                                                  "stop-color": "#AFD7E8"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.939",
                                                  "stop-color": "#BFD8E6"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "radialGradient",
                                            {
                                              attrs: {
                                                id:
                                                  "mj-inactive-paint4_radial" +
                                                  _vm.suffix,
                                                cx: "0",
                                                cy: "0",
                                                r: "1",
                                                gradientUnits: "userSpaceOnUse",
                                                gradientTransform:
                                                  "translate(64.3393 51.053) scale(45.1159)"
                                              }
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  "stop-color": "#FFE5E4",
                                                  "stop-opacity": "0.5"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.1972",
                                                  "stop-color": "#EAE1E5",
                                                  "stop-opacity": "0.3982"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.48",
                                                  "stop-color": "#D2DCE5",
                                                  "stop-opacity": "0.2522"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.7434",
                                                  "stop-color": "#C4D9E6",
                                                  "stop-opacity": "0.1161"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.9664",
                                                  "stop-color": "#BFD8E6",
                                                  "stop-opacity": "0.01"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.9998",
                                                  "stop-color": "#BFD8E6",
                                                  "stop-opacity": "0"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "linearGradient",
                                            {
                                              attrs: {
                                                id:
                                                  "mj-inactive-paint5_linear" +
                                                  _vm.suffix,
                                                x1: "50.1994",
                                                y1: "96.3716",
                                                x2: "50.1983",
                                                y2: "77.9417",
                                                gradientUnits: "userSpaceOnUse"
                                              }
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  "stop-color": "#DCFCF8"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.32",
                                                  "stop-color": "#E8F4F1"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.7567",
                                                  "stop-color": "#FFE5E4"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "linearGradient",
                                            {
                                              attrs: {
                                                id:
                                                  "mj-inactive-paint6_linear" +
                                                  _vm.suffix,
                                                x1: "64.8558",
                                                y1: "91.6125",
                                                x2: "64.8549",
                                                y2: "77.049",
                                                gradientUnits: "userSpaceOnUse"
                                              }
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  "stop-color": "white",
                                                  "stop-opacity": "0.9"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.5189",
                                                  "stop-color": "#FFF4F3",
                                                  "stop-opacity": "0.9519"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "1",
                                                  "stop-color": "#FFE5E4"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "linearGradient",
                                            {
                                              attrs: {
                                                id:
                                                  "mj-inactive-paint7_linear" +
                                                  _vm.suffix,
                                                x1: "39.4404",
                                                y1: "91.5883",
                                                x2: "39.4395",
                                                y2: "77.0439",
                                                gradientUnits: "userSpaceOnUse"
                                              }
                                            },
                                            [
                                              _c("stop", {
                                                attrs: {
                                                  "stop-color": "white",
                                                  "stop-opacity": "0.9"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "0.5189",
                                                  "stop-color": "#FFF4F3",
                                                  "stop-opacity": "0.9519"
                                                }
                                              }),
                                              _c("stop", {
                                                attrs: {
                                                  offset: "1",
                                                  "stop-color": "#FFE5E4"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c("v-card-text", { staticClass: "pt-4 pb-4" }, [
                                _c("p", {
                                  staticClass: "mj-talk-voice",
                                  domProps: {
                                    innerHTML: _vm._s(_vm.part.content)
                                  }
                                })
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }