











import Vue from 'vue';
import { IStyleContentThumbnail, ITherapyImage, StyleDeviceEnum, StyleSizeEnum } from '@/scripts/store/modules/therapy/types';

const VIMEO_ID = 'vimeoid:';

export default Vue.extend({
  name: 'element-part-thumbnail',
  props: {
    part: {
      type: Object as () => IStyleContentThumbnail,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      imageUrl: null as string | null,
      imageAlt: null as string | null,
    };
  },
  computed: {
    showImage(): boolean {
      return this.part && ((!this.part.device || !this.part.device.length) ||
        (this.part.device === StyleDeviceEnum.MOBILE && this.$vuetify.breakpoint.xsOnly) ||
        (this.part.device === StyleDeviceEnum.DESKTOP && this.$vuetify.breakpoint.smAndUp));
    },
    isMaxSize(): boolean {
      return this.part.size === StyleSizeEnum.XL;
    },
  },
  mounted() {
    this.$log.debug('Displaying thumbnail', this.part);
    this.loaded = false;

    if (this.part.vimeoid || this.part.content?.toLowerCase().includes(VIMEO_ID)) {
      this.loadImageFromVimeo();
    } else {
      this.loadImageFromLibrary();
    }
  },
  destroyed() {
    this.destroyImage();
  },
  methods: {
    destroyImage() {
      if (this.imageUrl) {
        URL.revokeObjectURL(this.imageUrl);
        this.imageUrl = null;
      }
    },
    loadImageFromLibrary() {
      this.$store.dispatch('therapy/getTherapyImage', this.part.id ? this.part.id : this.part.content)
        .then((res: ITherapyImage) => {
          if (!res || !res.image) {
            this.imageUrl = null;
            this.imageAlt = null;
            this.loaded = true;
            return;
          }
          const blob = new Blob([ res.image ], { type: 'image/svg+xml' });
          this.imageUrl = URL.createObjectURL(blob);
          this.imageAlt = res.altText;
          this.loaded = true;
        });
    },
    loadImageFromVimeo() {
      let vimeoId;

      if (this.part.vimeoid) {
        vimeoId = this.part.vimeoid;
      } else {
        const index = this.part.content.toLowerCase().indexOf(VIMEO_ID);
        vimeoId = this.part.content.substring(index + VIMEO_ID.length);
      }

      this.$store.dispatch('getVideoByVimeoId', vimeoId).then(video => {
        this.$http.get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${video.vimeoId}`)
          .then((res:any) => {
            if (!res || !res.data || !res.data.thumbnail_url) {
              this.imageUrl = null;
              this.imageAlt = null;
              this.loaded = true;
              return;
            }
            this.imageUrl = res.data.thumbnail_url;
            this.imageAlt = null;
            this.loaded = true;
          });
      });
    },
  },
});
