
















import Vue from 'vue';
import { mapGetters } from 'vuex';
import { IStyleContent, StyleHorizontalEnum } from '@/scripts/store/modules/therapy/types';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export default Vue.extend({
  name: 'element-part-download',
  components: { PrimaryButton },
  props: {
    part: {
      type: Object as () => IStyleContent,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    buttonLabel(): string {
      return (this.part.id ? this.part.content : null) || this.$t('app.products.element.popup.download_button') as string;
    },
    justifyLeft(): boolean {
      return !!this.part.horizontal && this.part.horizontal === StyleHorizontalEnum.LEFT;
    },
    justifyCenter(): boolean {
      return !this.part.horizontal || this.part.horizontal === StyleHorizontalEnum.CENTER;
    },
    justifyRight(): boolean {
      return !!this.part.horizontal && this.part.horizontal === StyleHorizontalEnum.RIGHT;
    },
    ...mapGetters({
      isNativePlatform: 'isNativePlatform',
    }),
  },
  methods: {
    handleClick() {
      if (!this.part.id && !this.part.content) return;
      this.loading = true;
      this.getMediaMetadata().then((media?: { url: string, filename: string, blob: Blob}) => {
        if (!media) return;

        if (this.isNativePlatform) {
          this.capacitorSaveFile(media.filename, media.blob);
        } else {
          this.saveFileOnWeb(media);
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    async getMediaMetadata() {
      let url = this.part.content;
      const headers = new Headers({});

      if (this.part.id) {
        const media = await this.$store.dispatch('therapy/getMedia', this.part.id);
        if (media.type !== 'EXTERNAL') return;
        url = media.download;
      }

      const response = await fetch(url, {
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers,
      });
      if (response.status !== 200) return;
      const mediaBlob = await response.blob();
      const contentDisposition = response.headers.get('content-disposition');
      const filename = contentDisposition && contentDisposition.indexOf('filename') ? contentDisposition.split('filename="')[1].split('"')[0] : 'download.pdf';
      return { url: URL.createObjectURL(mediaBlob), filename, blob: mediaBlob };
    },
    saveFileOnWeb(media: { url: string, filename: string, blob: Blob }) {
      const link = document.createElement('a');
      link.href = media.url;
      link.download = media.filename;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
});
