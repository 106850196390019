var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isColorBg && _vm.isBackground && !_vm.isRendered
    ? _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            {
              class: {
                xs12: _vm.part.size === _vm.sizeStyles.XL,
                xs10: _vm.part.size === _vm.sizeStyles.LG,
                xs8: _vm.part.size === _vm.sizeStyles.MD,
                xs6: _vm.part.size === _vm.sizeStyles.SM,
                xs4: _vm.part.size === _vm.sizeStyles.XS
              }
            },
            [
              _vm.imageLoaded
                ? _c("img", {
                    staticClass: "starling-background",
                    attrs: { src: _vm.image.src, alt: _vm.image.alt },
                    on: { load: _vm.loadedHandler }
                  })
                : _vm._e()
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }