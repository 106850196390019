<template>
    <div >
      <div class="note-header">
        <span class="starling-small-text"><strong>{{  formattedNoteType }}</strong></span>
        <v-divider class="note-divider ml-2" :style="{ backgroundColor: noteColor }"></v-divider>
      </div>
    </div>
  </template>
<script>
export default {
  name: 'HorizontalLineNote',
  props: {
    note: String,
    noteType: String,
  },
  computed: {
    noteColor() {
      return this.$t(`app.assessments.${this.noteType}`).color;
    },
    formattedNoteType() {
      return this.$t(`app.assessments.${this.noteType}.label`) + ' notes';
    },
  },
};
</script>

 <style scoped>
.note-header {
  display: flex;
  align-items: center;
}

.note-divider {
  border-width: 1.5px !important;
}

.assessment-notes .v-input__append-inner {
  color: currentColor;
}
</style>
