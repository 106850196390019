








import Vue from 'vue';
import { IStyleContent } from '@/scripts/store/modules/therapy/types';
import ResilienceMeter from '@/views/home/components/resilience-meter.vue';

export default Vue.extend({
  name: 'element-part-resilience',
  components: { ResilienceMeter },
  props: {
    part: {
      type: Object as () => IStyleContent,
      required: true,
    },
  },
  computed: {
    value() {
      const content = this.part.content;
      return {
        value: content,
        min: 0,
        max: 8,
      };
    },
  },
});
